const validation = (values, checked, index) => {
    let errors={};
    //Phone_number_verification form
    if (index === "Phone_number_verification") {
        if (!values.telNum) {
            errors.telNum = "Telefonnummer ist erforderlich";
        }
        else if (!/^[1-9]\d*$/.test(values.telNum)){
            errors.telNum = "Telefonnummer ist inkorrekt";
        }
        if (!checked){
            errors.checked = "Du musst die Verifizierung Deiner Nummer akzeptieren";
        }
    }
    if (index === "Check_verification_code") {
        //Check_verification_code form
        if (!values.digit1 && !values.digit2 && !values.digit3 && !values.digit4) {
            errors.code = "Verifizierungscode ist erforderlich";
        }
    }
    if (index === "Enter_personal_data") {
        //Enter_personal_data form
        if (!values.fname) {
            errors.fname = "Vorname ist erforderlich";
        }
        /*else if (!/^[a-zA-Z]+ [a-zA-Z]+$/.test(values.fname)) {
            errors.fname = "Bitte geben Sie einen gültigen Namen ein";
        }*/
        if (!values.lname) {
            errors.lname = "Nachname ist erforderlich";
        }/*else if (!/^[a-zA-Z]+ [a-zA-Z]+$/.test(values.lname)) {
            errors.lname = "Bitte geben Sie einen gültigen Namen ein";
        }*/
        if (!values.email) {
            errors.email = "E-Mail-Adresse ist erforderlich";
        }else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
            errors.email = "E-Mail ist ungültig, bitte verwenden Sie eine korrekte E-Mail Adresse";
        }
        if (!values.pass) {
            errors.pass = "Passwort ist erforderlich";
        }else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9])(.{8,50})$/.test(values.pass)) {
            errors.pass = "Passwort ist zu schwach, das Passwort sollte mindestens 8 Charakter lang sein und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten";
        }
        if (!values.rpass) {
            errors.rpass = "Passwort ist erforderlich";
        }
        if (values.rpass !== values.pass) {
            errors.rpass = "Passwörter stimmen nicht überein";
        }
        else if (!values.username) {
            errors.username = "Nutzername ist erforderlich";
        }
        else if (!/^([a-zäöüÄÖÜß]{4})+([0-9]{4})/.test(values.username)){
            errors.username = "Der Username muss aus 4 kleingeschriebenen Buchstaben und 4 Zahlen bestehen. Beispiel : abcd4567";
        }
        if (!checked){
            errors.checked = "Sie müssen die Nutzungsbedingungen akzeptieren";
        }
    }
    return errors;
}

export default validation;