import React, {useState} from 'react';
import './register.css';
import Phone_number_verification from "./phone_number_verification";
import Check_verification_code from "./check_verification_code";
import Enter_personal_data from "./enter_personal_data";
import Registration_success from "./registration_sucess";


function Register() {
    const [index, setIndex] = useState("Phone_number_verification");
    const switchToIndex = (index) => {
        setIndex(index);
    };
    switch (index) {
        case "Phone_number_verification":
            return <Phone_number_verification switchToIndex={switchToIndex}/>;
        case "Check_verification_code":
            return <Check_verification_code switchToIndex={switchToIndex}/>;
        case "Enter_personal_data":
            return <Enter_personal_data switchToIndex={switchToIndex}/>;
        case "Registration_success":
            return <Registration_success switchToIndex={switchToIndex}/>
        default:
            return <Phone_number_verification/>;
    }
}

export default Register;

