import postfach_icon from './icons/Postfach _grau.svg'
import postfach_icon_weiss from './icons/Postfach _weiss.svg'
import inserate_icon from './icons/Beiträge_grau.svg'
import inserate_icon_weiss from './icons/Beiträge_weiss.svg'
import buchungen_icon from './icons/Buchungen_grau.svg'
import buchungen_icon_weiss from './icons/Buchungen_weiss.svg'
import admin_inserate_icon from './icons/Inserate_dunkelrot.svg'
import admin_buchungen_icon from './icons/Buchungen_dunkelrot.svg'
import market_icon from './icons/Marktplatz_grau.svg'
import market_icon_weiss from './icons/Marktplatz_weiß.svg'
import logout_icon_weiss from './icons/Abmelden_weiss.svg'
import logout_icon from './icons/Abmelden_grau.svg'

export const MeinBereichList = [
  {
    title: 'Postfach',
    path: '/chat',
    cName: 'dropdown-link',
    icon: postfach_icon,
    icon_white: postfach_icon_weiss
  },
  {
    title: 'Meine Inserate',
    path: '/profile#profile_my_posts',
    cName: 'dropdown-link',
    icon: inserate_icon,
    icon_white: inserate_icon_weiss
  },
  {
    title: 'Meine Buchungen',
    path: '/profile#profile_my_bookings',
    cName: 'dropdown-link',
    icon: buchungen_icon,
    icon_white: buchungen_icon_weiss
  }
  ,
  {
    title: 'Abmelden',
    path: '/',
    cName: 'dropdown-link',
    icon: logout_icon,
    icon_white: logout_icon_weiss
  }
];

export const AdminBereichList = [
  {
    title: 'Inserate freigeben',
    path: '/release_posts',
    cName: 'dropdown-link',
    icon: inserate_icon,
    icon_white: inserate_icon_weiss
  },
  {
    title: 'Auslastung',
    path: '/locker_load',
    cName: 'dropdown-link',
    icon: buchungen_icon,
    icon_white: buchungen_icon_weiss
  },
  {
    title: 'Analytics',
    path: '/analytics',
    cName: 'dropdown-link',
    icon: buchungen_icon,
    icon_white: buchungen_icon_weiss
  }
];
