import React, {useEffect, useState} from 'react';
import './liability_conditions.css';
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";


function Liability_conditions() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    return (
        <main class="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}></Navbar>
            <div className={"Liability_conditions_screen"}>
                <div className="Liability_conditions_content">
                    <h4 className={"teaser_h4"} style={{color:"#000000"}}>Verwahrungsbedingungen (Haftung)</h4>
                    <p></p>
                    <p>Die Nutzung unseres Kiez-Lockers unterliegt den nachstehenden Bestimmungen.
                        Als Nutzer:innen werden im Folgenden die Personen bezeichnet, die Artikel
                        in den Kiez-Locker einlegen oder Empfänger:innen von Artikeln sind.
                    </p>
                    <ol>
                        <p>
                        <li>

                            Die Nutzung beginnt mit der Buchung eines Faches über das Online-Portal <a href="www.BSR.de/Kiez-Locker">www.BSR.de/Kiez-Locker</a>. Die Nutzung endet mit dem Öffnen eines Faches und der
                            Entnahme des Inhaltes bzw. mit der Stornierung der Buchung.

                        </li>
                        </p>





                        <p>
                            <li>
                            Die Fächer sind sorgfältig zu behandeln und vor Beschädigung und Beschmutzung
                            zu bewahren. Soweit das Fach verschmutzt ist, hat die/der Nutzer:in dies der BSR zu
                            melden. Bei Beendigung der Nutzung hat die/der Nutzer:in das Fach vollständig zu
                            entleeren und von ihr/ihm verursachte Verunreinigungen zu beseitigen.
                            </li>
                        </p>

                        <p>
                            <li>
                            Der Kiez-Locker ist so zu nutzen, dass Beschädigungen/Verunreinigungen/Belästigungen des/am Locker/s, der Buchungsplattform oder anderer im Zusammenhang
                            mit der Nutzung bestehender Services ausgeschlossen sind.
                            Weiterhin darf durch die die Nutzung keine Belästigung/Beeinträchtigung anderer
                            Personen erfolgen und auch sonst nicht gegen geltendes Recht oder gegen gute Sitten verstoßen werden.
                            Dies betrifft sowohl die Nutzung selbst wie auch Inhalt/Natur der im Locker eingestellten Gegenstände. Beispielsweise betrifft dies.:
                                <ul>
                                    <li><p>Gefährliche, gesundheitsgefährdende oder geruchsbelästigende und auslaufende Stoffe dürfen in den Fächern nicht aufbewahrt werden.</p></li>
                                    <li><p>Verderbliche Artikel sind entsprechend zu lagern und schnellstmöglich wieder zu entnehmen.</p></li>
                                    <li><p>Gegenstände die explosions-, feuer- oder sonst gefährlich sind (z. B. Gasflaschen, Lösungsmittel, Chemikalien, Pyrotechnik) oder deren Besitz und/oder
                                        Weitergabe strafbar ist (z. B. Drogen, Waffen Munition), sind von der Verwahrung ausgeschlossen.</p></li>
                                    <li><p>Gleiches gilt für Lebewesen jeder Art.</p></li>
                                    <li><p>Jugendgefährdende und verfassungswidrige Inhalte sind von der Verwahrung ausgeschlossen.</p></li>
                                    <li><p>Jedwede weitere betrügerische, sonstig strafbare oder rechtswidrige Handlung ist von der Nutzung ausgeschlossen.</p></li>
                                    <li><p>Soweit das Fach verschmutzt ist, hat die/der Nutzer:in dies der BSR zu melden. Bei Beendigung der Nutzung hat die/der Nutzer:in das Fach vollständig
                                        zu entleeren und von ihr/ihm verursachte Verunreinigungen zu beseitigen.</p></li>
                                </ul>
                                <p>Für Zuwiderhandlungen und entstehende Schäden haftet der/die Nutzer:in.</p>
                            </li>
                        </p>

                        <p>
                            <li>
                            Alle Gegenstände werden auf eigenes Risiko der nutzenden Person verwahrt. Dies betrifft
                            insbesondere Wertsachen wie Geld, Papiere mit Geldwert, Schmuck oder andere werthaltige Gegenstände.
                            </li>
                        </p>

                        <p>
                            <li>
                                Die Verwahrung erfolgt unentgeltlich. Für unzulässige oder sonstige eingestellte Gegenstände (insbesondere nach Nr. 3 und 4)
                                ist jede Haftung der BSR ausgeschlossen. Im Übrigen haftet die BSR nur für Schäden, die durch grob fahrlässiges oder
                                vorsätzliches Verhalten ihrer Mitarbeiter:innen und Beauftragten verursacht wird; im Falle von Personenschäden darüber hinaus
                                auch bei (einfach) fahrlässiger Verursachung durch die vorgenannten Personen.
                            </li>
                        </p>
                        <p>
                            <li>
                                Standorte:
                                <ul>
                                    <li><p>
                                      Tempelhof: 12103 Berlin, Ringbahnstraße 96
                                      Der Locker steht auf BSR-Betriebsgelände, am Haupteingang in der Durchfahrt unter dem Dach
                                      und ist i.d.R. zu folgenden Zeiten erreichbar:
                                      Mo – Fr  04:00 Uhr – 20:00 Uhr,
                                      Sa, So und feiertags  05:00 Uhr – 14:00 Uhr.
                                      Außerhalb dieser Zugangszeiten ist das Tor verschlossen.
                                        </p>
                                    </li>
                                    <li><p>
                                      Adlershof: 12489 Berlin, Groß-Berliner Damm 150
                                      Der Locker steht auf Gelände der Charlottenburger Baugenossenschaft eG und wird in gemeinsamer Kooperation zwischen „Charlotte“ und „BSR“ betrieben.
                                      Die als Kiez-Locker für o.g. Zwecke nutzbaren Fächer sind gekennzeichnet.
                                      Der Locker ist 24/7 erreichbar – auf Ruhezeiten für Anwohner:innen sollte trotzdem Rücksicht genommen werden.
                                        </p>
                                    </li>
                                    </ul>
                            </li>
                        </p>

                        <p>
                            <li>
                                Die Fachnutzung kann durch die/den Einstellende:n am Buchungs- oder am Folgetag
                                beginnen. Die/der Empfangende wird über das Einstellen per E-Mail informiert. Die
                                reguläre Verwahrdauer beträgt maximal 7 Tage ab Datum der Fachbuchung durch
                                die/den Einstellende:n, wobei der Tag der Buchung mitgezählt wird. In der E-Mail an
                                die/den Empfangende:n ist das Ende der Verwahrdauer aufgeführt.
                                Bei Überschreitung der zulässigen Verwahrdauer oder unzureichender Räumung des
                                Fachs ist die BSR zur Öffnung des Faches und Inbesitznahme der eingestellten Sachen berechtigt. Besteht der Verdacht, dass sich verbotene Gegenstände im Fach
                                befinden, ist die BSR zur sofortigen Räumung des Faches berechtigt. Einer ausdrücklichen Räumungsaufforderung oder eines weiteren vorherigen Hinweises bedarf
                                es nicht.
                            </li>
                        </p>

                        <p>
                            <li>
                                Nach Ablauf der Verwahrdauer in Nr. 7 werden die Gegenstände durch die BSR aus
                                dem Locker entnommen und andernorts aufbewahrt. Die bei der Buchung angegebenen Nutzer (Einstellende:r und Empfangende:r) haben danach maximal 14 Kalendertage Zeit, die Gegenstände zurückzuverlangen. Danach ist die BSR berechtigt, diese
                                Gegenstände dem Gebrauchtwarenkaufhaus NochMall zuzuführen.
                            </li>
                        </p>
                        <p>
                            <li>
                                Eine Kündigung des Verwahrungsvertrages durch uns ist nur aus wichtigem Grund
                                möglich. Insbesondere stellen Verstöße gegen diese Verwahrungsbedingungen Nr.
                                2-3 einen wichtigen Grund dar.
                            </li>
                        </p>
                        <p>
                            <li>
                                Mit dem Kiez-Locker und der Buchungsplattform stellen wir nur einen Übergabeort für
                                Gegenstände zur Verfügung. Auf die Rechtsgeschäfte zwischen Einlegenden und
                                Empfangenden selbst haben wir keinen Einfluss und sind durch diese untereinander
                                zu klären.
                            </li>
                        </p>
                        <p>
                            <li>
                                Erfüllungsort der beiderseitigen Leistungspflichten ist der Standort des Kiez-Lockers,
                                folglich Berlin. Gegenüber Kaufleuten ist der Gerichtsstand bei einer Streitigkeit aus
                                dem Vertragsverhältnis ausschließlich nach diesem Erfüllungsort zu bestimmen
                            </li>
                        </p>
                        <p>
                            <li>
                                Nebenabreden, Änderungen und Ergänzungen bedürfen zu ihrer Wirksamkeit der
                                Textform. Die BSR kann diese Nutzungsbedingungen ändern. Sie wird die geänderten Bedingungen per E-Mail der/dem Nutzer:in mitteilen. Die geänderten Bedingungen werden im Verhältnis zu der/dem Nutzer:in wirksam, wenn diese/r nicht innerhalb
                                von 2 Wochen nach Bekanntgabe in Textform widerspricht. Die BSR wird die/den
                                Nutzer auf diese Folge aufmerksam machen.
                            </li>
                        </p>
                        <p>
                            <li>
                                Sollten einzelne oder mehrere Bestimmungen ganz oder teilweise unwirksam sein oder werden, wird hiervon die
                                Gültigkeit der übrigen Bestimmungen nicht berührt.
                                Jede/r Vertragspartner:in hat in diesem Fall das Recht, die Vereinbarung einer gültigen durchführbaren Bestimmung
                                zu verlangen, die dem mit der unwirksamen Bestimmung verfolgten Zweck am nächsten kommt.
                            </li>
                        </p>
                   </ol>
                    <h3><br/>Stand August 2023</h3>
                </div>
            </div>
            <Footer/>

        </main>
    )
}

export default Liability_conditions;