import React, {useEffect, useState} from 'react';
import './register.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import validation from "./validation";
import {isTesting} from "../../App";
import {phoneNumber} from "./phone_number_verification";
import RestApi from "../../RestAPI";


function Enter_personal_data({switchToIndex}) {
    const [generatedUsername, setGeneratedUsername] = useState("");
    const [backBtnClicked, setBackBtnClicked] = useState(false);
    const [values, setValues] = useState({
        fname: "",
        lname: "",
        email: "",
        pass: "",
        rpass: "",
        username: ""
    })
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [checked, setChecked] = React.useState(false);
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
        //generateUsername();
    }

    function handleCheckBoxChange() {
        setChecked(!checked);
        changeNextButtonColor();
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, checked, "Enter_personal_data"));
        setDataIsCorrect(true);
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Registration_success") : registerRequest();
        }
    }, [errors]);
    const handleBackBtn = (event) => {
        event.preventDefault();
        setBackBtnClicked(true);
    }
    useEffect(() => {
        if (backBtnClicked) {
            switchToIndex("Check_verification_code");
        }
    });

    function registerRequest() {
        let data = {
            first_name: values.fname,
            last_name: values.lname,
            phone_number: phoneNumber,
            email: values.email,
            password: values.pass,
            username: values.username
        }
        RestApi("/api/signup", "POST", data).then((resp) => {
            return resp.json()
        })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    switchToIndex("Registration_success");
                } else {
                    setRequestError(message);
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    function checkIfUsernameExists() {
         let data = {username: generatedUsername}
        RestApi("/api/check_username", "POST", data).then((resp) => {
            return resp.json()
        })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setValues({
                        ...values,
                        username: generatedUsername,
                    });
                    setUserNameApproved(true)
                } else {
                    setRequestError(message);
                }

            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    function handleUmlautCharacter(characterPartOfUsername: string) {
        characterPartOfUsername = characterPartOfUsername.toLowerCase();
        characterPartOfUsername = characterPartOfUsername.replace(/ä/g, 'ae');
        characterPartOfUsername = characterPartOfUsername.replace(/ö/g, 'oe');
        characterPartOfUsername = characterPartOfUsername.replace(/ü/g, 'ue');
        return characterPartOfUsername.slice(0, 2);
    }

    const [userNameApproved, setUserNameApproved] = useState(false)

    function generateUsername() {
        if (values.fname === "" || values.lname === "" || values.fname.length < 2 || values.lname.length < 2 || userNameApproved) {
            return null;
        } else {
            // Generate 4 character + 4 digits as username
            const firstCharacterPartOfUsername = handleUmlautCharacter(values.fname);
            const secondCharacterPartOfUsername = handleUmlautCharacter(values.lname);
            const digitPartOfUsername = Math.floor(1000 + Math.random() * 9000);
            const generatedUsername = firstCharacterPartOfUsername + secondCharacterPartOfUsername + digitPartOfUsername;
            setGeneratedUsername(generatedUsername);
        }

    }

    useEffect(() => {
        generatedUsername.length === 8 ? checkIfUsernameExists() : console.log("Waiting")
    }, [generatedUsername]);

    useEffect(() => {
        if (values.fname.length < 2 || values.lname.length < 2) {
            setUserNameApproved(false)
        }
    }, [values.fname, values.lname]);

    function addUsernameManually() {
        setValues({
            ...values,
            username: '',
        })
        document.getElementById('username').disabled = false;
        document.getElementById('username').focus();
    }

    function changeNextButtonColor() {
        if (!checked) {
            document.getElementById("loginbtn").style.backgroundColor = "#f60";
        } else {
            document.getElementById("loginbtn").style.backgroundColor = "#A0A0A0";
        }

    }

    return (
        <main className="main">
            <Navbar></Navbar>
            <div className="info_box">
                <div className="login_content register_window">
                    <div style={{display:"none"}} className="login_content_left">
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">1</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Telefon</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">2</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Verifizierung</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div style={{backgroundColor: "#f61"}}
                                 className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#fff"}} className="global_style_h2_light_text">3</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#f61"}} className="global_style_h2_light_text">Kundendaten</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">4</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Bestätigen</h2>
                            </div>
                        </div>
                    </div>
                    <div className="login_content_right login_content_right_register">
                        <div className="login_content_right_frame login_content_right_frame_register">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Persönliche Daten</h3>
                            </div>
                            <div className="login_content_right_item">
                                <input id="fname" name="fname" type="text" className="text_input"
                                       placeholder="Vorname" required value={values.fname} onChange={handleChange}/>
                                {errors.fname &&
                                    <span className="error_span">{errors.fname}</span>
                                }
                            </div>

                            <div className="login_content_right_item">
                                <input id="lname" name="lname" type="text" className="text_input"
                                       placeholder="Nachname" required value={values.lname}
                                       onfocusout={generateUsername}
                                       onChange={handleChange}/>
                                {errors.lname &&
                                    <span className="error_span">{errors.lname}</span>
                                }
                            </div>
                            <div className="login_content_right_item">
                                <input id="email" name="email" type="email" onFocus={generateUsername}
                                       className="text_input" placeholder="E-Mail-Adresse" required
                                       value={values.email} onChange={handleChange}/>
                                {errors.email &&
                                    <span className="error_span">{errors.email}</span>
                                }
                            </div>
                            <div className="login_content_right_item">
                                <input id="pass" name="pass" type="password" className="text_input"
                                       placeholder="Passwort" required value={values.pass} onChange={handleChange}/>
                                {errors.pass &&
                                    <span className="error_span">{errors.pass}</span>
                                }
                            </div>
                            <div className="login_content_right_item">
                                <input id="rpass" name="rpass" type="password" className="text_input"
                                       placeholder="Passwort wiederholen" required value={values.rpass}
                                       onChange={handleChange}/>
                                {errors.rpass &&
                                    <span className="error_span">{errors.rpass}</span>
                                }
                            </div>
                            <div className="login_content_right_item span_down">
                                <input id="username" name="username" type="text" className="text_input"
                                       placeholder="Nutzername" size={8} maxLength={8} required value={values.username}
                                       onChange={handleChange}
                                       disabled={true}/>
                                <span style={{width:"max-content"}} className="my_span" onClick={addUsernameManually}>Manuell
                                    eingeben
                                </span>
                                {errors.username &&
                                    <span className="error_span">{errors.username}</span>
                                }
                            </div>
                            <div className="login_content_right_item">
                                <p className="checkbox_text_p">Ich habe die <a className="checkbox_text_p" href="/data_protection_policy"target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen.</p>
                                <div className="login_content_right_item_checkbox">
                                    <div id={"register_checkbox_icon"} className="login_content_right_item_left">
                                        <label>
                                            <input id="termsconds" name="termsconds" className="checkbox_input"
                                                   type="checkbox" required checked={checked}
                                                   onChange={handleCheckBoxChange}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                    <div className="login_content_right_item_right">
                                        <p className="checkbox_text_p">
                                            Ich akzeptiere die <a className="checkbox_text_p"
                                            href="/registration_conditions"
                                            target="_blank">Registrierungsbedingungen</a> und die <a className="checkbox_text_p"
                                            href="/liability_conditions" target="_blank">Verwahrungsbedingungen
                                            (Haftung)</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {errors.checked &&
                                <div className="login_content_right_item">
                                    <span className="error_span">{errors.checked}</span>
                                </div>
                            }
                            <div style={{flex:"1"}}></div>
                            <div className="login_content_right_item register_btn_field">
                                <div className="login_content_left_items_component">
                                    <button  style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} type="button" onClick={handleBackBtn} className="ueberschrift_btn">Zurück</button>

                                </div>
                                <div className="login_content_left_items_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} onClick={handleFormSubmit} id="loginbtn" type="button" className="ueberschrift_btn">Weiter</button>
                                </div>
                            </div>
                            {requestError &&
                                <div className="login_content_right_item">
                                    <span className="error_span">{requestError}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default Enter_personal_data;

