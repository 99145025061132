import React, {useEffect, useState} from 'react';
import './start_seite.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import Buchungen_icon from "../../static/start_site/Buchungen_orange.svg";
import Beiträge_icon from "../../static/start_site/Meine Beiträge Anzeigen_orange.svg";
import Profile_icon from "../../static/start_site/Profil_orange.svg";
import StandOrt from "../../static/start_site/Standort_weiß.svg";
import openingOhrs from "../../static/start_site/Uhr.svg";
import questions from "../../static/start_site/Fragen.svg";
import postfach from "../../static/start_site/Postfach_weiss.svg";
import inbox_icon from "../../static/start_site/Postfach_orange.svg";
import stoerer_rund from "../../static/start_site/Störer V02.svg";
import stoerer_mobil from "../../static/start_site/Störer mobil.svg";
import corner from "../../static/start_site/Eselsohr V02.svg";
import Box_selection from "../box_selection";
import Main_faq from "../common/main_faq/main_faq";
import Ad_main_faq from "../common/main_faq/ad_main_faq";
import Loading_spinner from "../common/loading_spinner";
import {isLoggedIn, isTesting, XLscreenWidth, LscreenWidth, MscreenWidth} from "../../App";
import {isAdmin, isLogin} from "../login";
import Post from "../market/post";
import RestAPI from "../../RestAPI";
import { Helmet } from 'react-helmet';
import bsr_logo from '../../static/icons/BSR_RGB.svg'


function Start_seite() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    const [notificationCounter, setNotificationCounter] = useState(0);
    const [posts, setPosts] = useState([]);

    const [toggleThClass, setToggleThClass] = useState(false);
    const [toggleAhClass, setToggleAhClass] = useState(false);
        const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    function toggleTh(){
        setToggleThClass(!toggleThClass)
    }
    function toggleAh(){
        setToggleAhClass(!toggleAhClass)
    }


    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        await data()
    }, []);
    const redirect_to_profile = () => {
        window.location.href = "/profile";
    }
    const redirect_to_login = () => {
        window.location.href = "/login";
    }
    const redirect_to_register = () => {
        window.location.href = "/register";
    }
    const redirect_to_insert_box = () => {
        window.location.href = "/insert_box";
    }
    const redirect_to_market = () => {
        window.location.href = "/market";
    }
    const redirect_to_bookings = () => {
        window.location.href = "/profile#profile_my_bookings";
    }
    const redirect_to_posts = () => {
        window.location.href = "/profile#profile_my_posts";
    }
    const redirect_to_chat = () => {
        window.location.href = "/chat";
    }
    let root = document.documentElement;

    useEffect(() => {
        if (isLoggedInVar) {
            checkForNotifications()
        }
    }, [isLoggedInVar])

    function checkForNotifications() {
        RestAPI("/api/check_for_notifications", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setNotificationCounter(response["notificationCounter"])
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    useEffect(()=>{
        getPosts()
    },[])
    function getPosts() {
        setRestQueryIsLoading(true);
        RestAPI("/api/get_posts", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                setRestQueryIsLoading(false);
                if (response["status"] === 200) {
                    setPosts(response["posts"])
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    const screenWidth = window.matchMedia("(max-width: 600px)");
    let width = screenWidth;

    const my_inserate = [
        {"id": 12345678901234567890, "date": "03.10.2022", "views": "25", "title": "Tassen", "type": "Tauschen"},
        {"id": 2, "date": "03.10.2022", "views": "12", "title": "Schreibtisch", "type": "Weitergeben"},
        {"id": 3, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 4, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 5, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 6, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 7, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
    ];
    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Helmet>
            <title>Die BSR Kiez-Locker</title>
            <meta name="description" content="Die BSR Kiez-Locker Verschenken, Tauschen, Weitergeben." />
            <meta name="keywords" content="BSR, Kiez-Locker, Verschenken, Tauschen, Weitergeben" />
            <meta property="og:image" content={bsr_logo} />
            </Helmet>
            <Navbar borderColor="#fff" logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="start_site_content">
                <div className="start_site_content_item start_site_content_item_header">

                    <div className="bsr_slogan">
                        <div style={{paddingTop: "40px", maxWidth: "500px"}}>
                            <h4 style={screenWidth.matches ? {color: "white", textAlign: "left"} : {color: "white"}}
                                className="teaser_h4">{"Die BSR Kiez-Locker"}</h4>
                        </div>
                        <div style={{maxWidth: "500px"}}>
                            {
                                MscreenWidth.matches || LscreenWidth.matches ?
                                    <h1 className="titel_h1">Verschenken,<br/> Tauschen, Weitergeben.</h1>
                                    :
                                    <h1 className="titel_h1">Verschenken, Tauschen, Weitergeben.</h1>
                            }
                        </div>
                        <div style={{marginTop: "20px", maxWidth: "500px"}}>
                            {
                                MscreenWidth.matches || LscreenWidth.matches ?
                                    <h4 style={{color: "white"}} className="teaser_h4">{screenWidth.matches ? null :
                                        <div><br/></div>}</h4>
                                    :
                                    <h4 style={{color: "white"}} className="teaser_h4">{screenWidth.matches ? null :
                                        <div><br/><br/></div>}</h4>
                            }

                        </div>

                        <div style={{marginLeft: "0px", marginTop: "20px", marginBottom: "30px"}}></div>
                    </div>
                    <div className={"stoerer_rund"}>
                        <img src={stoerer_rund}/>
                        <div>
                            <p style={{color: "#BD451C"}} className={"fliesstext_p"}><br/>Im Test für Dich:</p>
                            <p style={{color: "#000000", textAlign: "center"}} className={"checkbox_text_p"}>
                                Der Kiez-Locker ist ein neuer <br/>Service. Eine Art Packstation zur <br/>Weitergabe von gutem Gebrauchten.<br/>Direkt bei Dir im Kiez. <br/><br/>
                                Wir freuen uns, dass Du mitmachst und diesen Service mit uns zusammen ausprobierst! Teile gerne Deine Erfahrungen und<br/><a href="mailto:kiez-locker@bsr.de">schreibe uns.</a>
                            </p>
                        </div>
                    </div>
                    <div className={"stoerer_rund_klein"}>
                        <img src={stoerer_rund}/>
                        <div>
                            <p className={"fliesstext_p"}>Jetzt<br/>auch in<br/></p>
                            <p style={{color: "#BD451C"}} className={"fliesstext_p"}>Adlershof</p>
                        </div>

                    </div>
                </div>
                <div className={"start_site_content_item start_site_content_item_mobileStoerer"}>
                    <div>
                        <img src={corner}/>
                    </div>
                        <p style={{color: "#BD451C"}} className={"fliesstext_p"}>Im Test für Dich:</p>
                            <p style={{color: "#000000", textAlign: "center"}} className={"checkbox_text_p"}>
                                Der Kiez-Locker ist ein neuer Service. <br/>Eine Art Packstation zur Weitergabe von gutem Gebrauchten. Direkt bei Dir im Kiez. <br/><br/>
                                Wir freuen uns, dass Du mitmachst und diesen Service <br/>mit uns zusammen ausprobierst! <br/>Teile gerne Deine Erfahrungen und<br/>
                                <a href="mailto:kiez-locker@bsr.de">schreibe uns.</a>
                            </p>

                </div>
                <div id={"stoerer_rund_klein"} className={"start_site_content_item start_site_content_item_mobileStoerer"}>
                    <div>
                        <img src={corner}/>
                    </div>
                        <p className={"fliesstext_p"}>Demnächst auch in</p>
                        <p style={{color: "#BD451C", textAlign: "center"}} className={"checkbox_text_p"}>Adlershof</p>
                </div>
                <div style={isLoggedInVar || isLoggedIn ? {} : {display: "none"}}
                     className="start_site_content_item start_site_content_item_main_field_background">
                    <div className="start_site_content_item_main_field">
                        <div className="start_site_content_item_main_field_title">
                            <div>
                                <h2 className="untertitel_h2">Mein Bereich</h2>
                            </div>
                            <div>
                                <h4 className="teaser_h4">Eine Übersicht über Deine Aktivitäten</h4>
                            </div>
                        </div>
                        <div className="start_site_content_item_main_field_btns">
                            <div onClick={redirect_to_posts} className="start_site_content_item_main_field_btns_item">
                                <div className="inbox_notification">
                                </div>
                                <img src={Beiträge_icon}/>
                                <h3 className="quadrat_btn_beschriftung">Inserate</h3>
                            </div>
                            <div className="start_site_content_item_main_field_btns_item" onClick={redirect_to_bookings}>
                                <div className="inbox_notification">
                                </div>
                                <img src={Buchungen_icon}/>
                                <h3 className="quadrat_btn_beschriftung">Buchungen</h3>
                            </div>

                            <div onClick={redirect_to_chat} className="start_site_content_item_main_field_btns_item">
                                <div className="inbox_notification">
                                    {isTesting || notificationCounter > 0 ?
                                        <span className="notifications_badge">{notificationCounter}</span> : null}
                                </div>
                                <img src={inbox_icon}/>
                                <h3 className="quadrat_btn_beschriftung">Postfach</h3>
                            </div>
                            <div className="start_site_content_item_main_field_btns_item" onClick={redirect_to_profile}>
                                <div className="inbox_notification">
                                </div>
                                <img src={Profile_icon}/>
                                <h3 className="quadrat_btn_beschriftung">Profil</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="start_site_market" className={"start_site_content_item start_site_content_item_markt"}>
                    <div className={"start_site_content_item_markt_titleSection"}>
                        <div>
                            <h2 style={{textAlign:"center"}} className="untertitel_h2">{"Kiez-Locker Marktplatz"}</h2>
                        </div>
                        <div>
                            <h4 style={{textAlign:"center"}} className="teaser_h4">Suchen und Weitergeben von gutem Gebrauchten im Kiez</h4>
                        </div>
                    </div>
                    <div style={{display:"none"}} className={"start_site_content_item_markt_guideInfo"}>
                        <p style={{color: "#a0a0a0"}} className={"fliesstext_p"}>Vorschau</p>
                    </div>
                    <div className={"start_site_content_item_markt_contentSection"}>

                        <div className={"start_site_content_item_markt_contentSection_left"}>
                            <div className={"start_site_content_item_markt_contentSection_left_posts"}>
                                {
                                    isTesting ?
                                    my_inserate.slice(0, 6).map(function (d) {
                                        return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                        type={d.type} isMyPost={true} path={d.image} id={d.id} standort={d.standort}/>)
                                    })
                                        :
                                    posts.slice(0, 6).map(function (d) {
                                        return (<Post key={d.id} date={d.date} views={d.views} title={d.title}
                                                      type={d.type} path={d.image} id={d.id} standort={d.standort}/>)
                                    })
                                }
                            </div>
                        </div>
                        <div className={"start_site_content_item_markt_contentSection_right"}>
                            <div>
                                <p className={"fliesstext_p"}>
                                    Der Marktplatz bietet die Möglichkeit gutes Gebrauchtes kieznah weiterzugeben und
                                    selbst auf der Plattform nach Schätzen zu stöbern.<br/>
                                    Die Kiez-Locker in Adlershof und Tempelhof stehen täglich als Übergabestation zur Verfügung.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"start_site_content_item_markt_btnField"}>
                        <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}} className={"ueberschrift_btn"} onClick={redirect_to_market}>Zum Marktplatz</button>
                    </div>
                </div>
                <div id="start_seite_locker" style={{flexDirection: "column"}}
                     className="start_site_content_item start_site_content_item_guide_background">

                    <div style={{paddingTop: "50px"}}
                         className="start_site_content_item_guide_item start_site_content_item_guide_item_text_centering">
                        <h2 className="untertitel_h2">{"Die BSR Kiez-Locker"}</h2>
                    </div>
                    <div style={{paddingBottom: "20px"}}
                         className="start_site_content_item_guide_item start_site_content_item_guide_item_text_centering">
                        <h4 className="teaser_h4">Eine Übergabestation für alle im Kiez</h4>
                    </div>

                    <div className="start_site_content_item_guide">

                        <div className="start_site_content_item_guide_item start_site_content_item_guide_item_left">
                            <div>
                                <p className="fliesstext_p">Im Kiez-Locker können Schließfächer individuell
                                gebucht und für die Übergabe von Artikeln genutzt werden.
                                Verschenken, Tauschen, kurzfristiges Verwahren oder Weitergeben an die NochMall sind
                                über unsere Kiez-Locker möglich.
                                <br/><br/>Für die Buchung der Schließfächer musst Du Dich registrieren bzw.
                                einloggen.</p>
                            </div>
                        </div>
                         <div className="start_site_content_item_guide_item start_site_content_item_guide_item_right">
                            <div style={MscreenWidth.matches ? {display:"none"}:{}} className="guide_item">
                                    {
                                        screenWidth.matches ?
                                            <h4 className={"teaser_h4"}>In vier Schritten<br/>zur Buchung:</h4>
                                            :
                                            <h4 className={"teaser_h4"}>In vier Schritten zur Buchung:</h4>
                                    }

                                </div>
                                 <div className="guide_item">
                                        <div className="guide_item_step">
                                            <h4 style={{fontWeight: "bold", color: "white"}} className="teaser_h4">1</h4>
                                        </div>
                                        <div className="guide_item_text">
                                            {
                                                screenWidth.matches ?
                                                    <h4 className="teaser_h4">Standort auswählen</h4>
                                                    :
                                                    <h4 className="teaser_h4">Standort auswählen</h4>
                                            }

                                        </div>
                                    </div>
                                <div className="guide_item">
                                    <div className="guide_item_step">
                                        <h4 style={{fontWeight: "bold", color: "white"}} className="teaser_h4">2</h4>
                                    </div>
                                    <div className="guide_item_text">
                                        {
                                            screenWidth.matches ?
                                                <h4 className="teaser_h4">Fachgröße auswählen</h4>
                                                :
                                                <h4 className="teaser_h4">Fachgröße auswählen</h4>
                                        }

                                    </div>
                                </div>
                                <div className="guide_item">
                                    <div className="guide_item_step">
                                        <h4 style={{fontWeight: "bold", color: "white"}} className="teaser_h4">3</h4>
                                    </div>
                                    <div className="guide_item_text">
                                        <h4 className="teaser_h4">Empfänger:in angeben</h4>
                                    </div>
                                </div>
                                <div className="guide_item">
                                    <div className="guide_item_step">
                                        <h4 style={{fontWeight: "bold", color: "white"}} className="teaser_h4">4</h4>
                                    </div>
                                    <div className="guide_item_text">
                                        <h4 className="teaser_h4">Bestätigen</h4>
                                    </div>
                                </div>
                            </div>

                        {
                            MscreenWidth.matches ?
                                <div className={"start_site_content_item_guide_item"}>
                                    <h4 className={"teaser_h4"}>In vier Schritten zur Buchung:</h4>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div style={{paddingBottom: "20px"}} className="start_site_content_item_guide_item  start_site_content_item_guide_item_btn_field">
                        <button style={{
                                    "--btn_font_color": "#ffffff",
                                    "--btn_background_color": "#f61",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}
                                        className="ueberschrift_btn" type="button"
                                        onClick={localStorage.getItem("token") ? redirect_to_insert_box : redirect_to_login}>
                                    {localStorage.getItem("token") ? "Zur Fachbuchung " : "Jetzt Einloggen"}</button>
                    </div>
                </div>
                <div className={"start_site_content_item start_site_content_item_package_station_title"}>
                    <h4 className="teaser_h4">Hier findest Du unsere Kiez-Locker</h4>
                </div>
                {/*Nich vergessen!!!!!*/}
                <div style={MscreenWidth.matches? {flexDirection:"column"}:{flexDirection:"row"}}  className={toggleAhClass || toggleThClass ? "start_site_content_item start_site_content_item_package_station" : "start_site_content_item start_site_content_item_package_station_stretch"}>
                    <div className="start_site_content_item_package_station_item start_site_content_item_package_station_item_terminalsListItem">
                        <h4 className="teaser_h4">Kiez-Locker Tempelhof</h4>
                        <div className={"start_site_content_item_package_station_item_terminalsListItem_details"}>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_details_item"}>
                                <img src={StandOrt}/>
                                <div>
                                    <p className="fliesstext_p">Ringbahnstraße 96</p>
                                    <p className="fliesstext_p">12103 Berlin Tempelhof</p>
                                    <p className="fliesstext_p">Auf dem BSR Betriebsgelände</p>
                                </div>
                            </div>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_details_item"}>
                                <img src={openingOhrs}/>
                                <div>
                                    <p className="fliesstext_p">Montag - Freitag</p>
                                    <p className="fliesstext_p">04:00 Uhr - 20:00 Uhr</p>
                                    <p className="fliesstext_p">Samstag, Sonntag und feiertags</p>
                                    <p className="fliesstext_p">05:00 Uhr - 14:00 Uhr</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => toggleTh()} className={"start_site_content_item_package_station_item_terminalsListItem_btnSection"}>
                            <p style={{color:"#f61"}} className={"fliesstext_p_bold"}>Schau Dir die Auslastung an</p>
                            <button className={"question-btn"} >
                                <span style={toggleThClass ? {display:"inline"} : {display:"none"}} className="up-icon">
                                    <i style={{color:"#f61", fontSize:"30px"}} className="fas fa-chevron-up"></i>
                                </span>
                                <span style={toggleThClass ? {display:"none"} : {display:"inline"}} className="down-icon">
                                    <i style={{color:"#f61", fontSize:"30px"}} className="fas fa-chevron-down"></i>
                                </span>
                            </button>
                        </div>
                        <div className={toggleThClass ? "start_site_content_item_package_station_item_terminalsListItem_contentSection" : "hide_content"}>
                            {
                                LscreenWidth.matches ?
                                    <p style={{hyphens:"auto"}} className={"fliesstext_p"}>Im Kiez-Locker Tempelhof hast Du die Auswahl zwischen fünf verschiedenen Fachgrößen &nbsp;&ndash;
                                vom Aktenordner bis zum Staubsauger.</p>
                                    :
                                    <p style={{hyphens:"auto"}} className={"fliesstext_p"}>Im Kiez-Locker Tempelhof hast Du die Auswahl zwischen fünf verschiedenen Fachgrößen &nbsp;&ndash;<br/>
                                vom Aktenordner bis zum Staubsauger.</p>
                            }

                            <div className={"start_site_content_item_package_station_item_sizesTable " +
                                "start_site_content_item_package_station_item_sizesTable_laptopScreen1"}>
                                <div className={"start_site_content_item_package_station_item_sizesTable_left"}>
                                    <p className={"fliesstext_p"}>XS</p>
                                    <p className={"fliesstext_p"}>S</p>
                                    <p className={"fliesstext_p"}>M</p>
                                    <p className={"fliesstext_p"}>L</p>
                                    <p className={"fliesstext_p"}>XL</p>
                                </div>
                                <div className={"start_site_content_item_package_station_item_sizesTable_right"}>
                                    <p className={"fliesstext_p"}>09 x 48 x 61 cm</p>
                                    <p className={"fliesstext_p"}>20 x 48 x 61 cm</p>
                                    <p className={"fliesstext_p"}>33 x 48 x 61 cm</p>
                                    <p className={"fliesstext_p"}>43 x 48 x 61 cm</p>
                                    <p className={"fliesstext_p"}>73 x 48 x 61 cm</p>
                                </div>
                            </div>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_contentSection_terminal"}>
                                <div style={{flexDirection:"row"}} className={"terminal_explain_items"}>
                                    <div className={"terminal_explain_item"}>
                                        <div className="not_free_box"></div>
                                        <div>
                                            <h4 className={"teaser_h4"}>belegt</h4>
                                        </div>
                                    </div>
                                    <div className={"terminal_explain_item"}>
                                        <div className="free_box"></div>
                                        <div>
                                            <h4 className={"teaser_h4"}>frei</h4>
                                        </div>
                                    </div>
                                </div>
                                {
                                    screenWidth.matches ?
                                        <Box_selection isAdmin={false} lockerWidth={`calc(90vw - 90px)`}></Box_selection>
                                        :
                                        MscreenWidth.matches ?
                                            <Box_selection isAdmin={false} lockerWidth={`calc(80vw - 90px)`}></Box_selection>
                                            :
                                            LscreenWidth.matches ?
                                                <Box_selection isAdmin={false} lockerWidth={`calc(39vw - 90px)`}></Box_selection>
                                                :
                                                XLscreenWidth.matches ?
                                                    <Box_selection isAdmin={false} lockerWidth={`calc(39vw - 90px)`}></Box_selection>
                                                    :
                                                <Box_selection isAdmin={false} lockerWidth={`calc(36.5vw - 90px)`}></Box_selection>
                                }
                            </div>


                        </div>
                    </div>
                    <div className="start_site_content_item_package_station_item start_site_content_item_package_station_item_terminalsListItem">
                        <h4 className="teaser_h4">Kiez-Locker Adlershof</h4>
                        <div style={{paddingBottom: "40px"}} className={"start_site_content_item_package_station_item_terminalsListItem_details"}>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_details_item"}>
                                <img src={StandOrt}/>
                                <div>
                                    <p className="fliesstext_p">Groß-Berliner Damm 150</p>
                                    <p className="fliesstext_p">12489 Berlin Adlershof</p>
                                </div>
                            </div>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_details_item"}>
                                <img src={openingOhrs}/>
                                <div>
                                    <p className="fliesstext_p">24/7 erreichbar</p>

                                </div>
                            </div>

                        </div>
                        <div style={{display:"flex", flex:"1"}}></div>
                        <div onClick={() => toggleAh()} className={"start_site_content_item_package_station_item_terminalsListItem_btnSection"}>
                            <p style={{color:"#f61"}} className={"fliesstext_p_bold"}>Schau Dir die Auslastung an</p>
                            <button className={"question-btn"} >
                                <span style={toggleAhClass ? {display:"inline"} : {display:"none"}} className="up-icon">
                                    <i style={{color:"#f61", fontSize:"30px"}} className="fas fa-chevron-up"></i>
                                </span>
                                <span style={toggleAhClass ? {display:"none"} : {display:"inline"}} className="down-icon">
                                    <i style={{color:"#f61", fontSize:"30px"}} className="fas fa-chevron-down"></i>
                                </span>
                            </button>
                        </div>
                        <div className={toggleAhClass ? "start_site_content_item_package_station_item_terminalsListItem_contentSection" : "hide_content"}>
                            {
                                LscreenWidth.matches ?
                                    <p style={{hyphens:"auto"}} className={"fliesstext_p"}>Im Kiez-Locker Adlershof gibt es vier Fächer der BSR. Er
                                    steht auf dem Gelände der Charlottenburger Baugenossenschaft eG.<br/>
                                    Alle Fächer haben die gleiche Größe mit viel Platz.</p>
                                    :
                                    <p style={{hyphens:"auto"}} className={"fliesstext_p"}>Im Kiez-Locker Adlershof gibt es vier Fächer der BSR. Er
                                    steht auf dem Gelände der Charlottenburger Baugenossenschaft eG.<br/>
                                    Alle Fächer haben die gleiche Größe mit viel Platz.</p>
                            }
                            <div style={{paddingBottom: "120px"}} className={"start_site_content_item_package_station_item_sizesTable " +
                                "start_site_content_item_package_station_item_sizesTable_laptopScreen1"}>
                                <div className={"start_site_content_item_package_station_item_sizesTable_left"}>
                                    <p className={"fliesstext_p"}>XL</p>
                                </div>
                                <div className={"start_site_content_item_package_station_item_sizesTable_right"}>
                                    <p className={"fliesstext_p"}>40 x 60 x 70 cm</p>
                                </div>
                            </div>
                            <div className={"start_site_content_item_package_station_item_terminalsListItem_contentSection_terminal"}>
                                <div style={{flexDirection:"row"}} className={"terminal_explain_items"}>
                                    <div className={"terminal_explain_item"}>
                                        <div className="not_free_box"></div>
                                        <div>
                                            <h4 className={"teaser_h4"}>belegt</h4>
                                        </div>
                                    </div>
                                    <div className={"terminal_explain_item"}>
                                        <div className="free_box"></div>
                                        <div>
                                            <h4 className={"teaser_h4"}>frei</h4>
                                        </div>
                                    </div>
                                </div>
                                {
                                    screenWidth.matches ?
                                        <Box_selection isAdmin={false} lockerWidth={`calc(90vw - 90px)`} location={"AH"}></Box_selection>
                                        :
                                        MscreenWidth.matches ?
                                            <Box_selection isAdmin={false} lockerWidth={`calc(80vw - 90px)`} location={"AH"}></Box_selection>
                                            :
                                            LscreenWidth.matches ?
                                                <Box_selection isAdmin={false} lockerWidth={`calc(39vw - 90px)`} location={"AH"}></Box_selection>
                                                :
                                                XLscreenWidth.matches ?
                                                    <Box_selection isAdmin={false} lockerWidth={`calc(39vw - 90px)`} location={"AH"}></Box_selection>
                                                    :
                                                <Box_selection isAdmin={false} lockerWidth={`calc(36.5vw - 90px)`} location={"AH"}></Box_selection>
                                }
                            </div>


                        </div>

                    </div>
                </div>
                <div className={"start_site_content_item start_site_content_item_info"}>
                    <div style={{backgroundColor:"#f61"}} className={"start_site_content_item_info_box"}>
                        <h4 className={"teaser_h4"}>Noch Fragen? Wir sind für Dich da!</h4>
                        <div style={screenWidth.matches ? {width:"100%",justifyContent:"space-between"} : {width:"70%",justifyContent:"space-between"}} className={"start_site_content_item_info_box_items"}>
                            <div style={{flex:".5"}} className={"start_site_content_item_info_box_items_item"}>
                                <img src={questions}/>
                                <div>
                                    {
                                        screenWidth.matches ?
                                            <p style={{minWidth:"max-content", textAlign:"left"}} className="fliesstext_p_bold">030 7592-4900</p>
                                            :
                                            <h4 style={{minWidth:"max-content", textAlign:"left"}} className="teaser_h4">030 7592-4900</h4>
                                    }
                                    <p className="fliesstext_p">Unser Service-Center erreichst Du montags bis freitags von 07:00 bis 19:00 Uhr, samstags von 08:00 bis 14:00 Uhr.</p>
                                </div>
                            </div>
                            <div style={{flex:".5"}} className={"start_site_content_item_info_box_items_item"}>
                                <img src={postfach}/>
                                <div>
                                    {
                                        screenWidth.matches ?
                                            <p style={{minWidth:"max-content", textAlign:"left"}} className="fliesstext_p_bold">
                                                <a href="mailto:kiez-locker@bsr.de">kiez-locker@bsr.de</a>
                                            </p>
                                            :
                                            <h4 style={{minWidth:"max-content", textAlign:"left"}} className="teaser_h4">
                                                <a href="mailto:kiez-locker@bsr.de">kiez-locker@bsr.de</a>
                                            </h4>
                                    }

                                    <p className="fliesstext_p">Du kannst uns Deine Anfragen auch per E-Mail schicken.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="start_site_content_item start_site_content_item_faq_background">
                    <div className="start_site_content_item_faq_field">
                        <div>
                            <h2 style={{color: "white"}} className="untertitel_h2">FAQ</h2>
                        </div>
                        <Ad_main_faq/>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Start_seite;

/*
<div className="start_site_content_item start_site_content_item_location_field">
                        <div className="start_site_content_item_location_field_item">
                            <img src={StandOrt}/>
                            <div>
                                <p className="fliesstext_p_bold">Ringbahnstraße 96,</p>
                                <p className="fliesstext_p_bold">12103 Berlin Tempelhof</p>
                                <p className="fliesstext_p">Auf dem BSR Betriebsgelände</p>
                            </div>
                        </div>
                        <div className="start_site_content_item_location_field_item">
                            <img src={openingOhrs}/>
                            <div>
                                <p className="fliesstext_p_bold">Montag - Freitag</p>
                                <p className="fliesstext_p">04:00 Uhr - 20:00 Uhr</p>
                                <p className="fliesstext_p_bold">Samstag, Sonntag und feiertags</p>
                                <p className="fliesstext_p">05:00 Uhr - 14:00 Uhr</p>
                            </div>
                        </div>
                    </div>
* */