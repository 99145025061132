import React, {useEffect, useState} from 'react';
import './registration_conditions.css';
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";


function Registration_conditions() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);
    return (
        <div className="flexSequence">
            <main class="main">
                <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
                <div className="registration_conditions_content">
                    <h1>Registrierungsbedingungen Kiez-Locker</h1>

                    <div>
                        <h2>1.	Präambel</h2>
                        <p>
                            Die Berliner Stadtreinigung (AöR) und das Fraunhofer-Institut für Produktionsanlagen und Konstruktionstechnik (Fraunhofer IPK) entwickeln ein nachhaltiges und integriertes Logistiksystem, in dem zwischen mehreren Beteiligten an einem Hub oder Kiez-Locker (Schließfachschrank) Gegenstände im Self Service weitergegeben werden können. Betrieben wird der Kiez-Locker (Hardware) von der BSR, die Software zunächst von Fraunhofer.<br/>
                            Einsatzorte sind zunächst im Bereich der Ringbahnstraße in Tempelhof und des Groß-Berliner Damms in Adlershof.<br/>
                            <br/>
                            In diesem Kontext wird zusätzlich im Internet eine Online-Plattform installiert,
                            <li>auf der sich Nutzer:innen für den Service registrieren und/oder einloggen</li>
                            <li>auf der Nutzer:innen Fächer buchen können, um Gegenstände über den Kiez-Locker weiterzugeben, bspw. gutes Gebrauchtes an andere Nutzer:innen oder das Gebrauchtwarenkaufhaus der BSR, die NochMall.</li>
                            <br/>
                            Die nachfolgenden Bestimmungen regeln die Registrierungsbedingen als Voraussetzung der Nutzung des Kiez-Lockers.
                        </p>
                    </div>

                    <div>
                        <h2>2.	Anwendungsbereich; Vertragssprache</h2>
                        <p>
                            2.1.  Für die Nutzung des Kiez-Lockers ist eine Registrierung auf Basis der vorliegenden Bedingungen notwendig. Die registrierungspflichtigen Nutzer:innen sind die Beteiligten des Einstell- und Entnahmevorgangs des Kiez-Lockers.
                        </p>
                        <p></p>
                        <p>
                            2.2.  Vertragspartner sind die Berliner Stadtreinigung (AöR), Ringbahnstr. 63, 12103 Berlin und die Nutzenden. Durch Abschluss des Online-Registrierungsprozesses und Erstellung eines Nutzerkontos wird ein Vertrag zwischen der/dem Nutzer:in und der BSR geschlossen.
                        </p>
                        <p></p>
                        <p>2.3.	 Die vorrangig geltende Sprache dieser Registrierungsbedingungen ist Deutsch.</p>
                    </div>

                    <div>
                        <h2>3.	Registrierung und Änderungsmitteilungen</h2>
                        <p>
                        3.1.	Die Registrierung erfolgt auf Anfrage der/des Nutzenden bei der BSR via Website unter Angabe des Vor- und Nachnamens, einer gültigen E-Mail-Adresse, einer gültigen Telefonnummer und eines frei wählbaren Nutzernamens. Die Verifizierung der/des Nutzenden und die Bestätigung der Registrierung erfolgt durch die Versendung eines Links via E-Mail zur Aktivierung des Nutzerkontos sowie durch die Verifikation der angegebenen Telefonnummer. Die/der Nutzer:in wird aufgefordert, ein individuelles Passwort festzulegen.
                        </p>
                        <p></p>
                        <p>
                            3.2.	Ein Anspruch einer/eines Nutzenden auf Registrierung besteht nicht.
                        </p>
                        <p>
                            3.3.	Bei Änderung der E-Mail-Adresse verpflichtet sich die/der Nutzer:in, die BSR über diese Änderung im Online-Portal unverzüglich zu informieren. Die/der Nutzer:in hat dafür Sorge zu tragen, dass ihre/seine Daten im Online-Portal auf dem aktuellen Stand sind und prüft alle von ihr/ihm eingegebenen Daten auf Vollständigkeit und Richtigkeit.
                        </p>
                    </div>

                    <div>
                        <h2>4.	Kosten, Verfügbarkeit, Wartungsarbeiten</h2>
                        <p>
                           4.1.	  Die BSR stellt den Nutzenden die Benutzung der Webseite, insbesondere die technische Infrastruktur, unentgeltlich zur Verfügung. Sollten im Rahmen der Nutzung der Webseite Kosten, z.B. für den erforderlichen Internetzugang, anfallen, hat diejenige/derjenige die Kosten zu tragen, bei der/dem sie anfallen.
                        </p>
                        <p></p>
                        <p>
                        4.2.	Die Verfügbarkeit und die Funktionsfähigkeit der Webseite kann nicht ununterbrochen gewährleistet werden und aufgrund von Umständen außerhalb des Wirkungs- bzw. Machtbereichs der BSR, z. B. technische Störungen oder Ausfälle ohne Verschulden der BSR, zeitweise nicht verfügbar sein. Wegen solcher äußeren Umstände ergeben sich keine Ansprüche der/des Nutzenden gegenüber der BSR.
                        </p>
                    </div>

                    <div>
                        <h2>5.	Maßnahmen bei Verletzungshandlungen</h2>
                        <p>
                            5.1.	Die BSR ist bei Bestehen konkreter Anhaltspunkte für eine Verletzung gesetzlicher Vorschriften, der vorliegenden Bedingungen berechtigt
                            <p>
                                a)	die Registrierung oder Aktivierung des Nutzer:innen-Kontos abzulehnen
                            </p>
                            <p></p>
                            <p>
                                b)	das Nutzer:innen-Konto vorübergehend zu sperren oder endgültig zu löschen.
                            </p>
                        </p>
                        <p></p>
                        <p>
                        5.2.	Bei einer Sperrung des Nutzer:innen-Kontos besteht kein Anspruch auf Wiederherstellung dieses gesperrten Kontos.
                        </p>
                    </div>

                    <div>
                        <h2>6.	Datenschutz</h2>
                        <p>
                            Die Registrierung sowie die Nutzung erfolgt auf Anfrage der/des Nutzenden und impliziert die Erhebung und Verarbeitung personenbezogener Daten der/des Nutzenden im für die Bereitstellung und Nutzung aufgrund der vorliegenden Bedingungen notwendigen Umfang. Weitere Informationen über die Verarbeitung von personenbezogenen Daten können der Datenschutzinformation der BSR entnommen werden.  (<a href="/data_protection_policy" target="_blank">Datenschutzerklärung</a>)
                        </p>
                    </div>

                    <div>
                        <h2>7.	Haftung</h2>
                        <p>
                           7.1.	Soweit sich aus den vorliegenden Bedingungen nicht etwas anderes ergibt, haftet die BSR bei einer Verletzung von vertraglichen und außervertraglichen Pflichten nach den gesetzlichen Vorschriften.
                        </p>
                        <p></p>
                        <p>
                            7.2.	Auf Schadensersatz haftet die BSR – gleich aus welchem Rechtsgrund – im Rahmen der Verschuldenshaftung bei Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet die BSR nur
                            <li>für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit,</li>
                            <li>für Schäden aus der nicht unerheblichen Verletzung einer wesentlichen Vertragspflicht (Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung die andere Vertragspartei regelmäßig vertraut und vertrauen darf); in diesem Fall ist die Haftung der BSR jedoch auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens begrenzt.</li>
                        </p>
                        <p></p>
                        <p>
                            7.3.	Die sich aus Ziffer 7.2 ergebenden Haftungsbeschränkungen gelten auch bei Pflichtverletzungen durch bzw. zugunsten von Personen, deren Verschulden die BSR nach gesetzlichen Vorschriften zu vertreten hat. Sie gelten nicht, soweit die BSR einen Mangel arglistig verschwiegen hat oder nach gesetzlich zwingenden Regelungen haftet.
                        </p>
                        <p>
                            7.4. 	Die BSR haftet nicht für die Leistung von Internet- oder Serviceprovidern.
                        </p>
                    </div>

                    <div>
                        <h2>8.	Vertragsdauer; Kündigung</h2>
                        <p>
                            8.1.	Der zwischen den Parteien auf Basis dieser Registrierungsbedingungen geschlossene Vertrag wird auf unbestimmte Zeit geschlossen. Die BSR sowie die/der Nutzer:in können die Registrierung jederzeit ohne Einhaltung einer Frist stornieren oder das Konto der /des Nutzenden löschen.
                        </p>
                        <p></p>
                        <p>
                         8.2.	Im Falle einer Kündigung wird das Konto der/des Nutzenden deaktiviert, so dass die/der Nutzer:in diese Webseite nicht mehr benutzen kann. Die Nutzer:innen-Daten werden gelöscht.
                        </p>
                    </div>

                    <div>
                        <h2>9.	Sonstige Bestimmungen</h2>
                        <p>
                           9.1.	Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Für alle Streitigkeiten aus oder im Zusammenhang mit diesen Registrierungsbedingungen ist der Gerichtsstand Berlin vereinbart.
                        </p>
                        <p></p>
                        <p>
                           9.2.	Nebenabreden, Änderungen und Ergänzungen bedürfen zu ihrer Wirksamkeit der Textform. Die BSR kann die Registrierungsbedingungen ändern. Sie wird die geänderten Bedingungen per E-Mail der/dem Nutzer:in mitteilen. Die geänderten Bedingungen werden im Verhältnis zur/zum Nutzer:in wirksam, wenn diese/r nicht innerhalb von 2 Wochen nach Bekanntgabe in Textform widerspricht. Die BSR wird die/den Nutzer:in auf diese Folge aufmerksam machen.
                        </p>
                        <p></p>
                        <p>
                            9.3.	Sollten einzelne oder mehrere Bestimmungen ganz oder teilweise unwirksam sein oder werden, wird hiervon die Gültigkeit der übrigen Bestimmungen nicht berührt. Jede/r Vertragspartner:in hat in diesem Fall das Recht, die Vereinbarung einer gültigen durchführbaren Bestimmung zu verlangen, die dem mit der unwirksamen Bestimmung verfolgten Zweck am nächsten kommt.
                        </p>
                        <h4><br/>Stand Juni 2023</h4>
                    </div>
                </div>
                <Footer/>
            </main>

        </div>
    )
}

export default Registration_conditions;