import React from 'react';
import {createPortal} from "react-dom";
import x_icon from '../../static/modal/X_grau.svg'
import "./modal.css";

const Modal = ({ isOpened, children, onClose }) => {
  if (!isOpened) {
    return null;
  }

  return createPortal(
    <div>
      <div className="overlay"></div>
      <div className="modal">

          <div style={{display:"flex"}}>
              <div style={{flex:"1"}}></div>
              {/*<span className="close-button" onClick={onClose}>X</span>*/}
              <img style={{width:"24px", cursor:"pointer"}} onClick={onClose} src={x_icon}/>
          </div>


          <div className="modal-content">{
              children}</div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;