import React, {useEffect, useState} from 'react';
import img_place_holder from "../../static/market/Kein Bild (1).svg"
import img_views from "../../static/market/Auge_hellgrau.svg"
import test from "../../static/profile/test.png"
import Post_details from "./post_details";
import RestApi from "../../RestAPI";
import {isTesting, MY_URL} from "../../App";

function Post({date, views, type, standort, title, isMyPost, path = "", id = ""}) {

    //isTesting ? date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) : date = null
    //isTesting ? date = new Date(2023, 1, 30) : date = null
    const [image, setImage] = useState(img_place_holder)
    const redirect_to_post_details = () => {
        window.location.href = `/post_details/`+id;

    }
    useEffect(() => {
        getImage();
    }, [])

    function getImage() {
        RestApi("/api/get_post_image/" + id, "GET", {}).then(response => response.blob())
            .then(images => {
                setImage(URL.createObjectURL(images))
            }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="market_page_content_posts_section_components_posts_row_item">
            <div style={{backgroundImage: `url(${image})`, backgroundSize:"contain", backgroundRepeat:"no-repeat", backgroundPosition:"center"}} onClick={redirect_to_post_details} className="market_page_content_posts_section_components_posts_row_item_img_body">

            </div>
            <div className="market_page_content_posts_section_components_posts_row_item_text_component market_page_content_posts_section_components_posts_row_item_text_component_row">
                <p className={"tootip_content_p_gray"}>{date}</p>
                <p className={"tootip_content_p_gray"}>{standort}</p>

            </div>
            <div className="market_page_content_posts_section_components_posts_row_item_text_component">
                <p className={"fliesstext_p"} style={{color: "#f61"}}>{type === "S" ? "Suchen" : "Weitergeben"}</p>
            </div>
            <div className="market_page_content_posts_section_components_posts_row_item_text_component">
                <p className={"fliesstext_p"}>{title}</p>
            </div>
        </div>
    )
}

export default Post;
