import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import telIcon from "../../static/icons/tel_icon.png";
import verifyIcon from "../../static/icons/verify_icon.jpg";
import personalsIcon from "../../static/icons/personals_icon.jpg";
import doneIcon from "../../static/icons/done_icon.jpg";
import {isTesting} from "../../App";
import validation from "./validation";

function Insert_box_period({switchToIndex, isAdminVar, isLoggedInVar}) {

    const [errors, setErrors] = useState({});
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [todayChecked, setTodayChecked] = React.useState(false);
    const [tomorrowChecked, setTomorrowChecked] = React.useState(false);
    const [in2DaysChecked, setIn2DaysChecked] = React.useState(false);
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const dateTomorrow = new Date();
    dateTomorrow.setDate(currentDate.getDate() + 1);
    const dateAfter2Days = new Date();
    dateAfter2Days.setDate(currentDate.getDate() + 2);
    const monthNames = ["Jan", "Feb", "März", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dez"
    ];
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [values, setValues] = useState({hours, minutes});
    function incrementHours(){
        if (hours < 24)
            setHours(hours + 1);
        else
            setHours(0);
    }
    function decrementHours(){
        if (hours < 1)
            setHours(24);
        else
            setHours(hours - 1);
    }
    function incrementMinutes(){
        if (minutes < 60)
            setMinutes(minutes + 15);
        else
            setMinutes(0);
    }
    function decrementMinutes(){
        if (minutes < 1)
            setMinutes(60);
        else
            setMinutes(minutes - 15);
    }
    function handleTodayCheckBoxChange(){
        setTodayChecked(!todayChecked);
    }
    function handleTomorrowCheckBoxChange(){
        setTomorrowChecked(!tomorrowChecked);
    }
    function handleIn2DaysCheckBoxChange(){
        setIn2DaysChecked(!in2DaysChecked);
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Insert_box_period", todayChecked, tomorrowChecked, in2DaysChecked));
        setDataIsCorrect(true);
    }
    useEffect(() =>{
        if (Object.keys(errors).length === 0 && dataIsCorrect){
            isTesting ? switchToIndex("Insert_box_selection") : switchToIndex("Insert_box_selection");
        }
    }, [errors]);

    useEffect(() =>{
        if (todayChecked){
            document.getElementById("tomorrow").checked = false;
            document.getElementById("in2Days").checked = false;
            setTomorrowChecked(false);
            setIn2DaysChecked(false);
        }
        if (tomorrowChecked){
            document.getElementById("today").checked = false;
            document.getElementById("in2Days").checked = false;
            setTodayChecked(false);
            setIn2DaysChecked(false);
        }
        if (in2DaysChecked){
            document.getElementById("today").checked = false;
            document.getElementById("tomorrow").checked = false;
            setTodayChecked(false);
            setTomorrowChecked(false);
        }
    });
    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="info_box_green_background">
                <div style={{marginLeft:"10px", alignItems:"left", justifyContent:"left", marginTop:"0px", flexDirection:"row"}} className="flex_container">
                    <div style={{margin:"0px"}} className="flex_item">
                        <h1 style={{marginBottom:"0px", marginTop:"0px"}} className="global_style_h1_huge">Fachbuchung</h1>
                        <h2 style={{marginBottom:"0px", fontSize:"24px"}} className="global_style_h2">Überschrift 2</h2>
                    </div>
                </div>
            </div>
            <div className="info_box">
                <div className="insert_box_content">
                    <div className="insert_box_content_left">
                        <div className="register_text_line" style={{border: "3px solid #169c7c", boxShadow: "0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)", backgroundColor:"white"}}>
                            <div className="register_text_line_icon">
                                <img src={telIcon}/>
                            </div>
                            <div className="register_text_line_text">
                                <h2 className="global_style_h2_light_text">Empfänger</h2>
                            </div>
                        </div>
                        <div className="register_text_line">
                            <div className="register_text_line_icon">
                                <img src={verifyIcon}/>
                            </div>
                            <div className="register_text_line_text">
                                <h2 className="global_style_h2_light_text">Paketmaße</h2>
                            </div>
                        </div>
                        <div className="register_text_line">
                            <div className="register_text_line_icon">
                                <img src={personalsIcon}/>
                            </div>
                            <div className="register_text_line_text">
                                <h2 className="global_style_h2_light_text">Zeitraum</h2>
                            </div>
                        </div>
                        <div className="register_text_line">
                            <div className="register_text_line_icon">
                                <img src={doneIcon}/>
                            </div>
                            <div className="register_text_line_text">
                                <h2 className="global_style_h2_light_text">Fachauswahl</h2>
                            </div>
                        </div>
                    </div>
                    <div className="insert_box_content_right">
                        <div className="insert_box_content_right_item">
                            <h2 className="global_style_h2_light_text">Zeitraum:</h2>
                        </div>
                        <div className="insert_box_content_right_section">
                            <div className="insert_box_content_right_section_item">
                                <h2 className="global_style_h2_light_text">Datum:</h2>
                            </div>
                            <div className="insert_box_content_right_section_item">
                                <div className="period_content_left_item">
                                    <h2 className="global_style_h2_light_text_gray">{currentDate.getDate()}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{monthNames[currentDate.getMonth()]}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{currentDate.getFullYear()}</h2>
                                </div>
                                <div className="period_content_right_item">
                                    <label>
                                        <input id="today" name="today" className="checkbox_input" type="checkbox" checked={todayChecked} onChange={handleTodayCheckBoxChange}/>
                                        <span className="checkbox">
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="insert_box_content_right_section_item">
                                <div className="period_content_left_item">
                                    <h2 className="global_style_h2_light_text_gray">{dateTomorrow.getDate()}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{monthNames[dateTomorrow.getMonth()]}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{dateTomorrow.getFullYear()}</h2>
                                </div>
                                <div className="period_content_right_item">
                                    <label>
                                        <input id="tomorrow" name="tomorrow" className="checkbox_input" type="checkbox" checked={tomorrowChecked} onChange={handleTomorrowCheckBoxChange}/>
                                        <span className="checkbox">
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="insert_box_content_right_section_item">
                                <div className="period_content_left_item">
                                    <h2 className="global_style_h2_light_text_gray">{dateAfter2Days.getDate()}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{monthNames[dateAfter2Days.getMonth()]}</h2>
                                    <h2 className="global_style_h2_light_text_gray">{dateAfter2Days.getFullYear()}</h2>
                                </div>
                                <div className="period_content_right_item">
                                    <label>
                                        <input id="in2Days" name="in2Days" className="checkbox_input" type="checkbox" checked={in2DaysChecked} onChange={handleIn2DaysCheckBoxChange}/>
                                        <span className="checkbox">
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {errors.checked &&
                            <div style={{justifyContent:"flex-start"}} className="insert_box_content_right_item">
                                <h2 className="global_style_h2_light_text">{errors.checked}</h2>
                            </div>
                        }
                        </div>
                        <div className="insert_box_content_right_section">
                            <div className="insert_box_content_right_section_item">
                                <h2 className="global_style_h2_light_text">Uhrzeit:</h2>
                            </div>
                            <div className="insert_box_content_right_section_item">
                                <div className="period_content_time_part">
                                    <div className="period_content_time_up" onClick={incrementHours}></div>
                                    <div className="period_content_time_number">
                                        <h2 className="global_style_h2_light_text_gray">{hours}</h2>
                                    </div>
                                    <div className="period_content_time_down" onClick={decrementHours}></div>
                                </div>
                                <div className="period_content_time_part">
                                    <h2 className="global_style_h2_light_text_gray">:</h2>
                                </div>
                                <div className="period_content_time_part">
                                    <div className="period_content_time_up" onClick={incrementMinutes}></div>
                                    <div className="period_content_time_number">
                                        <h2 className="global_style_h2_light_text_gray">{minutes}</h2>
                                    </div>
                                    <div className="period_content_time_down" onClick={decrementMinutes}></div>
                                </div>
                                <div style={{marginLeft:"10px"}} className="period_content_time_part">
                                    <h2 className="global_style_h2_light_text_gray">Uhr</h2>
                                </div>
                            </div>

                        </div>




                        <div style={{justifyContent:"center"}} className="insert_box_content_right_item">
                            <button id="loginbtn" type="button" className="insert_box_submit_btn" onClick={handleFormSubmit}>Weiter</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}
export default Insert_box_period;
