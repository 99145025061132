import React, {useEffect, useRef, useState} from 'react';
import './add_post.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import download_img_icon from "../../static/market/Bild hinzufügen.svg"
import x_icon from "../../static/market/X im Kreis.svg"
import 'react-dropdown/style.css';
import Dropdown from "react-dropdown";
import RestApi from "../../RestAPI";
import validation from "./validation";
import {isTesting, MY_URL} from "../../App";
import {images} from "../../Helpers/CarouselData";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import lnc from "../../static/shutterstock_LNC_IPK.jpg";
import lnc_logo from "../../static/LNC Signet 2020 80.jpg";
import PTZ from "../../static/PTZ-Ausschnitt_V03.jpg";
import ipk_logo from "../../static/20_ipk_rgb_modul_send_de.png";
import irs from "../../static/irs.jpg";
import irs_logo from "../../static/IRS-Logo_Schrift_transparent.gif";
import IPB from "../../static/IPB.jpg";
import insel_project_logo from "../../static/insel_project_logo.jpg";
import Modal from "../modal";
let imgNum = 0;

function Post_preview({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [images, setImages] = useState([
            JSON.parse(localStorage.getItem("postInfos")).fileData1,
            JSON.parse(localStorage.getItem("postInfos")).fileData2,
            JSON.parse(localStorage.getItem("postInfos")).fileData3,
            JSON.parse(localStorage.getItem("postInfos")).fileData4
    ])
    const currentDate = new Date();
    const [currImg, setCurrImg] = useState(0);
    const [requestError, setRequestError] = useState("");
    const [publishPostModalOpened, setPublishPostModalOpened] = useState(false);
    const [infos, setInfos] = useState({})
    const inputFile1 = useRef(null)
    const inputFile2 = useRef(null)
    const inputFile3 = useRef(null)
    const inputFile4 = useRef(null)
    const [fileData1, setFileData1] = useState(null);
    const [fileData2, setFileData2] = useState(null);
    const [fileData3, setFileData3] = useState(null);
    const [fileData4, setFileData4] = useState(null);

    function backToEditMode(){
        switchToIndex("Add_post");
    }

    function switchToStart(){
        switchToIndex("Start_page");
    }
    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="add_post_screen">
                <div className="details_page_content">
                    <div style={{padding:"0px"}} className="details_page_content_item">
                        <div className="carousel">
                            <div className="carouselInner" style={{ backgroundImage: `url(${images[currImg]})` }}>
                                <div className={currImg === 0 ? "left_last" : "left"} onClick={() => {currImg > 0 && setCurrImg(currImg - 1);}}>
                                    {currImg === 0 ? null : <ArrowBackIosIcon style={{ fontSize: 30 }} />}
                                </div>
                                <div className="center">
                                </div>
                                <div className={currImg === images.length - 1 ? "right_last" : "right"} onClick={() => {currImg < images.length - 1 && setCurrImg(currImg + 1);}}>
                                    {currImg === images.length - 1 ? null : <ArrowForwardIosIcon style={{ fontSize: 30 }} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details_page_content_item details_page_content_item_white_bg">
                        <div className="details_page_content_item_post_details details_page_content_item_post_details_date_district">
                            <p className={"tootip_content_p_gray"}>{currentDate.getDate().toString() + "." + (currentDate.getMonth() + 1).toString() + "." + currentDate.getFullYear()}</p>
                            <p className={"tootip_content_p_gray"}>{JSON.parse(localStorage.getItem("postInfos")).category}</p>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <p className={"fliesstext_p"} style={{color:"#f61"}}>{
                                                JSON.parse(localStorage.getItem("postInfos")).postCategory === "Give" ? "Weitergeben"
                                                :
                                                JSON.parse(localStorage.getItem("postInfos")).postCategory ? "Tauschen"
                                                :
                                                "Suchen"}
                            </p>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <h4 className={"teaser_h4"} style={{color:"#000"}}>{JSON.parse(localStorage.getItem("postInfos")).title}</h4>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <p className={"fliesstext_p"} style={{color:"#a0a0a0"}}>{"Beschreibung"}</p>
                            <p className={"fliesstext_p"}>{JSON.parse(localStorage.getItem("postInfos")).description}</p>
                        </div>
                        <div style={{flex:"1"}}></div>
                        <div className="details_page_content_item_post_details details_page_content_item_post_details_btn_field">
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} onClick={backToEditMode} className="ueberschrift_btn">Zurück</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}}  onClick={() =>setPublishPostModalOpened(true)} className="ueberschrift_btn">Hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
            <Modal isOpened={publishPostModalOpened} onClose={() => setPublishPostModalOpened(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Dein Inserat wird nach Überprüfung veröffentlicht</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"flex-end", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={() => switchToStart()} className="ueberschrift_btn">Fertig</button>
                                </div>
                            </div>
                        </div>
            </Modal>
        </main>
    )
}

export default Post_preview;

/*
<div className="download_img_field_scope">
                            <div className="download_img_field_scope_item">
                                <img id={"uploadedImage"} onClick={onClickUpload} style={{width:"100px"}} src={download_img_icon}/>
                                <input type="file" id="file" onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    setFileData(event.target.files[0]);
                                document.getElementById("uploadedImage").src = URL.createObjectURL(event.target.files[0])}} accept="image/png, image/jpeg" ref={inputFile} style={{display:"none"}}/>
                            </div>
                            <div className="download_img_field_scope_item">
                                <p className="global_style_p_light_text">Bilddatein hierher ziehen oder clicken</p>
                            </div>
                            <button onClick={handleUploadImage}>upload</button>
                        </div>
* */
