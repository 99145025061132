import React from "react";
import Post from "../market/post";
import {isTesting} from "../../App";

const Chat_room = ({ username = "sam", title = "Iphone 14 Pro Max 512GB", newMessage = true}) =>{
    return (
        <div className={"chat_content_area_window_chatrooms_item"}>
            <div className={newMessage ? "chat_content_area_window_chatrooms_item_icon_withBorder" : "chat_content_area_window_chatrooms_item_icon"}>
                <p style={{color: "#fff"}} className={"fliesstext_p"}>{isTesting ? "sam"[0].toUpperCase() : username[0].toUpperCase()}</p>
            </div>
            <div className="chat_content_area_window_chatrooms_item_texts">
                <p className={"fliesstext_p"}>{isTesting ? "sam"[0].toUpperCase() + "sam".slice(1) : username[0].toUpperCase() + username.slice(1)}</p>
                <h4 style={{color:"#666666"}} className={newMessage ? "fliesstext_p_bold" : "fliesstext_p"}>{isTesting ? "Iphone 14 Pro Max 512GB" : title}</h4>
            </div>
        </div>
    )
}

export default Chat_room;
const My_message = ({
                        messageContent = "aaaaaaaaaaaaaaaaaaaa",
                        timestamp = "21.02.2023 12:00:00"
                    }) => {
    return (
        <div className={"my_messages"}>
            <div className={"my_messages_content"}>
                <div className={"my_messages_content_text"}>
                    <p className={"fliesstext_p"}>{isTesting ? messageContent : messageContent}</p>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                    <p className={"check_box_text"}>{isTesting ? timestamp : timestamp}</p>
                </div>
            </div>

        </div>
    )
}
const Your_message = ({
                          username = "sam",
                          messageContent = "Lorem ipsum dolor sit amet",
                          hideIcon = false,
                          timestamp = "21.02.2023 12:00:00"
                      }) => {
    return (
        <div className={"your_messages"}>

            <div className={"your_messages_content"}>
                {
                    !hideIcon &&
                    <div className={"your_messages_content_icon"}>
                        <p style={{color: "#fff"}}
                           className={"fliesstext_p"}>{isTesting ? "sam"[0].toUpperCase() : username[0].toUpperCase()}</p>
                    </div>
                }
                <div>
                    <div style={hideIcon ? {marginLeft : "55px"} : {}} className={"your_messages_content_text"}>
                        <p className={"fliesstext_p"}>{isTesting ? "aaaaaaaaaaaaaaaaa" : messageContent}</p>
                    </div>
                    <div style={{display:"flex", justifyContent:"flex-start", minWidth:"100%"}}>
                        <p className={"check_box_text"}>{isTesting ? timestamp : timestamp}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export {My_message, Your_message}