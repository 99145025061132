import irs from "../static/irs.jpg";
import lnc from "../static/shutterstock_LNC_IPK.jpg";
import PTZ from "../static/PTZ-Ausschnitt_V03.jpg";
import ipk_logo from "../static/20_ipk_rgb_modul_send_de.png";
import irs_logo from "../static/IRS-Logo_Schrift_transparent.gif";
import insel_project_logo from "../static/insel_project_logo.jpg";
import lnc_logo from "../static/LNC Signet 2020 80.jpg";
import IPB from "../static/IPB.jpg";


export const images = [
  {
    title: "LNC Hannover",
    subtitle: "LogisticNetwork Consultants GmbH",
    role: "Konsortialführer",
    img: lnc,
    logo: lnc_logo,
  },
  {
    title: "Fraunhofer IPK",
    subtitle: "",//Fraunhofer-Institut für Produktionsanlagen und Konstruktionstechnik
    role: "",//Konsortialpartner
    img: PTZ,
    logo: ipk_logo,
  },
  {
    title: "IRS",
    subtitle: "",//Leibniz-Institut für Raumbezogene Sozialforschung Erkner
    role: "",//Konsortialpartner
    img: irs,
    logo: irs_logo,
  },
  {
    title: "insel-projekt",
    subtitle: "",//insel-projekt.berlin UG
    role: "",//Konsortialpartner
    img: IPB,
    logo: insel_project_logo,
  },
];
