import React, {useEffect, useState} from 'react';
import './new_login.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import {isTesting, MscreenWidth, MY_URL, screenWidth} from "../../App";
import validation from "./validation";
import Modal from "../modal";
import RestAPI from "../../RestAPI";


function Enter_login_data({switchToIndex, isAdminVar, isLoggedInVar}) {

    const [isEmailVerificationModalOpened, setIsEmailVerificationModalOpened] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [values, setValues] = useState({
        emailPhone: "",
        userpass: ""
    })
    const [loginRequestError, setLoginRequestError] = useState("")
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleChange = (event) =>{
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }
    const handleFormSubmit = (event) => {
        event?.preventDefault();
        setErrors(validation(values, "Enter_login_data"));
        setDataIsCorrect(true);
    }
    useEffect(() =>{
        if (Object.keys(errors).length === 0 && dataIsCorrect){
            isTesting ? switchToIndex("Enter_login_data") : loginRequest();
        }
    }, [errors]);

    const handleForgotPass = (event) => {
        event.preventDefault();
        setForgotPass(true);
    }
    useEffect(() =>{
        if (forgotPass){
            switchToIndex("Forgot_pass");
        }
    });
    const redirect = () => {
        window.location.href = "/register";
    }
    function handleKeyDown(event) {
        event?.preventDefault();
        if(event.keyCode === 13){
           handleFormSubmit()
        }
    }

    const loginRequest = () => {
        let url = MY_URL + "/api/login";
        let header = {
            'Content-Type': 'application/json'
        };
        let options = {
            method: 'POST',
            body: JSON.stringify({
                email: values.emailPhone,
                password: values.userpass
            }),
            headers: header
        };
        fetch(url, options)
            .then((resp) => {
                return resp.json();
            })
            .then((response) => {
                if (response["status"] === 200) {
                    localStorage.setItem('token', response["token"]);
                    localStorage.setItem('role', response["role"]);
                    switchToIndex("Start_seite");
                } else if (response["status"] === 401 && response["errorId"] === 0) {
                    //updateModal(true, "Falsche Logindaten", response["message"], "", "Nochmal versuchen", "", true, false, false, false);
                    setLoginRequestError("Falsche Logindaten, Bitte überprüfe deine Zugangsdaten und versuche es erneut!");

                } else if (response["status"] === 401 && response["errorId"] === 1) {
                    //updateModal(true, "Falsche Logindaten", response["message"], "Email wiederschicken", "Abbrechen", "api/resend_confirmation_email/" + response["user_id"], false, false, false, false);//erledigt
                    setLoginRequestError("Falsche Logindaten, Bitte überprüfe deine Zugangsdaten und versuche es erneut!");
                    setIsEmailVerificationModalOpened(true);
                }
            })
            .catch((err) => {
                //updateModal(true, "Fehler", err.toString(), "btnTitle", "() => {console.log(\"myEvent\");}");
                //alert(err);
                setLoginRequestError("Fehler bei der Anmeldung. Bitte versuche es zu einem späteren Zeitpunkt erneut.");
            });
    }
    function resendVerificationMail(){
        let data = {email : values.emailPhone, password: values.userpass}
        RestAPI("/api/resend_confirmation_email", "POST", data)
            .then((response) => {
                if (response["status"] === 200) {
                    setIsEmailVerificationModalOpened(false);
                }
                else {
                    setLoginRequestError("Falsche Logindaten.");
                }
            })
            .catch((err) => {
                setLoginRequestError("Fehler bei der Anmeldung. Bitte versuchen Sie es zu einem späteren Zeitpunkt");
            });
    }

    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}></Navbar>
            <div className="info_box">
                <div className="login_content">
                    <div className="login_content_left">
                        <div className="login_content_left_items_top">
                            <div style={(screenWidth.matches || MscreenWidth.matches) ? {display:"none"}:{}}>
                                <h2 style={{color:"white"}} className="untertitel_h2">Willkommen</h2>
                            </div>

                            <div style={(screenWidth.matches || MscreenWidth.matches) ? {display:"none"}:{flex:"1"}}></div>
                            <div>
                                <p style={{color:"white"}} className="fliesstext_p">Du hast noch kein Konto?</p>
                            </div>
                        </div>
                        <div className="login_content_left_items_down">
                            <div className="login_content_left_items_component">
                                <button style={{"--btn_font_color":"#666"}} id="loginbtn" type="button" onClick={redirect} className="ueberschrift_btn">Registrieren</button>
                            </div>

                        </div>

                    </div>
                    <div className="login_content_right">
                        <div className="login_content_right_frame">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Login</h3>
                            </div>
                            <div className="login_content_right_item">
                                <input id="emailPhone" name="emailPhone" type="text" className="text_input" placeholder="E-Mail-Adresse" required value={values.emailPhone} onChange={handleChange}/>
                                {errors.emailPhone &&
                                <span className="error_span">{errors.emailPhone}</span>
                                }
                            </div>
                            <div className="login_content_right_item">
                                <input id="userpass" name="userpass" type="password" className="text_input" placeholder="Passwort" required value={values.userpass} onChange={handleChange} onKeyUp={handleKeyDown}/>
                                {errors.userpass &&
                                <span className="error_span">{errors.userpass}</span>
                                }
                            </div>
                            <div style={(screenWidth.matches || MscreenWidth.matches) ? {display:"flex"} : {flex:"1"}}></div>
                            <div className="login_content_right_item login_content_right_item_btn_section">

                                <div className="login_content_right_item_btn_section_left">
                                    <span className="my_span" onClick={handleForgotPass}>Passwort vergessen?</span>
                                </div>
                                <div className="login_content_right_item_btn_section_right">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={redirect} className="ueberschrift_btn" id="loginbtn" type="button" onClick={handleFormSubmit}>Einloggen</button>
                                </div>

                            </div>


                            {loginRequestError &&
                                <div className="login_content_right_item">
                                    <span className={"error_span"}>{loginRequestError}</span>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        (screenWidth.matches || MscreenWidth.matches) ?
                        <div style={{backgroundColor:"transparent", marginBottom:"5px"}} className="login_content_right">
                            <h2 className="untertitel_h2">Willkommen</h2>
                        </div>
                            :
                        null
                    }
                </div>
            </div>
            <Footer></Footer>
            { /* ↓ Pop-Up Window for phone number verification ↓ */}
            <Modal isOpened={isEmailVerificationModalOpened} onClose={() => setIsEmailVerificationModalOpened(false)}>
                {
                    <div className="modal_group">
                        <div className="modal_group_item">
                            <h2 className="global_style_h2_light_text_gray">Du musst deine E-Mail verifizieren</h2>
                        </div>
                        <div className="modal_group_item">
                            <h2 className="global_style_h2_light_text_gray">Verifizierungsmail wieder schicken</h2>
                        </div>
                        <div className="btn_section">
                            <button id="loginbtn" type="button" onClick={() => resendVerificationMail()} className="submit_btn">Bestätigen</button>
                        </div>
                        {loginRequestError &&
                            <div className="modal_group_item">
                                <span_login>{loginRequestError}</span_login>
                            </div>
                        }
                    </div>
                }
            </Modal>
            { /* ↑ Pop-Up Window for phone number verification ↑ */}
        </main>
    )
}

export default Enter_login_data;
