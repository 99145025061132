import React, {useEffect, useState} from 'react';
import './data_protection_policy.css';
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";


function Data_protection_policy() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect( () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    return (
        <div className="flexSequence">
            <main className="main">
                <Navbar admin={isAdminVar} logged={isLoggedInVar}></Navbar>
                <div className="data_protection_policy_content">
                    <div><h1><a name="_TOC2064142556">Datenschutzerklärung zur Nutzung des „Kiez-Lockers“ und der damit verbundenen Webseite www.BSR.de/kiez-locker</a></h1>
                        <div><p></p>

                            <h2>Informationen zur Datenerhebung gemäß Artikel 13 DS-GVO</h2>

                            <p></p>

                            <p>Im Rahmen der Nutzung dieser Webseite werden personenbezogene Daten von Ihnen durch uns als den für die Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie darüber, um welche Daten es sich dabei handelt, auf welche Weise sie verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen.</p>

                            <p>Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung (DSGVO) alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</p>
                        </div>
                    </div>
                    <div><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">1 Geltungsbereich</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">2 Verantwortliche Stelle und Datenschutzbeauftragte</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">4 Weitergabe von Daten</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">5 Cookies</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">6 Betroffenenrechte</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">7 Datensicherheit</a></p><p><a
                        href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/sq41.ipk.fraunhofer.de/full">8 Aktualität und Änderung dieser Datenschutzinformation</a></p></div>
                    <div><h2><a name="_TOC1101520339">1 Geltungsbereich</a></h2>
                        <div><p>Diese Datenschutzinformation gilt für Datenverarbeitungen auf der Webseite des „Kiez-Lockers“ (www.BSR.de/kiez-locker), sowie aller im Zusammenhang mit dem Projekt angebotenen Dienste als Webdienst oder als mobile App (zur besseren Lesbarkeit im Folgenden: Webseite), sofern jeweils auf diese Datenschutzinformation verwiesen wird.</p>
                        </div>
                    </div>
                    <div><h2><a name="_TOC581809998">2 Verantwortliche Stelle und Datenschutzbeauftragte</a></h2>
                        <div><p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>

                            <p>Berliner Stadtreinigungsbetriebe, Anstalt des öffentlichen Rechts (BSR)<br/>
                                Ringbahnstr. 96<br/>
                                12103 Berlin<br/>
                                Tel.: 030 7592-4900<br/>
                                E-Mail: service@bsr.de<br/>
                                <a href="www.bsr.de">Website: www.bsr.de</a><br/>

                            </p>

                            <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
                                <p>Die betrieblichen Datenschutzbeauftragten der BSR sind unter der E-Mail-Adresse Datenschutz@BSR.de erreichbar.</p>
                            </p>
                        </div>
                    </div>

                    <div>
                        <h2><a name="_TOC319907938">3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung</a></h2>
                        <div></div>
                    </div>
                    <div>
                        <h3>Beim Besuch der Webseite</h3>
                        <div>
                            <p>Sie können unsere Webseite aufrufen, ohne Angaben zu Ihrer Identität preisgeben zu müssen. Der auf Ihrem Endgerät eingesetzte Browser sendet lediglich automatisch Informationen an den Server der Webseite (z.B. Browsertyp und –version, Datum und Uhrzeit des Zugriffs), um einen Verbindungsaufbau der Webseite zu ermöglichen. Hierzu gehört auch die IP-Adresse Ihres anfragenden Endgerätes. Diese wird temporär in einem sog. Logfile gespeichert und spätestens nach 4 Wochen gelöscht.
                                <br/>
                            </p>

                            <p></p>

                            <p>Die Speicherung der IP-Adresse erfolgt anonymisiert, indem der letzte Zeichenblock entfernt wird.</p>

                            <p></p>

                            <p>Aus der Verarbeitung der IP-Adresse und anderer Information in dem Logfile können wir keine unmittelbaren Rückschlüsse auf Ihre Identität ziehen.</p>

                            <p>Darüber hinaus setzen wir beim Besuch unserer Website Cookies ein. Nähere Erläuterungen dazu erhalten Sie weiter unten in dieser Datenschutzinformation.</p>
                        </div>
                    </div>
                    <div>
                        <h3>Bei Anlegen eines Benutzerkontos</h3>
                        <div>
                            <p>Auf der Webseite besteht die Möglichkeit ein passwortgeschütztes Benutzerkonto anzulegen.</p>

                            <p>Für die Einrichtung des Benutzerkontos benötigen wir von Ihnen folgende Informationen:</p>
                                <li> Vor- und Nachname,</li>
                                <li> eine gültige E-Mail-Adresse sowie</li>
                                <li> eine gültige deutsche Mobilfunk-Telefonnummer.</li>
                            <p></p>
                            <p>Die Verarbeitung dieser Daten erfolgt, um Sie als Nutzer:in identifizieren und Ihr Nutzer:innenkonto anlegen zu können.</p>

                            <p>
                                Zusätzlich müssen Sie für die Einrichtung des Nutzer:innenkonto ein selbstgewählten Nutzer:innennamen und ein selbstgewähltes Passwort angeben. Zusammen mit Ihrer E-Mail-Adresse ermöglicht dies den Zugang zu Ihrem Nutzer:innenkonto. In Ihrem Nutzer:innenkonto können Sie die zu Ihnen gespeicherten Daten jederzeit einsehen,ändern und Ihr Konto vollständig löschen.
                            </p><br/>


                            <p>Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b DSGVO zu den genannten Zwecken für die Einrichtung Ihres Nutzer:innenkontos und dessen Nutzung sowie den vorvertraglichen Maßnahmen hierfür erforderlich. Ihre E-Mail-Adresse verwenden wir zudem für Benachrichtigungsfunktionen.</p>
                                 <br/>
                            <p>
                             Mit Fraunhofer IPK wurde ein Auftragsverarbeitungsvertrag abgeschlossen. Durch diesen Vertrag versichert Fraunhofer IPK, dass sie die Daten im Auftrag der BSR im Einklang mit der Datenschutzgrundverordnung verarbeiten und den Schutz der Rechte der betroffenen Personen gewährleisten. Die Datenverarbeitung erfolgt auf Ihre Anfrage hin. Alle zur Verfügung gestellten Nutzer:inneninformationen werden Fraunhofer IPK zur Verfügung gestellt, da diese Betreiber der Server und Software für den Betrieb des Kiezlockers sind..
                            </p>
                                    <br/>
                            <p>
                               Fraunhofer IPK hat mit Infobip ltd. ein Auftragsverarbeitungsvertrag abgeschlossen. Durch diesen Vertrag versichert Infobip, dass sie die Daten im Auftrag von Fraunhofer IPK im Einklang mit der Datenschutzgrundverordnung verarbeiten und den Schutz der Rechte der betroffenen Personen gewährleisten. Die Datenverarbeitung erfolgt auf Ihre Anfrage hin. Die zur Verfügung gestellte Telefonnummer dient hierbei der Verifizierung des Nutzenden durch eine Prüfung der hinterlegten Telefonnummer durch Infobip.
                            </p>

                        <p>
                            Nach Löschung Ihres Nutzer:innenkontos werden Ihre Daten für die weitere Verwendung gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (insbesondere § 147 AO sofern steuerlich relevant) zu einer längeren Speicherung verpflichtet sind oder Sie in eine darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
                        </p>

                        <p>
                            Mit den Berliner Stadtreinigungsbetrieben AöR (kurz: BSR) wurde ein Auftragsverarbeitungsvertrag abgeschlossen. Durch diesen Vertrag versichert
                            die BSR, dass sie die Daten im Auftrag von Fraunhofer IPK im Einklang mit der Datenschutzgrundverordnung verarbeiten und den Schutz der Rechte
                            der betroffenen Personen gewährleisten. Die Datenverarbeitung erfolgt auf Ihre Anfrage hin. Alle zur Verfügung gestellten Nutzendeninformationen
                            werden der BSR zur Verfügung gestellt, da diese der Betreiber des Paketterminals sind und das User-Management übernehmen.
                        </p>

                        <p>
                            Nach Löschung Ihres Benutzerkontos werden Ihre Daten für die weitere Verwendung gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO
                            aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (insbesondere § 147 AO sofern steuerlich relevant) zu einer
                            längeren Speicherung verpflichtet sind oder Sie in eine darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
                        </p>
                    </div></div>
                    <div><h2><a name="_TOC2000865106">4 Weitergabe von Daten</a></h2>
                        <div><p>Sofern wir personenbezogene Daten, die wir über die Webseite erheben, an Auftragsverarbeiter weitergeben, informieren wir Sie darüber in dieser Datenschutzinfomation bei dem jeweiligen Datenverarbeitungsvorgang unter Nennung des konkreten Empfängers.</p>

                            <p>Im Übrigen geben wir Ihre personenbezogenen Daten nur weiter, wenn</p>

                                <li>
                                    Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche Einwilligung dazu erteilt haben;
                                </li>
                                <p></p>
                                <li>
                                    dies gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines Vertrages mit Ihnen erforderlich ist (zum Beispiel bei Weitergabe an Versandunternehmen zum Zwecke der Lieferung der von Ihnen bestellten Ware oder bei Weitergabe von Zahlungsdaten an Zahlungsdienstleister oder Kreditinstitute, um einen Zahlungsvorgang durchzuführen);
                                </li>
                                <p></p>
                                <li>
                                    für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht.
                                </li>
                            <p></p>
                            <p>Die weitergegebenen Daten dürfen von den Empfängern ausschließlich zu den genannten Zwecken verwendet werden.</p>
                        </div>
                    </div>

                    <div><h2><a name="_TOC1358618279">5 Cookies</a></h2>
                        <div>
                            <p>
                                Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
                            </p>

                            <p>
                                In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
                            </p>

                            <p>
                                Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir z.B. sogenannte Session-Cookies ein, um eine Sitzungssteuerung zu ermöglichen oder um Formulareingaben oder Warenkörbe während der Sitzung zu speichern. Session-Cookies werden spätestens mit dem Schließen Ihres Webbrowsers gelöscht.
                            </p>

                            <p>
                                Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für 4 Wochen auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
                            </p>

                            <p>
                                Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
                            </p>

                            <p>
                                Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.
                            </p>
                        </div>
                    </div>

                    <div><h2><a name="_TOC1029964882">6 Betroffenenrechte</a></h2>
                        <div><p>Sie haben das Recht:</p>

                            <p>
                                <li> gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;</li>
                                <li> gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
                                <li> gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
                                <li> gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
                                <li> gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                                <li> gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und</li>
                                <li> gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li>
                            </p>

                            <div style={{border:"solid"}} >
                                <div style={{padding: "0.5em"}} >
                                    <p><strong>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</strong></p>

                                    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen.</p>

                                    <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                                    <p>Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an kiez-locker@BSR.de.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><h2><a name="_TOC1794533937">7 Datensicherheit</a></h2>
                        <div><p>Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein üblichen und sicheren Standard TLS (Transport Layer Security) verschlüsselt übertragen. TLS ist ein sicherer und erprobter Standard, der z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen eine sichere TLS-Verbindung unter anderem an dem angehängten s am http (also https://..) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.</p>

                            <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
                        </div>
                    </div>
                    <div><h2><a name="_TOC83839327">8 Aktualität und Änderung dieser Datenschutzinformation</a></h2>
                        <div><p>notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils aktuelle Datenschutzinformation kann jederzeit auf der Webseite von Ihnen abgerufen und ausgedruckt werden.</p>
                         <br/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </main>

        </div>
    )
}

export default Data_protection_policy;
