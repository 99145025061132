import React, {useEffect, useRef, useState} from 'react';
import './add_post.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import 'react-dropdown/style.css';
import RestApi from "../../RestAPI";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {isAdmin, isLogin} from "../login";
import {useParams} from "react-router-dom";

import img_place_holder from "../../static/market/Bildplatzhalter_grau.svg"
import Modal from "../modal";
import {isTesting, tinyScreenWidth} from "../../App";

function Post_details({switchToIndex}) {

    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    let {q} = useParams();
    const id = q;
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    const [isMyPost, setIsMyPost] = useState(false)
    const [isActivated, setIsActivated] = useState(false)
    const [currImg, setCurrImg] = useState(0);
    const [images, setImages] = useState([])
    const [postCategory, setPostCategory] = useState("");
    const [category, setCategory] = useState("");
    const [district, setDistrict] = useState("");
    const [checked, setChecked] = React.useState("");
    const [uploadingDate, setUploadingDate] = useState("")
    const [userName, setUserName] = useState("")
    const [activationModal, setActivationModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [values, setValues] = useState({
        title: "",
        description: "",
        message : ""
    })


    function handleActivateButton(){
        setActivationModal(true);
    }
    function handleDeleteButton(){
        setDeleteModal(true);
    }
    function backToEditMode() {
        switchToIndex("Add_post");
    }

    const redirect_to_market = () => {
        window.location.href = "/market";
    }
    const redirect_to_login = () => {
        window.location.href = "/login";
    }

    useEffect(() => {
        getPostDetails()
    }, [])

    function getImage(imgNr) {
        RestApi("/api/get_image/" + id + "/" + imgNr, "GET", {}).then(response => response.blob())
            .then(images => {
                setImages(oldArray => [...oldArray, URL.createObjectURL(images)]);

            }).catch(error => {
            console.error(error);
        });
    }

    function activatePost() {
        let postData = {postId : id.toString()}
        RestApi("/api/activate_post", "POST", postData)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    window.location.href = "/admin"
                } else {

                }
            })
            .catch((err) => {
            });
    }

    function deactivatePost() {
        let postData = {postId : id.toString()}
        RestApi("/api/deactivate_post", "POST", postData)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    window.location.href = "/admin"
                } else {

                }
            })
            .catch((err) => {
            });
    }

    const handleInput = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    };

    function handleSendButtonClick(){
        if (values.message.length !== 0 && /[a-zA-Z]/g.test(values.message)){
            startConversation();
        }
        else {
            setMessageModal(true)
        }
    }

    function startConversation() {
        let data = {postId : id.toString(), messageBody : values.message}
        RestApi("/api/start_conversation", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    window.location.href = "/chat"
                } else {

                }
            })
            .catch((err) => {
            });
    }

    function getPostDetails() {
        RestApi("/api/get_post_details/" + id, "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setIsMyPost(response["isMyPost"] !== 0)
                    setCategory(response["category"])
                    setDistrict(response["district"])
                    setUploadingDate(response["uploading_date"])
                    setPostCategory(response["action_category"])
                    setValues({
                        ...values,
                        title: response["title"],
                        description: response["description"]
                    })
                    setIsActivated(response["isActivated"] === 1)
                    setUserName(response["username"])
                    getUploadedPicturesNumber(JSON.parse(response["images"].replace(/'/g, '"')))
                } else {
                    //TODO
                    window.location.href = "/market"
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function getUploadedPicturesNumber(imageNames) {
        let i = 0
        for (const key in imageNames) {
            if (imageNames[key] !== "") {
                getImage(i)
                i = i + 1
            }
        }
    }

    const redirect_to_edit_post = () => {
        window.location.href = "/edit_post/" + id;
    }

    const redirect_to_admin = () => {
        window.location.href = "/release_posts";
    }

    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="add_post_screen">
                <div className="details_page_content">
                    <div style={{padding: "0px"}} className="details_page_content_item">
                        <div className="carousel">
                            <div className="carouselInner" style={{backgroundImage: `url(${images[currImg]})`, backgroundSize: "contain"}}>
                                <div className={currImg === 0 ? "left_last" : "left"} onClick={() => {
                                    currImg > 0 && setCurrImg(currImg - 1);
                                }}>
                                    {currImg === 0 ? null : <ArrowBackIosIcon style={{fontSize: 30}}/>}
                                </div>
                                <div className="center">
                                </div>
                                <div className={currImg === images.length - 1 ? "right_last" : "right"} onClick={() => {
                                    currImg < images.length - 1 && setCurrImg(currImg + 1);
                                }}>
                                    {currImg === images.length - 1 ? null :
                                        <ArrowForwardIosIcon style={{fontSize: 30}}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details_page_content_item details_page_content_item_white_bg">
                        <div
                            className="details_page_content_item_post_details details_page_content_item_post_details_date_district">
                            <p className={"tootip_content_p_gray"}>{uploadingDate}</p>
                            <p className={"tootip_content_p_gray"}>{category}</p>
                            <p className={"tootip_content_p_gray"}>{district}</p>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <p className={"fliesstext_p"} style={{color: "#f61"}}>{
                                postCategory === "V" ? "Weitergeben"
                                    :
                                    "Suchen"}</p>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <h4 className={"teaser_h4"} style={{color: "#000"}}>{values.title}</h4>
                        </div>
                        <div className="details_page_content_item_post_details">
                            <p className={"fliesstext_p"} style={{color: "#a0a0a0"}}>{"Beschreibung"}</p>
                            <p className={"fliesstext_p"}>{values.description}</p>
                        </div>
                        {
                            !isMyPost && isLoggedInVar && !isAdminVar &&
                            <div className="details_page_content_item_post_details">
                                <p className={"fliesstext_p"} style={{color: "#a0a0a0"}}>{"Anbieter kontaktieren"}</p>
                            </div>
                        }
                        {
                            !isMyPost && isLoggedInVar &&
                            <div className="details_page_content_item_post_details contact_details">
                                <div className="owner_icon">
                                    <p style={{color: "#fff"}} className={"fliesstext_p"}>M</p>
                                </div>
                                <p className={"fliesstext_p"}>{userName}</p>
                            </div>
                        }
                        {
                            !isMyPost && isLoggedInVar &&
                            <div
                                className="details_page_content_item_post_details details_page_content_item_post_details_message">
                                <p className={"fliesstext_p"}>Nachricht</p>
                                <div>
                                    <textarea name={"message"} defaultValue={values.message} onChange={handleInput}
                                        maxLength={5000}></textarea>
                                </div>
                            </div>
                        }


                        <div style={{flex: "1"}}></div>
                        {
                            isActivated  &&
                            <div className="details_page_content_item_post_details btn_placement">
                                <button style={{"--btn_font_color": "#ffffff","--btn_background_color": "#f61"}} onClick={!isLoggedInVar ? redirect_to_login : !isMyPost ? handleSendButtonClick : redirect_to_edit_post}
                                        className="ueberschrift_btn">{!isLoggedInVar ? "Zum Login" : !isMyPost ? "Senden" : "Bearbeiten"}</button>
                            </div>
                        }

                        {
                            isAdminVar && !isActivated ?
                                <div className="details_page_content_item_post_details btn_placement_tow">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#a0a0a0", margin: "0 0", padding: "10px 30px 10px 30px"}} onClick={redirect_to_admin} className="ueberschrift_btn">{"Zurück"}</button>
                                    <button style={tinyScreenWidth.matches ? {fontSize:"14px",width:"auto", whiteSpace: "nowrap", padding:"10px 5px", "--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c"} : {"--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c"}} onClick={handleDeleteButton} className="ueberschrift_btn">{"Nicht freigeben"}</button>
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61", margin: "0 0", padding: "10px 30px 10px 30px"}} onClick={handleActivateButton} className="ueberschrift_btn">{"Freigeben"}</button>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>

            <Footer/>
            <Modal isOpened={activationModal} onClose={() => setActivationModal(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Willst Du das Inserat freigeben?</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"space-between", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" onClick={() =>setActivationModal(false)} className="ueberschrift_btn">Abbrechen</button>
                                </div>
                                <div className="btn_section">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}} id="loginbtn" type="button" onClick={() => activatePost()} className="ueberschrift_btn">Ja</button>
                                </div>
                            </div>
                        </div>
            </Modal>

            <Modal isOpened={deleteModal} onClose={() => setDeleteModal(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Das Inserat nicht freigeben?</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"space-between", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" onClick={() =>setDeleteModal(false)} className="ueberschrift_btn">Abbrechen</button>
                                </div>
                                <div className="btn_section">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}} id="loginbtn" type="button" onClick={() => deactivatePost()} className="ueberschrift_btn">Nicht freigeben</button>
                                </div>
                            </div>
                        </div>
            </Modal>
            <Modal isOpened={messageModal} onClose={() => setMessageModal(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Eine leere Nachricht kann nicht geschickt werden!</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"flex-end", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={() => setMessageModal(false)} className="ueberschrift_btn">OK</button>
                                </div>
                            </div>
                        </div>
            </Modal>
        </main>
    )
}

export default Post_details;