import React, {useEffect, useRef, useState} from 'react';
import './add_post.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import download_img_icon from "../../static/market/Bild hinzufügen.svg"
import x_icon from "../../static/market/X im Kreis.svg"
import 'react-dropdown/style.css';
import Dropdown from "react-dropdown";
import RestApi from "../../RestAPI";
import validation from "./validation";
import {isTesting, MY_URL} from "../../App";
import Modal from "../modal";
import {useParams} from "react-router-dom";
import Loading_spinner from "../common/loading_spinner";
import edit_icon from "../../static/market/Bild bearbeiten.svg";

let imgNum = 0;

function Edit_post({switchToIndex, isAdminVar, isLoggedInVar}) {

    const [isDeletePostModalOpened, setIsDeletePostModalOpened] = useState(false);
    const [isUpdatePostModalOpened, setIsUpdatePostModalOpened] = useState(false);
    const [isPostDeletedModalOpened, setIsPostDeletedModalOpened] = useState(false);
    const data = new FormData();
    const [previewMode, setPreviewMode] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handlePreview = (event) => {
        event.preventDefault();
        setErrors(validation(values, checked, "Post_preview"));
        setDataIsCorrect(true);
    }
    let postInfos = {};

    useEffect(() => {
            if (Object.keys(errors).length === 0 && dataIsCorrect) {

            }


        }
        , [errors]);

    let {q} = useParams();
    const id = q;
    const RubrikOptions = [
        'Rubrik (Alle)', 'Möbel/Hausrat', 'Haushaltsgeräte', 'Computer/HiFi', 'Telefon/Fax', 'Bücher', 'Hobby',
        'Für Kinder', 'Kleidung/Schuhe', 'Garten/Bauen', 'Materialreste', 'Sonstiges'
    ];
    const BezirkOptions = [
        'Bezirk (Alle)', 'Charlottenburg', 'Friedrichshain', 'Hellersdorf', 'Köpenick', 'Kreuzberg', 'Lichtenberg',
        'Marzahn', 'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Schöneberg', 'Spandau', 'Stieglitz', 'Tempelhof',
        'Treptow', 'Wilmersdorf', 'Zellendorf'
    ];
    const [images, setImages] = useState([])
    const rubrikDefaultOption = RubrikOptions[0];
    const bezirkDefaultOption = BezirkOptions[0];
    const inputFile1 = useRef(null)
    const inputFile2 = useRef(null)
    const inputFile3 = useRef(null)
    const inputFile4 = useRef(null)
    const [fileData1, setFileData1] = useState(null);
    const [fileData2, setFileData2] = useState(null);
    const [fileData3, setFileData3] = useState(null);
    const [fileData4, setFileData4] = useState(null);
    const [uploadingDate, setUploadingDate] = useState("")
    const [postCategory, setPostCategory] = useState("");
    const [category, setCategory] = useState("");
    const [district, setDistrict] = useState("");
    const [checked, setChecked] = React.useState();
    const [values, setValues] = useState({
        title: "",
        description: ""
    })
     const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);

    function handleSaveButton() {
        setIsUpdatePostModalOpened(true)
    }

    const onClickUpload1 = () => {
        inputFile1.current.click();
    };
    const onClickdelete1 = () => {
        setFileData1(null);
        images.splice(0);
    };
    const onClickUpload2 = () => {
        inputFile2.current.click();
    };
    const onClickdelete2 = () => {
        setFileData2(null);
        images.splice(1);
    };
    const onClickUpload3 = () => {
        inputFile3.current.click();
    };
    const onClickdelete3 = () => {
        setFileData3(null);
        images.splice(2);
    };
    const onClickUpload4 = () => {
        inputFile4.current.click();
    };
    const onClickdelete4 = () => {
        setFileData4(null);
        images.splice(3);
    };

    const handleCheckBox = (event) => {
        setPostCategory(event.target.value)


        setChecked(true);
    };
    const handleInput = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    };

    function deletePost() {
        let data = {post_id: id}
        setRestQueryIsLoading(true);
        RestApi("/api/delete_post", "POST", data).then((resp) => {
            return resp.json()
        })
            .then((response) => {
                if (response["status"] === 200) {
                    window.location.href = "/profile"
                } else {

                }
            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        checkPostOwner()
    }, [])

    const redirect_to_profile = () => {
        window.location.href = "/profile";
    }

    const redirect_to_post_details = () => {
        window.location.href = `/post_details/` + id;

    }

    function getUploadedPicturesNumber(imageNames) {
        let i = 0
        for (const key in imageNames) {
            if (imageNames[key] !== "") {
                getImage(i)
                i = i + 1
            }
        }
    }

    function getImage(imgNr) {
        RestApi("/api/get_image/" + id + "/" + imgNr, "GET", {}).then(response => response.blob())
            .then(images => {
                switch (imgNr) {
                    case 0:
                        setFileData1(new File([images], "image1.png"))
                        break;
                    case 1:
                        setFileData2(new File([images], "image2.png"))
                        break;
                    case 2:
                        setFileData3(new File([images], "image3.png"))
                        break;
                    case 3:
                        setFileData4(new File([images], "image4.png"))

                }


            }).catch(error => {
            console.error(error);
        });
    }

    function handleUploadImage() {
        setRestQueryIsLoading(true);
        const data = new FormData();
        let infos = {
            "postId": id,
            "postCategory": postCategory === "Search" ? "S" : "V",
            "checked": checked,
            "category": category,
            "district": district,
            "title": values.title,
            "description": values.description,
            "imageNames": {
                "image1": fileData1 ? "0"+fileData1.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image2": fileData2 ? "1"+fileData2.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image3": fileData3 ? "2"+fileData3.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image4": fileData4 ? "3"+fileData4.name.toString().replace(/[^a-zA-Z0-9]/g, '') : ""
            }
        }
        data.append('files', fileData1);
        data.append('files', fileData2);
        data.append('files', fileData3);
        data.append('files', fileData4);
        data.append('data', JSON.stringify(infos));
        RestApi("/api/edit_post", 'POST', data, true
        ).then((response) => {
            response.json().then(() => {
                if (response["status"] === 200) {
                    setRestQueryIsLoading(false);
                    window.location.href = "/post_details/" + id
                } else {
                    //TODO
                }
            });
        });
    }

    function checkPostOwner(){
        let data = {post_id: id}
        setRestQueryIsLoading(true);
        RestApi("/api/check_post_owner", "POST", data).then((resp) => {
            return resp.json()
        })
            .then((response) => {
                if (response["status"] === 200) {
                    getPostDetails();
                } else {
                    window.location.href = "/start_seite";
                }
            })
            .catch((err) => {
            });
    }

    function getPostDetails() {
        setRestQueryIsLoading(true);
        RestApi("/api/get_post_details/" + id, "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setRestQueryIsLoading(false);
                    setCategory(response["category"])
                    setDistrict(response["district"])
                    setUploadingDate(response["uploading_date"])
                    setPostCategory(response["action_category"] === "S" ? "Search" : "Give")
                    setValues({
                        ...values,
                        title: response["title"],
                        description: response["description"]
                    })
                    getUploadedPicturesNumber(JSON.parse(response["images"].replace(/'/g, '"')))
                } else {
                    //TODO

                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="add_post_screen">
                <div className="add_post_content">
                    <div className="add_post_content_item add_post_content_item_header">
                        <h2 className="untertitel_h2">Kiez-Locker Marktplatz Tempelhof</h2>
                        <h4 className="teaser_h4">{previewMode ? "Dein Inserat im Vorschau" : "Inserat bearbeiten"}</h4>
                    </div>
                    <div className="add_post_content_item">
                        <p style={{paddingBottom: "15px"}}
                           className="fliesstext_p">{previewMode ? "Bilder" : "Bilder auswählen"}</p>
                        <div className="download_img_field">
                            <div className="download_img_field_scope">
                                {
                                    previewMode && !fileData1 && !fileData2 && !fileData3 && !fileData4 ?
                                        <div className="download_img_field_scope_item">
                                            <p className={"fliesstext_p"}>Keine Bilder</p>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    previewMode && fileData1 ?
                                        <div className="download_img_field_scope_item">
                                            <img src={URL.createObjectURL(fileData1)}/>
                                        </div>
                                        :
                                        !previewMode ?
                                            <div className="download_img_field_scope_item">
                                                <img id="uploadedImage1" onClick={onClickUpload1}
                                                     src={fileData1 ? URL.createObjectURL(fileData1) : download_img_icon}/>
                                                <input type="file" id="file" onChange={(event) => {
                                                    setFileData1(event.target.files[0]);
                                                    images[0] = URL.createObjectURL(event.target.files[0]);
                                                    document.getElementById("uploadedImage1").src = URL.createObjectURL(event.target.files[0])
                                                }}
                                                       accept="image/png, image/jpeg" ref={inputFile1}
                                                       style={{display: "none"}}/>
                                                {
                                                    fileData1 ?
                                                        <div className={"download_img_field_scope_item_overlap"}>
                                                        <img id="uploadedImage1" style={{width:"37px"}}
                                                             onClick={onClickdelete1} src={x_icon}/>
                                                        <img style={{width:"37px"}}
                                                             onClick={onClickUpload1} src={edit_icon}/>
                                                    </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null

                                }
                                {
                                    previewMode && fileData2 ?
                                        <div className="download_img_field_scope_item">
                                            <img src={URL.createObjectURL(fileData2)}/>
                                        </div>
                                        :
                                        !previewMode ?
                                            <div className="download_img_field_scope_item">
                                                <img id="uploadedImage2" onClick={onClickUpload2}
                                                     src={fileData2 ? URL.createObjectURL(fileData2) : download_img_icon}/>
                                                <input type="file" id="file" onChange={(event) => {
                                                    setFileData2(event.target.files[0]);
                                                    images[1] = URL.createObjectURL(event.target.files[0]);
                                                    document.getElementById("uploadedImage2").src = URL.createObjectURL(event.target.files[0])
                                                }}
                                                       accept="image/png, image/jpeg" ref={inputFile2}
                                                       style={{display: "none"}}/>
                                                {
                                                    fileData2 ?
                                                        <div className={"download_img_field_scope_item_overlap"}>
                                                        <img id="uploadedImage2" style={{width:"37px"}}
                                                             onClick={onClickdelete2} src={x_icon}/>
                                                        <img style={{width:"37px"}}
                                                             onClick={onClickUpload2} src={edit_icon}/>
                                                    </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null
                                }
                                {
                                    previewMode && fileData3 ?
                                        <div className="download_img_field_scope_item">
                                            <img src={URL.createObjectURL(fileData3)}/>
                                        </div>
                                        :
                                        !previewMode ?
                                            <div className="download_img_field_scope_item">
                                                <img id="uploadedImage3" onClick={onClickUpload3}
                                                     src={fileData3 ? URL.createObjectURL(fileData3) : download_img_icon}/>
                                                <input type="file" id="file" onChange={(event) => {
                                                    setFileData3(event.target.files[0]);
                                                    images[2] = URL.createObjectURL(event.target.files[0]);
                                                    document.getElementById("uploadedImage3").src = URL.createObjectURL(event.target.files[0])
                                                }}
                                                       accept="image/png, image/jpeg" ref={inputFile3}
                                                       style={{display: "none"}}/>
                                                {
                                                    fileData3 ?
                                                        <div className={"download_img_field_scope_item_overlap"}>
                                                        <img id="uploadedImage3" style={{width:"37px"}}
                                                             onClick={onClickdelete3} src={x_icon}/>
                                                        <img style={{width:"37px"}}
                                                             onClick={onClickUpload3} src={edit_icon}/>
                                                    </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null
                                }
                                {
                                    previewMode && fileData4 ?
                                        <div className="download_img_field_scope_item">
                                            <img src={URL.createObjectURL(fileData4)}/>
                                        </div>
                                        :
                                        !previewMode ?
                                            <div className="download_img_field_scope_item">
                                                <img id="uploadedImage4" onClick={onClickUpload4}
                                                     src={fileData4 ? URL.createObjectURL(fileData4) : download_img_icon}/>
                                                <input type="file" id="file" onChange={(event) => {
                                                    setFileData4(event.target.files[0]);
                                                    images[3] = URL.createObjectURL(event.target.files[0]);
                                                    document.getElementById("uploadedImage4").src = URL.createObjectURL(event.target.files[0])
                                                }}
                                                       accept="image/png, image/jpeg" ref={inputFile4}
                                                       style={{display: "none"}}/>
                                                {
                                                    fileData4 ?
                                                        <div className={"download_img_field_scope_item_overlap"}>
                                                        <img id="uploadedImage4" style={{width:"37px"}}
                                                             onClick={onClickdelete4} src={x_icon}/>
                                                        <img style={{width:"37px"}}
                                                             onClick={onClickUpload4} src={edit_icon}/>
                                                    </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="add_post_content_item">
                        <p style={{paddingBottom: "15px"}}
                           className="fliesstext_p">{previewMode ? "Beitragsdaten" : "Daten eingeben"}</p>
                        <div className="insert_data_field">
                            <div className="insert_data_field_scope">
                                {
                                    previewMode ?
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Kategorie</p>
                                            <p style={{paddingBottom: "15px"}} className="fliesstext_p">{
                                                postCategory === "Give" ? "Verschenken"
                                                    :
                                                    postCategory === "Swap" ? "Tauschen"
                                                        :
                                                        "Suchen"}
                                            </p>
                                        </div>
                                        :
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Kategorie</p>
                                            <div className="checkbox_group">
                                                <div className="checkbox_component">
                                                    <div className="insert_data_field_item_left">
                                                        <label>
                                                            <input id="toMe" name="toMe" value={"Give"}
                                                                   className="checkbox_input" type="radio" required
                                                                   onClick={handleCheckBox}
                                                                   checked={postCategory === "Give"}
                                                            />
                                                            <span className="checkbox">
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="insert_data_field_item_right">
                                                        <p style={{color: "#000000"}}
                                                           className="checkbox_text_p">Weitergeben</p>
                                                    </div>
                                                </div>

                                                <div className="checkbox_component">
                                                    <div className="insert_data_field_item_left">
                                                        <label>
                                                            <input id="toMe2" name="toMe" className="checkbox_input"
                                                                   value={"Search"} onClick={handleCheckBox}
                                                                   type="radio"
                                                                   required
                                                                   checked={postCategory === "Search"}/>
                                                            <span className="checkbox">
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="insert_data_field_item_right">
                                                        <h2 style={{color: "#000000"}}
                                                            className="checkbox_text_p">Suchen</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {errors.checked &&
                                                <span className="error_span">{errors.checked}</span>
                                            }
                                        </div>
                                }
                                {
                                    previewMode ?
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Rubrik</p>
                                            <p style={{paddingBottom: "15px"}} className="fliesstext_p">{category}</p>
                                        </div>
                                        :
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Rubrik</p>
                                            <Dropdown name="category" onChange={(e) => setCategory(e.value)}
                                                      className="market_dropdown"
                                                      controlClassName="add_post_dropdown"
                                                      arrowClassName="add_post_dropdown_arrow" options={RubrikOptions}
                                                      placeholder="Bitte wählen"
                                                      value={category ? RubrikOptions[RubrikOptions.indexOf(category)] : rubrikDefaultOption}/>
                                        </div>
                                }
                                {
                                    previewMode ?
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Bezirk</p>
                                            <p style={{paddingBottom: "15px"}} className="fliesstext_p">{district}</p>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    previewMode ?
                                        <div className="insert_data_field_item insert_data_field_item_titel_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Titel</p>
                                            <p style={{paddingBottom: "15px"}}
                                               className="fliesstext_p">{values.title}</p>
                                        </div>
                                        :
                                        <div className="insert_data_field_item insert_data_field_item_titel_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Titel</p>
                                            <input className="text_input" defaultValue={values.title} name="title"
                                                   maxLength={200} onChange={handleInput}/>
                                            {errors.title &&
                                                <span className="error_span">{errors.title}</span>
                                            }
                                        </div>
                                }
                                {
                                    previewMode ?
                                        <div
                                            className="insert_data_field_item insert_data_field_item_description_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Beschreibung</p>
                                            <p style={{paddingBottom: "15px"}}
                                               className="fliesstext_p">{values.title}</p>
                                        </div>
                                        :
                                        <div
                                            className="insert_data_field_item insert_data_field_item_description_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Beschreibung</p>
                                            <textarea name={"description"} defaultValue={values.description}
                                                      onChange={handleInput} maxLength={5000}></textarea>
                                            <div>
                                                <p>{values.description.length}/5000 Character</p>
                                            </div>
                                            {errors.description &&
                                                <span className="error_span">{errors.description}</span>
                                            }
                                        </div>


                                }

                                <div className="insert_data_field_item edit_post_btn_placement">
                                    {/*<button style={{
                                        "--btn_font_color": "#ffffff",
                                        "--btn_background_color": "#f61",
                                        maxWidth: "max-content",
                                        margin: "0 0"
                                    }} className="ueberschrift_btn"
                                             onClick={handlePreview}>{previewMode ? "Bearbeiten" : "Vorschau"}</button>*/}

                                    <button style={{
                                        "--btn_font_color": "#ffffff",
                                        "--btn_background_color": "#a0a0a0",
                                        margin: "0 0"
                                    }} onClick={() => redirect_to_post_details()} className="ueberschrift_btn">Abbrechen
                                    </button>
                                    <button style={{
                                        "--btn_font_color": "#bd451c",
                                        "--btn_background_color": "#ffffff",
                                        border: "2px solid #bd451c",
                                        margin: "0 0"
                                    }} onClick={() => setIsDeletePostModalOpened(true)}
                                            className="ueberschrift_btn">Löschen
                                    </button>
                                    <button style={{
                                        "--btn_font_color": "#ffffff", "--btn_background_color": "#f61", margin: "0 0"
                                    }} onClick={handleSaveButton} className="ueberschrift_btn">Speichern
                                    </button>


                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>

            <Modal isOpened={isUpdatePostModalOpened} onClose={() => setIsUpdatePostModalOpened(false)}>
                <div className="modal_group">
                    <div className="modal_group_item">
                        <h4 className="teaser_h4_bold">Willst Du deine Änderungen speichern?</h4>
                    </div>
                    <div style={{flex: "1"}}></div>
                    <div style={{justifyContent: "space-between", flexDirection: "row"}} className="modal_group_item">
                        <div className="btn_section">
                            <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#bd451c"}}
                                    id="loginbtn" type="button" onClick={() => setIsUpdatePostModalOpened(false)}
                                    className="ueberschrift_btn">Abbrechen
                            </button>
                        </div>
                        <div className="btn_section">
                            <button style={{
                                "--btn_font_color": "#bd451c",
                                "--btn_background_color": "#ffffff",
                                border: "2px solid #bd451c"
                            }} id="loginbtn" type="button" onClick={() => handleUploadImage()}
                                    className="ueberschrift_btn">Speichern
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal isOpened={isDeletePostModalOpened} onClose={() => setIsDeletePostModalOpened(false)}>
                <div className="modal_group">
                    <div className="modal_group_item">
                        <h4 className="teaser_h4_bold">Willst Du das Inserat endgültig löschen?</h4>
                    </div>
                    <div style={{flex: "1"}}></div>
                    <div style={{justifyContent: "space-between", flexDirection: "row"}} className="modal_group_item">
                        <div className="btn_section">
                            <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#bd451c"}}
                                    id="loginbtn" type="button" onClick={() => setIsDeletePostModalOpened(false)}
                                    className="ueberschrift_btn">Abbrechen
                            </button>
                        </div>
                        <div className="btn_section">
                            <button style={{
                                "--btn_font_color": "#bd451c",
                                "--btn_background_color": "#ffffff",
                                border: "2px solid #bd451c"
                            }} id="loginbtn" type="button" onClick={() => deletePost()}
                                    className="ueberschrift_btn">Löschen
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal isOpened={isPostDeletedModalOpened} onClose={() => setIsPostDeletedModalOpened(false)}>
                <div className="modal_group">
                    <div className="modal_group_item">
                        <h4 className="teaser_h4_bold">Dein Inserat wurde erfolgreich gelöscht.</h4>
                    </div>
                    <div style={{flex: "1"}}></div>
                    <div style={{justifyContent: "flex-end", flexDirection: "row"}} className="modal_group_item">
                        <div className="btn_section">
                            <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                    id="loginbtn" type="button" onClick={() => redirect_to_profile()}
                                    className="ueberschrift_btn">Fertig
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <Footer/>
        </main>
    )
}

export default Edit_post;
