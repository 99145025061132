import React, {useEffect, useState} from 'react';
import './editorical_notes.css';
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";


function Editorical_notes ({ authorized }) {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect( () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
     data().catch(console.error);
    }, []);

    return (
        <div className="flexSequence">
            <main className="main">
                <Navbar logged={isLoggedInVar} admin={isAdminVar}></Navbar>
                <div className="editorical_notes_content">
                    <div>
                        <h2>Impressum</h2>
                        <div>
                            <p></p>
                            <p>Berliner Stadtreinigungsbetriebe AöR</p>
                            <p></p>
                            <p>
                                Ringbahnstr. 96
                                <br/>
                                12103 Berlin
                                <br/>
                                Tel.: 030 7592-4900
                                <br/>
                                Fax: 030 7592-2262
                                <br/>
                                Email: service@BSR.de
                                <br/>
                                 <a href="www.BSR.de">Web: www.BSR.de</a>
                            </p>
                            <p>
                                Vorstand
                                <br/>
                                Stephanie Otto (Vorsitzende)
                                <br/>
                                Werner Kehren
                                <br/>
                                Martin Urban
                                <br/>
                                Registergericht: AG Berlin Charlottenburg, HRA 33292
                                <br/>
                                Umsatzsteuer-Identifikationsnummer: DE 136 630 343
                            </p>
                        </div>
                    </div>
                    <div><h2>Haftung</h2>
                       <p>Die BSR erstellt alle Informationen dieser Website mit größtmöglicher Sorgfalt. Jedoch wird eine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität nicht übernommen. Die Nutzung der Website selbst oder der Inhalte der Website erfolgt auf eigene Gefahr der Nutzer:innen.</p>
                       <p>Die BSR behält es sich ausdrücklich vor, Inhalte ganz oder teilweise ohne gesonderte Ankündigung zu ändern oder zu löschen.</p>
                       <p>In durch Nutzer:innen erstellten Beiträgen oder Nachrichten wird deren Meinung wiedergegeben, die nicht mit der Meinung der BSR übereinstimmen muss.</p>
                       <p>Durch die reine Nutzung der Website wird kein vertragliches Verhältnis an sich zwischen der BSR und der / dem Nutzer:in begründet.</p>
                    </div>
                    <div><h2>Websites Dritter/ Verlinkungen</h2>
                        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Bei Kenntniserlangung von Rechtsverstößen werden diese Links jedoch unverzüglich gelöscht.</p>
                    </div>
                    <div><h2>Urheberrecht</h2>
                      <p>Für die auf der Website veröffentlichten Inhalte gilt das deutsche Urheberrecht. Jede danach nicht zulässige Nutzung oder Verwertung bedarf der schriftlichen Zustimmung der BSR.</p>
                      <p>Das Einrichten von Links auf die Website der BSR ist zulässig, soweit damit keine geschäftliche oder Rufschädigung der BSR herbeigeführt wird. Die BSR ist darüber vom Linksetzenden per Email an service@BSR.de zu informieren.</p>
                      <p>Vom Nutzer für die Website zur Verfügung gestellte Beiträge, Fotos und andere Inhalte dürfen von der BSR auf der Website uneingeschränkt dargestellt werden. Die / der Nutzer:in ist dafür verantwortlich, dass durch die Veröffentlichung ihrer / seiner Inhalte keine Urheberrechte Dritter berührt oder verletzt werden.</p>
                      <p>Sofern die / der Nutzer:in zu einem späteren Zeitpunkt möchte, dass zur Verfügung gestellte oder selbst eingestellte Inhalte gelöscht werden und dies der BSR in geeigneter Form mitteilt, löscht die BSR die entsprechenden Inhalte von der eigenen Website und ebenfalls von anderen Websites, sofern diese im BSR-Zugriff liegen.</p>
                    </div>
                    <div><h2>Nutzerbeiträge</h2>
                       <p>Auf Seiten, auf denen Nutzer:innen selbst Inhalte (z.B. Texte, Fotos, Grafiken) einstellen können oder auf denen durch Nutzer:innen eingereichte Inhalte durch die BSR veröffentlicht werden, trägt die / der Nutzer:in die Verantwortung für den Inhalt und die Einhaltung der „Netikette“ (gutes Benehmen in der virtuellen Kommunikation) sowie die Wahrung des Urheberrechts.</p>
                       <p>Insbesondere verboten sind verleumderische, ehrverletzende, pornografische, sexuell herabwürdigende, rassistische, gewaltverherrlichende und sonstige nichtrechtsstaatliche Inhalte.</p>
                       <p>Nutzer:innen dürfen nur Inhalte, gleich ob Text oder Bild, einreichen / einstellen, wenn dadurch Urheberrechte Dritter nicht berührt werden. Für die eingestellten Inhalte ist die / der einstellende Nutzer:in haftbar. Die BSR wird Inhalte, die dem Vorgenannten nicht entsprechen, nach Kenntnisnahme von der Website entfernen.</p>
                    </div>
                </div>
                <Footer/>
            </main>
        </div>
    )
}

export default Editorical_notes;