import React, { useEffect, useRef } from "react";
import CookieConsent from "react-cookie-consent";
import BannerContent from "./banner_content";
import "./cookie_banner.css";
import { getCookie, createSession, setPageview } from "./utils";

import { mapValues } from "lodash";

function CookieBanner(props) {
  // TODO: give {debug} instead of prop
  const debug = props.debug;

  // create new Session if user had already given consent
  useEffect(() => {
    if (!sessionStorage.getItem("SessionID") && getCookie("CookieConsent")) {
      createSession();
      if (localStorage.getItem("c_statistik") === "true")
        setPageview(window.location.pathname);
    }
  }, []);

  // checkbox states from child component BannerContent
  const checkboxData = useRef({
    Notwendig: true,
    Komfort: false,
    Statistik: false,
  });
  const checkboxStatesUpdate = (checkboxStates) => {
    checkboxData.current["Komfort"] = checkboxStates[0];
    checkboxData.current["Statistik"] = checkboxStates[1];
  };
  // Alle Cookies erlauben button
  const allowAllCookies = () => {
    checkboxData.current = mapValues(checkboxData.current, () => true);
    localStorage.setItem("c_komfort", "true");
    localStorage.setItem("c_statistik", "true");
    createSession();
    setPageview(window.location.pathname);
  };
  // Einstellungen Speichern button
  const saveOptions = () => {
    localStorage.setItem("c_komfort", checkboxData.current["Komfort"]);
    localStorage.setItem("c_statistik", checkboxData.current["Statistik"]);
    createSession();
    if (checkboxData.current["Statistik"] === "true")
      setPageview(window.location.pathname);
  };

  return (
    <CookieConsent
      id="CookieConsent"
      debug={debug}
      expires={365}
      location="bottom"
      disableStyles
      overlay
      enableDeclineButton
      buttonText="Alle Cookies erlauben"
      declineButtonText="Einstellungen speichern"
      /*children={BannerContent(checkboxStates)}*/
      onAccept={() => {
        allowAllCookies();
      }}
      onDecline={() => {
        saveOptions();
      }}
      /* styles from cookie_banner.css */
      containerClasses="cookie_banner_container"
      buttonWrapperClasses="cookie_banner_button_container"
      buttonClasses="cookie_banner_button"
      declineButtonClasses="cookie_banner_button"
      overlayClasses="cookie_banner_overlay"
    >
      <BannerContent checkboxStatesUpdate={checkboxStatesUpdate} />
    </CookieConsent>
  );
}

export default CookieBanner;
