import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown, {AdminBereich} from './Dropdown';
import start_icon from './icons/Start_grau.svg'
import start_icon_weiss from './icons/Start_weiß.svg'
import market_icon from './icons/Marktplatz_grau.svg'
import market_icon_weiss from './icons/Marktplatz_weiß.svg'
import locker_icon from './icons/Locker_grau.svg'
import locker_icon_weiss from './icons/Locker_weiß.svg'
import profil_icon from './icons/Profil_grau.svg'
import profil_icon_weiss from './icons/Profil_weiß.svg'
import logout_icon from './icons/Abmelden_weiss.svg'
import admin_icon from './icons/Admin_grau.svg'
import admin_icon_weiss from './icons/Admin_orange.svg'
import bsr_logo from './icons/BSR_RGB.svg'
import MeinBereich from "./Dropdown";

function Navbar({logged/* = false*/, admin/* = false*/}) {
  const [click, setClick] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter1 = () => {
    updateItem(1, true)
    if (window.innerWidth < 960) {/*
      setDropdown1(true);*/
    } else {/*
      setDropdown1(true);*/
    }
  };
  const onMouseEnter2 = () => {
    updateItem(4, true)
    if (window.innerWidth < 960) {
      /*setDropdown2(true);*/
    } else {
      /*setDropdown2(true);*/
    }
  };

  const onMouseLeave1 = () => {
    updateItem(1, false)
    if (window.innerWidth < 960) {/*
      setDropdown1(false);*/
    } else {/*
      setDropdown1(false);*/
    }
  };
  const onMouseLeave2 = () => {
    updateItem(4, false)
    if (window.innerWidth < 960) {
      /*setDropdown2(false);*/
    } else {
      /*setDropdown2(false);*/
    }
  };

  const [isShown, setIsShown] = useState([false, false, false, false, false]);
    // Update an item in the array
  const updateItem = (index, val) => {
    setIsShown(isShown => {
      const newArray = [...isShown];
      newArray[index] = val;
      return newArray;
    });
  };

  const redirect_to_start_page = () => {
        window.location.href = "/start_seite";
    }
    const redirect_to_locker = () => {
        window.location.href = "/start_seite#start_seite_locker";
    }
    const redirect_to_market = () => {
        window.location.href = "/start_seite#start_site_market";
    }
    const redirect_to_bookings = () => {
        window.location.href = "/profile#profile_my_bookings";
    }
    const redirect_to_profile = () => {
        window.location.href = "/profile";
        closeMobileMenu();
    }
    const redirect_to_admin = () => {
        window.location.href = "/admin";
        closeMobileMenu();
    }

  if (!logged){
    return (
      <>
        <nav className='navbar'>
          <Link to='/' className='navbar-logo' onClick={redirect_to_start_page}>
            <img src={bsr_logo}/>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item extra_tab'
                onMouseEnter={() => updateItem(0, true)}
                onMouseLeave={() => updateItem(0, false)}>
              <Link to='/login' className='nav-links' onClick={closeMobileMenu}>
                <div style={isShown[0] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>Anmelden</div>
              </Link>
            </li>
            <li className='nav-item extra_tab'
                onMouseEnter={() => updateItem(1, true)}
                onMouseLeave={() => updateItem(1, false)}
            >
              <Link
                to='/register'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[1] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>Registrieren</div>
                 {/*<i className='fas fa-caret-down' />*/}
              </Link>
              {/*dropdown && <Dropdown />*/}
            </li>
            <li className='nav-item'
                onMouseEnter={() => updateItem(2, true)}
                onMouseLeave={() => updateItem(2, false)}>
              <Link
                to='/start'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[2] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <img src={isShown[2] || window.innerWidth > 960 ? start_icon_weiss : start_icon}/>
                  Start
                </div>
              </Link>
            </li>
            <li className='nav-item'
                onMouseEnter={() => updateItem(3, true)}
                onMouseLeave={() => updateItem(3, false)}
                onClick={redirect_to_locker}>
              <Link
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[3] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <img src={isShown[3] || window.innerWidth > 960 ? locker_icon_weiss : locker_icon}/>
                  Kiez-Locker
                </div>
              </Link>
            </li>
            <li className='nav-item'
                onMouseEnter={() => updateItem(4, true)}
                onMouseLeave={() => updateItem(4, false)}>
              <Link
                to='market'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[4] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <img src={isShown[4] || window.innerWidth > 960 ? market_icon_weiss : market_icon}/>
                  Marktplatz
                </div>

              </Link>
            </li>
          </ul>
        </nav>
      </>
    );
  }
  else {
    return (
      <>
        <nav className='navbar'>
          <Link to='/' className='navbar-logo' onClick={redirect_to_start_page}>
            <img src={bsr_logo}/>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'
                onMouseEnter={() => updateItem(0, true)}
                onMouseLeave={() => updateItem(0, false)}
            >
              <Link to='/start_seite' className='nav-links' onClick={closeMobileMenu}

              >

                <div style={isShown[0] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}} >
                  <img src={isShown[0] || window.innerWidth > 960 ? start_icon_weiss : start_icon}/>
                  Start
                </div>
              </Link>
            </li>
            <li
              className='nav-item'
              onMouseEnter={onMouseEnter1}
              onMouseLeave={onMouseLeave1}
                //onMouseEnter={() => updateItem(1, true)}
              //onMouseLeave={() => updateItem(1, false)}
            >
              <Link
                /*to='/profile'*/
                className='nav-links'
                /*onClick={closeMobileMenu}*/
              >
                <div style={isShown[1] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <div onClick={redirect_to_profile}>
                    <img src={isShown[1] || window.innerWidth > 960 ? profil_icon_weiss : profil_icon}/>
                    Mein Bereich
                  </div>
                  <i className={dropdown1 ? 'fas fa-caret-up' : 'fas fa-caret-down'} onClick={() => setDropdown1(!dropdown1)}/>
                </div>

              </Link>
              {
                window.innerWidth > 960 ?
                  dropdown1 && <MeinBereich />
                :
                  null
              }

            </li>
            {
                window.innerWidth < 960 ?
                  dropdown1 && <MeinBereich />
                :
                  null
              }
            <li className='nav-item'
                onMouseEnter={() => updateItem(2, true)}
                onMouseLeave={() => updateItem(2, false)}>
              <Link
                to='/insert_box'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[2] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <img src={isShown[2] || window.innerWidth > 960 ? locker_icon_weiss : locker_icon}/>
                  Fachbuchung
                </div>
              </Link>
            </li>
            <li className='nav-item'
                onMouseEnter={() => updateItem(3, true)}
                onMouseLeave={() => updateItem(3, false)}>
              <Link
                to='/market'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <div style={isShown[3] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <img src={isShown[3] || window.innerWidth > 960 ? market_icon_weiss : market_icon}/>
                  Marktplatz
                </div>
              </Link>
            </li>
            {
              admin &&
            <li
                className='nav-item'
                onMouseEnter={onMouseEnter2}
                onMouseLeave={onMouseLeave2}
                //onMouseEnter={() => updateItem(4, true)}
                //onMouseLeave={() => updateItem(4, false)}
            >
              <Link
                /*to='/admin'*/
                className='nav-links'
                /*onClick={closeMobileMenu}*/
              >
                <div style={isShown[4] || window.innerWidth > 960 ? {color: "#fff"} : {color: "#666"}}>
                  <div onClick={redirect_to_admin}>
                    <img src={isShown[4] || window.innerWidth > 960 ? admin_icon_weiss : admin_icon}/>
                    Admin
                  </div>
                  <i className={dropdown2 ? 'fas fa-caret-up' : 'fas fa-caret-down'} onClick={() => setDropdown2(!dropdown2)}/>
                </div>

              </Link>
              {
                window.innerWidth > 960 ?
                  dropdown2 && <AdminBereich />
                :
                  null
              }
            </li>
            }
            {
                window.innerWidth < 960 && admin?
                  dropdown2 && <AdminBereich />
                :
                  null
              }
            {/*
            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
            */}
          </ul>
          {/*<Button/>*/}
        </nav>
      </>
    );
  }
}

export default Navbar;
