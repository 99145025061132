import React, {useEffect, useState} from 'react';
import './admin_page.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import admin_icon from "../../static/admin/Admin Profil_grau.svg"
import locker_icon from "../../static/admin/Locker_grau.svg"
import beitraege_icon from "../../static/admin/Beiträge_grau.svg"
import statistiken_icon from "../../static/admin/analytics.svg"
import postfach_icon from "../../static/admin/Postfach _grau.svg"
import marktplatz_icon from "../../static/admin/Marktplatz grau.svg"
import RestAPI from "../../RestAPI";
import {isTesting} from "../../App";

function Start_page({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [adminFirstName, setAdminFirstName] = useState("Mara")
    const [adminLastName, setAdminLastName] = useState("Musterfrau")
    const [postsCounter, setPostsCounter] = useState(0)
    const [notificationCounter, setNotificationCounter] = useState(0);
    const options = [
        'one', 'two', 'three'
    ];
    const defaultOption = options[0];
    const data = [{"name": "test1", "test": "test"}, {"name": "test2"}];

    function loadPosts(posts) {
        posts.forEach({})
    }

    function switch_to_locker_load() {
        //switchToIndex("Locker_load");
        window.location.href = "/locker_load";
    }

    function switch_to_release_posts() {
        //switchToIndex("Release_posts");
        window.location.href = "/release_posts";
    }
    function switch_to_statistics() {
        //switchToIndex("Release_posts");
        window.location.href = "/analytics";
    }
    function switch_to_db_export() {
        window.location.href = "/db_export";
    }

    useEffect(() => {
        getAdminInfos();
        checkForPostsToActivate()
        checkForNotifications()
    }, []);

    function checkForNotifications() {
        RestAPI("/api/check_for_notifications", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setNotificationCounter(response["notificationCounter"])
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }


    function checkForPostsToActivate() {
        RestAPI("/api/check_for_posts_to_activate", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setPostsCounter(response["postsCounter"])
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    function getAdminInfos() {
        RestAPI("/api/get_admin_infos", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setAdminFirstName(response["first_name"]);
                    setAdminLastName(response["last_name"]);
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <div className="admin_page_content">
                <div className="admin_page_content_item admin_page_content_description">
                    <div className="admin_page_content_description_item">
                        <img src={admin_icon}/>
                    </div>
                    <div id="admin_page_content_description_item_right" className="admin_page_content_description_item">
                        <p className="teaser_h4">Administrator:in</p>
                        <h1 id="adminName" className="titel_h1">{adminFirstName} {adminLastName}</h1>
                    </div>
                </div>
                <div className="admin_page_content_item admin_page_content_item_main_field_background">
                    <div className="admin_page_content_item_main_field">
                        <div className="admin_page_content_item_main_field_item" onClick={switch_to_locker_load}>
                            <div className="admin_page_inbox_notification">
                            </div>
                            <img src={locker_icon}/>
                            <h3 className="quadrat_btn_beschriftung">Locker</h3>
                        </div>
                        <div style={{display: ""}} className="admin_page_content_item_main_field_item"
                             onClick={switch_to_release_posts}>
                            <div className="admin_page_inbox_notification">
                                {isTesting || postsCounter > 0 ?
                                    <span className="admin_page_notifications_badge">{postsCounter}</span> : null}
                            </div>
                            <img src={beitraege_icon}/>
                            <h3 className="quadrat_btn_beschriftung">Inserate prüfen</h3>
                        </div>
                        <div style={{display: ""}} className="admin_page_content_item_main_field_item" onClick={switch_to_statistics}>
                            <img src={statistiken_icon}/>
                            <h3 className="quadrat_btn_beschriftung">Statistiken</h3>
                        </div>
                        <div style={{display: ""}} className="admin_page_content_item_main_field_item" onClick={switch_to_db_export}>
                            <img src={statistiken_icon}/>
                            <h3 className="quadrat_btn_beschriftung">Datenbank exportieren</h3>
                        </div>
                        {/*
                        <div style={{display: ""}} className="admin_page_content_item_main_field_item">
                            <div className="admin_page_inbox_notification">
                                {isTesting || notificationCounter > 0 ?
                                    <span
                                        className="admin_page_notifications_badge">{notificationCounter}</span> : null}
                            </div>
                            <img src={postfach_icon}/>
                            <h3>Postfach</h3>
                        </div>
                        */}
                        {/*
                        <div style={{display: ""}} className="admin_page_content_item_main_field_item">
                            <div className="admin_page_inbox_notification">
                            </div>
                            <img src={marktplatz_icon}/>
                            <h3>Marktplatz</h3>
                        </div>
                        */}
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Start_page;
