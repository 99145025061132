import React, {useEffect, useState} from 'react';
import './new_login.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import leftSideGraphic from "../../static/register_login/undraw_Mail_sent_re_0ofv.png";


function Mail_sent({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [loginClicked, setLoginClicked] = useState(false);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoginClicked(true);
    }
    useEffect(() =>{
        if (loginClicked){
            switchToIndex("Enter_login_data");
        }
    });

    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}></Navbar>
            <div className="info_box">
                <div className="login_content">
                    <div style={{display:"none"}} className="login_content_left">
                        <img style={{width:"300px"}} src={leftSideGraphic}/>
                    </div>
                    <div className="login_content_right login_content_right_forgot_pass">
                        <div className="login_content_right_frame login_content_right_frame_forgot_pass">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Passwort zurücksetzen</h3>
                            </div>
                            <div className="login_content_right_item">
                                <p style={{color:"#666"}} className="fliesstext_p">Du erhältst in Kürze eine E-Mail.<br/>Klicke dort auf den Link, um Dein Passwort zu ändern.</p>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{alignItems:"flex-end"}} className="login_content_right_item">
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Einloggen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default Mail_sent;