import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAdmin} from './index';
import Loading_spinner from "../common/loading_spinner";

const ProtectedRoute = ({component: Component, ...rest}) => {
  const [state, setState] = useState('loading');

  useEffect(() => {
    (async function() {
      try {
        /* Update effect logic to track correct state */
        const isUserLogged = await isAdmin();
        setState(isUserLogged ? 'loggedin' : 'redirect');
      }
      catch {
        setState('redirect');
      }
    })();
  }, []);

  /* If in loading state, return loading message while waiting for
  isValidToken to complete */
  if(state === 'loading') {
    return <Loading_spinner isRunning={true}>Loading..</Loading_spinner>
  }
    return (
        <Route {...rest} render={props => (
            (state === 'loggedin') ? <Component {...props} /> : <Redirect to="/start_seite"/>
        )}/>
    );
};

export default ProtectedRoute;