import React, {useEffect, useState} from 'react';
import './chat.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";
import postfach_icon from "../../static/chat/Postfach_orange.svg"
import back_arrow from "../../static/chat/einfacher Pfeil runter_grau.svg"
import dopple_arrow from "../../static/chat/Doppelpfeil_hellgrau.svg"
import locker_icon from "../../static/chat/Locker_orange.svg"
import Sprechblasen from "../../static/chat/Postfach Sprechblasen 03.svg"
import Chat_room, {My_message, Your_message} from "./chat_components";
import RestAPI from "../../RestAPI";
import Post from "../market/post";
import Loading_spinner from "../common/loading_spinner";
import {isLoggedIn, isTesting, LscreenWidth, MscreenWidth, screenWidth, XLscreenWidth, XXLscreenWidth} from "../../App";
import RestApi from "../../RestAPI";

function Chat() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [conversationTitle, setConversationTitle] = useState("");
    const [conversationPartner, setConversationPartner] = useState("");
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
    const [slideBarClicked, setSlideBarClicked] = useState(false);
    const [showBookingButton, setShowBookingButton] = useState(false);
    const [conversationClicked, setConversationClicked] = useState(false);
    const [switchChatRoomsConversation, setSwitchChatRoomsConversation] = useState(false);
    const [switchShowSideBar, setSwitchShowSideBar] = useState(false);
    const [currentConversationId, setCurrentConversationId] = useState(0);
    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    const [values, setValues] = useState({
        messageBody: ""
    })

    function handleBookBoxButton(){
        let bookingData = {username : conversationPartner, bookingTitle : conversationTitle}
        sessionStorage.setItem("Booking", JSON.stringify(bookingData))
        window.location.href = "/insert_box"
    }

    const handleInput = (event) => {
        if (/\S/.test(event.target.value) && values.messageBody.length > 0) {
            setIsSendButtonDisabled(false)
        } else {
            setIsSendButtonDisabled(true)
        }

        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    };
     useEffect(() => {
         if (/\S/.test(values.messageBody) && values.messageBody.length > 0) {
            setIsSendButtonDisabled(false)
        } else {
            setIsSendButtonDisabled(true)
        }

     },[values.messageBody])

    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
        getMyConversations()
    }, []);

    function handleConversationClick(conversationId, messageId, conversationPartner, conversationTitle, isNewMessage, latestMessageSentByMe) {
        setConversationClicked(true);
        getMessagesOfAConversation(conversationId);
        setSwitchChatRoomsConversation(true);
        if (isNewMessage === 1 && latestMessageSentByMe === 0) {
            setMessageIsRead(messageId)
        }

        setConversationPartner(conversationPartner)
        setConversationTitle(conversationTitle)
        // set conversation after open it
        let objIndex = conversations.findIndex((obj => obj.id === conversationId));
        conversations[objIndex].newMessage = 0
        // check if I started the conversation to show the booking button
        conversations[objIndex].isStartedByMe === 1 ? setShowBookingButton(false) : setShowBookingButton(true)
        setCurrentConversationId(conversationId)
    }

    useEffect(() => {
      const timer = setInterval(() => {
        getMyConversations()
      }, 10000);
      return () => clearInterval(timer);
    }, []);

    function getMyConversations() {
        setRestQueryIsLoading(true);
        RestAPI("/api/get_my_conversations", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                setRestQueryIsLoading(false);
                if (response["status"] === 200) {
                    setConversations(response["myConversations"])

                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    function sendMessage() {
        let data = {messageBody : values.messageBody, conversationId: currentConversationId}
        RestApi("/api/send_message", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                     setMessages(prevArray => [...prevArray, {
                         "body": values.messageBody,
                         "timestamp": response["timestamp"],
                         "isMyMessage": 1
                     }])
                    setValues({
                        ...values,
                        messageBody: "",
                    })
                } else {

                }
            })
            .catch((err) => {
            });
    }


    function setMessageIsRead(messageID) {
        let data = {messageID: messageID}
        RestAPI("/api/set_message_read", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {


                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    function getMessagesOfAConversation(conversationId) {
        let data = {conversationId: conversationId}
        RestAPI("/api/get_messages_of_conversation", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setMessages(response["messages"])
                    scrollDown();

                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    function scrollDown(){
        screenWidth.matches ?
        setSwitchChatRoomsConversation(true)
            :
        setSwitchChatRoomsConversation(false)
        let test = document.getElementById("messagesBlock");
        test.scrollTop = test.scrollHeight;
    }
    function scrollDownTesting(){
        setSwitchChatRoomsConversation(true);
        let test = document.getElementById("messagesBlock");
        test.scrollTop = test.scrollHeight;
    }
    const redirect_to_market = () => {
        window.location.href = "/market";
    }

    return (
        <main style={{justifyContent:"flex-start"}} className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <div className={"chat_content"}>
                <div className={"chat_content_slogan"}>
                    <div>
                        <img src={postfach_icon}/>
                    </div>
                    <div>
                        <h1 className={"titel_h1"}>Mein Postfach</h1>
                    </div>
                </div>

                <div className={"chat_content_area"}>
                    <div className={"chat_content_area_window"}>
                        {
                            !screenWidth.matches && conversations.length ?
                            <div className={"chat_content_area_window_chatrooms_switchIcon"}>
                                <img onClick={() => setSlideBarClicked(!slideBarClicked)} style={slideBarClicked ? {width:"24px", rotate:"180deg"} : {width:"24px"}} src={dopple_arrow}/>
                            </div>
                                :
                                null
                        }

                        <div style={!switchChatRoomsConversation && (screenWidth.matches || MscreenWidth.matches || LscreenWidth.matches || XLscreenWidth.matches || XXLscreenWidth.matches) ? {} : {display:"none"}} className={slideBarClicked || screenWidth.matches ? "chat_content_area_window_chatrooms" : "chat_content_area_window_chatrooms_hidden"}>
                            <div className={"chat_content_area_window_chatrooms_title"}>
                                <p className={"teaser_h4_bold"}>Nachrichten</p>
                            </div>
                            <div className={"chat_content_area_window_chatrooms_content"}>
                                {/*
                                    <div onClick={scrollDown}>
                                        <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                    <div onClick={scrollDown}>
                                    <Chat_room></Chat_room>
                                    </div>
                                */}
                                {
                                    isTesting ?
                                        <div onClick={scrollDown}>
                                            <Chat_room></Chat_room>
                                        </div>
                                        :
                                    conversations.length ?
                                        conversations.map(function (d) {
                                            return (
                                                <div onClick={() => handleConversationClick(d.id, d.latestMessageID, d.username, d.title, d.newMessage, d.latestMessageSentByMe)}>
                                                    <Chat_room username={d.username}
                                                               latestMessage={d.latestMessage}
                                                               newMessage={d.newMessage === 1}
                                                               title={d.title}/>
                                                </div>)
                                        })
                                        :
                                        <div className={"no_chatrooms"}>
                                            <img src={Sprechblasen}/>
                                            <div>
                                                <p className={"fliesstext_p"}>Starte eine Anfrage</p>
                                                <span className="my_span" onClick={redirect_to_market}>Zum Marktplatz</span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>


                        {
                            conversations.length || isTesting?
                                conversationClicked || isTesting?
                                    <div style={!switchChatRoomsConversation && screenWidth.matches ? {display:"none"} : {}} className={slideBarClicked ? "chat_content_area_window_textingfield" : "chat_content_area_window_textingfield_middle"}>
                                        <div className={"chat_content_area_window_textingfield_otheruserfield"}>
                                            <div className={"chat_content_area_window_textingfield_otheruserfield_back_icon"}>
                                                <img onClick={() => setSwitchChatRoomsConversation(!switchChatRoomsConversation)} src={back_arrow}/>
                                            </div>
                                            <div className={"chat_content_area_window_textingfield_otheruserfield_texts"}>
                                                <p style={{color: "#666"}}
                                                   className={"fliesstext_p"}>{isTesting ? "Max Mustermann" : conversationPartner}</p>
                                                <h4 className={"teaser_h4_bold"}>{isTesting ? "Staubsauger" : conversationTitle}</h4>
                                            </div>
                                            {(showBookingButton || isTesting) &&
                                                <div className={"chat_content_area_window_textingfield_otheruserfield_btn"}>
                                                    {
                                                        LscreenWidth.matches ?
                                                            <img onClick={handleBookBoxButton} src={locker_icon}/>
                                                            :
                                                            <button style={{
                                                                "--btn_font_color": "#ffffff",
                                                                "--btn_background_color": "#a0a0a0"
                                                            }} className={"ueberschrift_btn"} onClick={handleBookBoxButton}>Fach
                                                                buchen</button>
                                                    }
                                                </div>
                                            }
                                        </div>


                                        {
                                            isTesting ?

                                                <div id={"messagesBlock"} className={"chat_content_area_window_textingfield_background messages"}>

                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <Your_message></Your_message>
                                                    <My_message></My_message>
                                                    <My_message></My_message>
                                                    <My_message messageContent={"Lass mich in Ruhe!"}></My_message>
                                                    <My_message messageContent={"Lass mich in Ruhe!"}></My_message>
                                                    <My_message messageContent={"Lass mich in Ruhe!"}></My_message>
                                                </div>
                                                :
                                                <div id={"messagesBlock"} className={"chat_content_area_window_textingfield_background messages"}>
                                                    {
                                                        messages.map(function (d) {
                                                            if (d.isMyMessage === 1) {
                                                                return (<My_message messageContent={d.body}
                                                                                    timestamp={d.timestamp}></My_message>)
                                                            } else {
                                                                return (<Your_message username={d.partnerUsername}
                                                                                      messageContent={d.body}
                                                                                      timestamp={d.timestamp}
                                                                                      hideIcon={false}></Your_message>)
                                                            }
                                                        })
                                                    }
                                                </div>

                                        }
                                        <div className={"chat_content_area_window_textingfield_inputfield"}>
                                            <div className={"chat_content_area_window_textingfield_inputfield_input"}>
                                                <input name={"messageBody"} placeholder={"Text eingeben..."}
                                                       value={values.messageBody} onChange={handleInput}/>
                                            </div>
                                            <div className={"chat_content_area_window_textingfield_inputfield_submitbtn"}>
                                                <button disabled={isSendButtonDisabled} onClick={sendMessage}
                                                        style={!isSendButtonDisabled ? {
                                                            "--btn_font_color": "#ffffff",
                                                            "--btn_background_color": "#f61"
                                                        } : {
                                                            "--btn_font_color": "#ffffff",
                                                            "--btn_background_color": "#a0a0a0"
                                                        }}
                                                        className={"ueberschrift_btn"}>Senden
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                            :
                                    <div className={"chat_content_area_window_textingfield_empty"}>
                                        <p className={"fliesstext_p"}>Wähle ein zu lesendes Element aus.</p>
                                    </div>
                                :
                            <div className={"chat_content_area_window_textingfield_empty"}>
                                <div>
                                    <img src={Sprechblasen}/>
                                </div>
                                <p className={"fliesstext_p"}>Starte eine Anfrage</p>
                                <span className="my_span" onClick={redirect_to_market}>Zum Marktplatz</span>
                            </div>
                        }
                    </div>
                </div>
                <div className={"internal_footer"}></div>
            </div>

        </main>
    )
}

export default Chat;
