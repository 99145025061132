import React, {useEffect, useState} from 'react';
import './profile.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import profile_orange from "../../static/profile/Profil_orange.svg";
import edit_icon from "../../static/profile/Bearbeiten Stift_grau.svg";
import Modal from "../modal/modal.js";
import {isTesting, screenWidth} from "../../App";
import RestAPI from "../../RestAPI";
import Parcel from "../common/parcel";
import {isAdmin, isLogin} from "../login";
import Post from "../market/post";
import Loading_spinner from "../common/loading_spinner";

function Profile() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);
    const [firstName, setFirstName] = useState("firstName");
    const [username, setUsername] = useState("username");
    const [lastName, setLastName] = useState("lastName");
    const [email, setEmail] = useState("email");
    const [newEmail, setNewEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("phoneNumber");
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [verificationNumber, setVerificationNumber] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [isNumberVerificationModalOpened, setIsNumberVerificationModalOpened] = useState(false);
    const [isEmailVerificationModalOpened, setIsEmailVerificationModalOpened] = useState(false);
    const [numberVerificationStep, setNumberVerificationStep] = useState("0");
    const [numberVerificationError, setNumberVerificationError] = useState("0");
    const [emailVerificationStep, setEmailVerificationStep] = useState("0");
    const [pinId, setPinId] = useState("pinId");
    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    const my_inserate = [
        {"id": 12345678901234567890, "date": "02.08.2023 14:55:00", "views": "25", "title": "Tassen", "type": "Tauschen"},
        {"id": 2, "date": "02.08.2023 14:55:00", "views": "12", "title": "Schreibtisch", "type": "Weitergeben"},
        {"id": 3, "date": "02.08.2023 14:55:00", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 4, "date": "02.08.2023 14:55:00", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 5, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 6, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 7, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
    ];
    const [myBookingsAsSender, setMyBookingsAsSender] = useState([]);
    const [myBookingsAsRecipient, setMyBookingsAsRecipient] = useState([]);
    const [myPosts, setMyPosts] = useState([]);

    useEffect(() => {
        if (!isNumberVerificationModalOpened) {
            setNumberVerificationStep("0");
        }
        if (!isEmailVerificationModalOpened) {
            setEmailVerificationStep("0");
        }
    }, [isNumberVerificationModalOpened, isEmailVerificationModalOpened]);
    const redirect_to_register = () => {
        window.location.href = "/login";
    }

    useEffect(() => {
        getProfileData();
    }, []);

    let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let validNumber = /^[1-9]\d*$/;
    let validVerificationNumber = /^[0-9]\d*$/;

    const handlePhoneNumberFieldChange = (event) => {
        if (event.target.value === '' || validNumber.test(event.target.value)) {
            setNewPhoneNumber(event.target.value);
        }
    }
    const handleEmailFieldChange = (event) => {
        setNewEmail(event.target.value);
    }
    const handleVerificationPhoneNumberFieldChange = (event) => {
        if (event.target.value === '' || validVerificationNumber.test(event.target.value)) {
            setVerificationNumber(event.target.value);
        }
    }

    const validateEmailInput = () => {
        if (newEmail === '' || !newEmail.match(validEmail)) {
            alert("Email ist nicht gültig!")
        } else if (newEmail === email) {
            alert("Sie haben die hinterlegte Mailadresse eingegeben!")
        } else {
            sendEmail();

        }

    }


    function getProfileData() {
        setRestQueryIsLoading(true);
        RestAPI("/api/profile", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                setRestQueryIsLoading(false);
                if (response["status"] === 200) {
                    setFirstName(response["first_name"]);
                    setUsername(response["username"]);
                    setLastName(response["last_name"]);
                    setEmail(response["email"]);
                    setPhoneNumber(response["phone_number"]);
                    setMyBookingsAsSender(response["bookings_as_sender"]);
                    setMyBookingsAsRecipient(response["bookings_as_recipient"]);
                    setMyPosts(response["my_posts"]);

                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    function openPhoneNumberModal() {
        setNewPhoneNumber("");
        setVerificationNumber("");
        setIsNumberVerificationModalOpened(true);

    }

    function verifyNumber() {

        let data = {phone_number: "+49" + newPhoneNumber}
        RestAPI("/api/signup/sms/send", "POST", data)
            .then((resp) => {
                return resp.json()
            }).then(response => {
            let message = response["message"];
            if (response["status"] === 200) {
                setPinId(response["pinId"]);
                setNumberVerificationStep("1");
            } else {
                if (response["errorId"] === 0) {
                    setNumberVerificationError("1");
                } else if (response["errorId"] === 1) {
                } else if (response["errorId"] === 2) {
                }
            }

        })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function approvePhoneNumber() {

        let data = {
            phone_number: "+49" + newPhoneNumber,
            sms_code: verificationNumber,
            pinId: pinId
        }

        RestAPI("/api/signup/sms/approve", "POST", data)
            .then((resp) => {
                return resp.json()
            }).then((response) => {
            let message = response["message"];
            if (response["status"] === 200) {
                setNumberVerificationStep("2")
            } else {
                //TODO
                //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

            }
        })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function updatePhoneNumberInDB() {

        let data = {
            phone_number: "+49" + newPhoneNumber,

        }
        RestAPI("/api/update_phone_number", "POST", data)
            .then((resp) => {
                return resp.json()
            }).then((response) => {
            let message = response["message"];
            if (response["status"] === 200) {
                window.location.href = "/profile"
            } else {
                //TODO
                //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

            }
        })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function deletePhoneNumberInDB() {
        let data = {
            phone_number: "+49" + newPhoneNumber,
            pinId: pinId

        }
        RestAPI("/api/delete_phone_number", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    //Todo
                    console.log("deleted")
                } else {
                    //TODO
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function sendEmail() {
        let data = {newEmail: newEmail}
        RestAPI("/api/send_verification_link", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setEmailVerificationStep("1");
                } else {
                    //TODO
                    alert("Problem aufgetreten")
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }
    const redirect_to_market = () => {
        window.location.href = "/market";
    }
    const redirect_to_booking = () => {
        window.location.href = "/insert_box";
    }

    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="profile_content">
                <div className="profile_content_item profile_personals">
                    <div id={"profile_personals_item_left"} className="profile_personals_item">
                        <img className="profile_personals_item_img" src={profile_orange}/>
                    </div>
                    <div id={"profile_personals_item_right"} className="profile_personals_item">
                        <div id="username" style={{borderBottom: "0px", display: "flex"}}>
                            <h1 style={{color: "#f61"}} className="titel_h1">{username}</h1>
                        </div>
                        <div>
                            <p className="fliesstext_p">{firstName + " " + lastName}</p>
                        </div>
                        <div>
                            <p className="fliesstext_p">{email}</p>
                            <img className="profile_personals_item_edit_icon"
                                 onClick={() => setIsEmailVerificationModalOpened(true)} src={edit_icon}/>
                        </div>
                        <div>
                            <p className="fliesstext_p">{phoneNumber}</p>
                            <img className="profile_personals_item_edit_icon" onClick={() => openPhoneNumberModal()}
                                 src={edit_icon}/>
                        </div>
                    </div>
                </div>
                {/*
                <div id="profile_my_posts" className="profile_content_item profile_content_item_components profile_content_item_gray_background">
                    <div className="profile_content_item_components_content">
                        <h3 className="zwischenueberschrift_h3">Meine Inserate</h3>
                        <div className="profile_content_item_components_content_items">
                            {
                                !isTesting ?
                                    myPosts.length != 0 ?
                                        myPosts.map(function (d) {
                                        return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                        type={d.type} isMyPost={true} path={d.image} id={d.id}/>)
                                        })
                                    :
                                        <p className={"fliesstext_p"}>Du hast noch keine Inserate.</p>
                                :
                                   my_inserate.map(function (d) {
                                        return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                        type={d.type} isMyPost={true} path={d.image} id={d.id}/>)
                                        })
                            }
                        </div>
                    </div>
                </div>
                */}

                {
                    myPosts.length > 0 ?
                <div id="profile_my_posts" className="profile_content_item profile_content_item_components profile_content_item_gray_background">
                    <div className="profile_content_item_components_content">
                        <div style={{width: "90%"}}>
                            <h3 className="zwischenueberschrift_h3">Meine Inserate</h3>
                        </div>

                        <div className="profile_content_item_components_content_items">
                            {
                                !isTesting ?
                                    myPosts.length != 0 ?
                                        myPosts.map(function (d) {
                                        return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                        type={d.type} isMyPost={true} path={d.image} id={d.id}/>)
                                        })
                                    :
                                        <p className={"fliesstext_p"}>Du hast noch keine Inserate.</p>
                                :
                                   my_inserate.map(function (d) {
                                        return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                        type={d.type} isMyPost={true} path={d.image} id={d.id}/>)
                                        })
                            }
                        </div>
                    </div>
                </div>
                        :
                <div id="profile_my_posts" className="profile_content_item profile_content_item_components profile_content_item_gray_background">
                    <div className="profile_content_item_components_content">
                        <div style={{width: "90%"}}>
                            <h3 className="zwischenueberschrift_h3">Meine Inserate</h3>
                        </div>
                        <div>
                            <p className={"fliesstext_p"}>Keine Inserate vorhanden.</p>
                            <span className="my_span" onClick={redirect_to_market}>Zum Marktplatz</span>
                        </div>


                    </div>
                </div>
                }
                {
                myBookingsAsSender.length > 0 ?
                <div id="profile_my_bookings" className="profile_content_item profile_content_item_components">
                    <div id="should_be_edited_later" className="profile_content_item_components_content">
                        <h3 className="zwischenueberschrift_h3">Meine Buchungen</h3>
                        <div style={{width: "100%"}} className="profile_content_item_components_content_items">
                            {
                                isTesting ?
                                    my_inserate.map(function (d) {
                                        return (<Parcel key={d.id} mine={true} date={d.date} number={d.id}
                                                        size={d.size} location={d.location} title={d.title}/>)
                                    })
                                    :
                                    myBookingsAsSender.map(function (d) {
                                        return (<Parcel key={d.number} mine={true} date={d.date} number={"**" + d.number}
                                                        size={d.size} fullNumber={d.number} location={d.location} title={d.title}/>)
                                    })
                            }
                        </div>
                    </div>
                </div>
                        :
                    <div id="profile_my_posts" className="profile_content_item profile_content_item_components profile_content_item_gray_background">
                    <div className="profile_content_item_components_content">
                        <div style={{width: "90%"}}>
                            <h3 className="zwischenueberschrift_h3">Meine Buchungen</h3>
                        </div>
                        <div>
                            <p className={"fliesstext_p"}>Keine Buchungen vorhanden.</p>
                            <span className="my_span" onClick={redirect_to_booking}>Zur Fachbuchung</span>
                        </div>
                    </div>
                    </div>
                }
                {
                myBookingsAsRecipient.length > 0 ?
                <div className="profile_content_item profile_content_item_components profile_content_item_gray_background">
                    <div className="profile_content_item_components_content">
                        <div style={{width: "90%"}}>
                            <h3 className="zwischenueberschrift_h3">Für mich bereitgestellt</h3>
                        </div>

                        <div className="profile_content_item_components_content_items">
                            {
                                isTesting ?
                                    my_inserate.map(function (d) {
                                        return (<Parcel key={d.id} mine={false} date={d.date} number={d.number}
                                                        size={d.size} location={d.location}/>)
                                    })
                                    :
                                    myBookingsAsRecipient.map(function (d) {
                                        return (<Parcel key={d.number} mine={false} date={d.date} number={"**" + d.number}
                                                        size={d.size} fullNumber={d.number} location={d.location} title={d.title}/>)
                                    })

                            }
                        </div>
                    </div>
                </div>
                    :
                    null
                }

            </div>


            <Footer/>
            { /* ↓ Pop-Up Window for phone number verification ↓ */}
            <Modal isOpened={isNumberVerificationModalOpened} onClose={() => setIsNumberVerificationModalOpened(false)}>
                {
                    numberVerificationStep === "0" ?
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Gib bitte Deine neue Telefonnummer ein:</h4>
                            </div>
                            <div className="modal_group_item modal_group_item_phoneNrInput">
                                <div style={{flex:".1", minWidth:"max-content"}}>
                                    <p className="fliesstext_p" style={{fontSize:"17px", maxWidth:"max-content", color:"#737373"}}>+49 (0)</p>
                                </div>
                                <div style={{flex:".9"}}>
                                    <input style={{flex:".9"}} id="phoneNumberField" className="text_input" value={newPhoneNumber}
                                       type="text" onChange={handlePhoneNumberFieldChange} placeholder={screenWidth.matches ? "Tel. beginnt mit 1" : "Telefonnummer beginnt mit 1"}/>
                                </div>

                                 </div>
                           { numberVerificationError === "1" ?
                              <div><p className={"numberVerificationError"}>Diese Nummer ist bereits vergeben! Bitte verwende eine andere Telefonnummer!</p></div>
                               : null
                           }
                            <div style={{flex: "1"}}></div>
                            <div className=""></div>
                            <div className="modal_group_item modal_group_item_btn_section">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                            id="loginbtn" type="button" onClick={verifyNumber}
                                            className="ueberschrift_btn">Weiter
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        numberVerificationStep === "1" ?
                            <div className="modal_group">
                                <div className="modal_group_item">
                                    <h4 className="teaser_h4_bold">Gib bitte den Verifizierung-Code ein:</h4>
                                </div>
                                <div className="modal_group_item">
                                    <input className="text_input" type="text" value={verificationNumber}
                                           onChange={handleVerificationPhoneNumberFieldChange}
                                           placeholder="Verifizierungs-Code" size={4} maxLength={4}/>
                                </div>
                                <div style={{flex: "1"}}></div>
                                <div className="modal_group_item modal_group_item_btn_section">
                                    <div className="btn_section">
                                        <button
                                            style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                            id="loginbtn" type="button" onClick={approvePhoneNumber}
                                            className="ueberschrift_btn">Weiter
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            numberVerificationStep === "2" ?
                                <div className="modal_group">
                                    <div className="modal_group_item">
                                        <h4 className="teaser_h4_bold">Verifizierung erfolgreich!</h4>
                                    </div>
                                    <div className="modal_group_item">
                                        <h4 className="teaser_h4_bold">Speichere bitte die Änderungen</h4>
                                    </div>
                                    <div style={{flex: "1"}}></div>
                                    <div style={{justifyContent: "space-between"}}
                                         className="modal_group_item modal_group_item_btn_section">
                                        <div className="btn_section">
                                            <button style={{
                                                "--btn_font_color": "#ffffff",
                                                "--btn_background_color": "#bd451c"
                                            }} type="button" className="ueberschrift_btn"
                                                    onClick={() => deletePhoneNumberInDB()}>Abbr.
                                            </button>
                                        </div>
                                        <div className="btn_section">
                                            <button style={{
                                                "--btn_font_color": "#ffffff",
                                                "--btn_background_color": "#f61"
                                            }} type="button" className="ueberschrift_btn"
                                                    onClick={() => updatePhoneNumberInDB()}>Speichern
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                }
            </Modal>
            { /* ↑ Pop-Up Window for phone number verification ↑ */}
            { /* ↓ Pop-Up Window for email verification ↓ */}
            <Modal isOpened={isEmailVerificationModalOpened} onClose={() => setIsEmailVerificationModalOpened(false)}>
                {
                    emailVerificationStep === "0" ?
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Gib bitte Deine neue E-Mail-Adresse ein:</h4>
                            </div>
                            <div className="modal_group_item">
                                <input className="text_input" type="text" value={newEmail}
                                       onChange={handleEmailFieldChange}
                                       placeholder="E-Mail-Adresse"/>
                            </div>
                            <div style={{flex: "1"}}></div>
                            <div className="modal_group_item modal_group_item_btn_section">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                            id="loginbtn" type="button"
                                            onClick={isTesting ? setEmailVerificationStep("1") : () => validateEmailInput()}
                                            className="ueberschrift_btn">Weiter
                                    </button>
                                </div>
                            </div>

                        </div>
                        :
                        emailVerificationStep === "1" ?
                            <div className="modal_group">
                                <div className="modal_group_item">
                                    <h4 className="teaser_h4_bold">Sie bekommen eine Email mit einem Verifizierungslink.
                                        Klicken Sie bitte auf den Link, damit Sie die Email Adresse ändern können.</h4>
                                </div>
                                <div style={{flex: "1"}}></div>
                                <div className="modal_group_item modal_group_item_btn_section">
                                    <div className="btn_section">
                                        <button
                                            style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                            id="loginbtn" type="button"
                                            onClick={() => setIsEmailVerificationModalOpened(false)}
                                            className="ueberschrift_btn">Fertig
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            emailVerificationStep === "2" ?
                                <div className="modal_group">
                                    <div className="modal_group_item">
                                        <h2 className="global_style_h2_light_text_gray">Verifizierung erfolgreich!</h2>
                                    </div>
                                    <div className="modal_group_item">
                                        <h2 className="global_style_h2_light_text_gray">Speichere bitte die
                                            Änderungen</h2>
                                    </div>
                                    <div className="btn_section">
                                        <button type="button" className="cancel_btn">Abbr.</button>
                                        <button type="button" className="submit_btn">Speichern</button>
                                    </div>
                                </div>
                                :
                                null
                }
            </Modal>
            { /* ↑ Pop-Up Window for email number verification ↑ */}
        </main>
    )
}

export default Profile;
