import React, {useState} from 'react';
import './main.css';

function Ad_main_faq () {

    const [toggleAnswer1Class, setToggleAnswer1Class] = useState(false);
    const [toggleAnswer2Class, setToggleAnswer2Class] = useState(false);
    const [toggleAnswer3Class, setToggleAnswer3Class] = useState(false);
    const [toggleAnswer4Class, setToggleAnswer4Class] = useState(false);
    const [toggleAnswer5Class, setToggleAnswer5Class] = useState(false);
    const [toggleAnswer6Class, setToggleAnswer6Class] = useState(false);
    const [toggleAnswer7Class, setToggleAnswer7Class] = useState(false);
    const [toggleAnswer8Class, setToggleAnswer8Class] = useState(false);
    const [toggleAnswer9Class, setToggleAnswer9Class] = useState(false);
    const [toggleAnswer10Class, setToggleAnswer10Class] = useState(false);
    const [toggleAnswer11Class, setToggleAnswer11Class] = useState(false);
    const [toggleAnswer12Class, setToggleAnswer12Class] = useState(false);
    const [toggleAnswer13Class, setToggleAnswer13Class] = useState(false);
    const [toggleAnswer14Class, setToggleAnswer14Class] = useState(false);
    const [toggleAnswer15Class, setToggleAnswer15Class] = useState(false);
    const [toggleAnswer16Class, setToggleAnswer16Class] = useState(false);
    const [toggleAnswer17Class, setToggleAnswer17Class] = useState(false);
    const [toggleAnswer18Class, setToggleAnswer18Class] = useState(false);
    const [toggleAnswer19Class, setToggleAnswer19Class] = useState(false);
    const [toggleAnswer20Class, setToggleAnswer20Class] = useState(false);
    const [toggleAnswer21Class, setToggleAnswer21Class] = useState(false);
    const [toggleAnswer22Class, setToggleAnswer22Class] = useState(false);
    const [toggleAnswer23Class, setToggleAnswer23Class] = useState(false);
    const [toggleAnswer24Class, setToggleAnswer24Class] = useState(false);
    const [toggleAnswer25Class, setToggleAnswer25Class] = useState(false);
    const [toggleAnswer26Class, setToggleAnswer26Class] = useState(false);
    const [toggleAnswer27Class, setToggleAnswer27Class] = useState(false);
    const [toggleAnswer28Class, setToggleAnswer28Class] = useState(false);
    const [toggleAnswer29Class, setToggleAnswer29Class] = useState(false);
    const [toggleAnswer30Class, setToggleAnswer30Class] = useState(false);
    const [toggleAnswer31Class, setToggleAnswer31Class] = useState(false);
    const [toggleAnswer32Class, setToggleAnswer32Class] = useState(false);
    const [toggleAnswer33Class, setToggleAnswer33Class] = useState(false);
    const [toggleAnswer34Class, setToggleAnswer34Class] = useState(false);
    const [toggleAnswer35Class, setToggleAnswer35Class] = useState(false);
    const [toggleAnswer36Class, setToggleAnswer36Class] = useState(false);
    const [toggleAnswer37Class, setToggleAnswer37Class] = useState(false);
    const [toggleAnswer38Class, setToggleAnswer38Class] = useState(false);
    const [toggleAnswer39Class, setToggleAnswer39Class] = useState(false);

    const redirect_to_profile = () => {
        window.location.href = "/profile";
    }
    const redirect_to_register = () => {
        window.location.href = "/register";
    }
    const redirect_to_datenschutz = () => {
        window.location.href = "/data_protection_policy";
    }
    const redirect_to_fachbuchung = () => {
        window.location.href = "/insert_box";
    }
    const redirect_to_help = () => {
        window.location.href = "/";
    }
    const redirect_to_verwahrungsbedingungen = () => {
        window.location.href = "/liability_conditions";
    }

    function toggleAnswer1(){
        setToggleAnswer1Class(!toggleAnswer1Class);
    }
    function toggleAnswer2(){
        setToggleAnswer2Class(!toggleAnswer2Class);
    }
    function toggleAnswer3(){
        setToggleAnswer3Class(!toggleAnswer3Class);
    }
    function toggleAnswer4(){
        setToggleAnswer4Class(!toggleAnswer4Class);
    }
    function toggleAnswer5(){
        setToggleAnswer5Class(!toggleAnswer5Class);
    }
    function toggleAnswer6(){
        setToggleAnswer6Class(!toggleAnswer6Class);
    }
    function toggleAnswer7(){
        setToggleAnswer7Class(!toggleAnswer7Class);
    }
    function toggleAnswer8(){
        setToggleAnswer8Class(!toggleAnswer8Class);
    }
    function toggleAnswer9(){
        setToggleAnswer9Class(!toggleAnswer9Class);
    }
    function toggleAnswer10(){
        setToggleAnswer10Class(!toggleAnswer10Class);
    }
    function toggleAnswer11(){
        setToggleAnswer11Class(!toggleAnswer11Class);
    }
    function toggleAnswer12(){
        setToggleAnswer12Class(!toggleAnswer12Class);
    }
    function toggleAnswer13(){
        setToggleAnswer13Class(!toggleAnswer13Class);
    }
    function toggleAnswer14(){
        setToggleAnswer14Class(!toggleAnswer14Class);
    }
    function toggleAnswer15(){
        setToggleAnswer15Class(!toggleAnswer15Class);
    }
    function toggleAnswer16(){
        setToggleAnswer16Class(!toggleAnswer16Class);
    }
    function toggleAnswer17(){
        setToggleAnswer17Class(!toggleAnswer17Class);
    }
    function toggleAnswer18(){
        setToggleAnswer18Class(!toggleAnswer18Class);
    }
    function toggleAnswer19(){
        setToggleAnswer19Class(!toggleAnswer19Class);
    }
    function toggleAnswer20(){
        setToggleAnswer20Class(!toggleAnswer20Class);
    }
    function toggleAnswer21(){
        setToggleAnswer21Class(!toggleAnswer21Class);
    }
    function toggleAnswer22(){
        setToggleAnswer22Class(!toggleAnswer22Class);
    }
    function toggleAnswer23(){
        setToggleAnswer23Class(!toggleAnswer23Class);
    }
    function toggleAnswer24(){
        setToggleAnswer24Class(!toggleAnswer24Class);
    }
    function toggleAnswer25(){
        setToggleAnswer25Class(!toggleAnswer25Class);
    }
    function toggleAnswer26(){
        setToggleAnswer26Class(!toggleAnswer26Class);
    }
    function toggleAnswer27(){
        setToggleAnswer27Class(!toggleAnswer27Class);
    }
    function toggleAnswer28(){
        setToggleAnswer28Class(!toggleAnswer28Class);
    }
    function toggleAnswer29(){
        setToggleAnswer29Class(!toggleAnswer29Class);
    }
    function toggleAnswer30(){
        setToggleAnswer30Class(!toggleAnswer30Class);
    }
    function toggleAnswer31(){
        setToggleAnswer31Class(!toggleAnswer31Class);
    }
    function toggleAnswer32(){
        setToggleAnswer32Class(!toggleAnswer32Class);
    }
    function toggleAnswer33(){
        setToggleAnswer33Class(!toggleAnswer33Class);
    }
    function toggleAnswer34(){
        setToggleAnswer34Class(!toggleAnswer34Class);
    }
    function toggleAnswer35(){
        setToggleAnswer35Class(!toggleAnswer35Class);
    }
    function toggleAnswer36(){
        setToggleAnswer36Class(!toggleAnswer36Class);
    }
    function toggleAnswer37(){
        setToggleAnswer37Class(!toggleAnswer37Class);
    }
    function toggleAnswer38(){
        setToggleAnswer38Class(!toggleAnswer38Class);
    }function toggleAnswer39(){
        setToggleAnswer39Class(!toggleAnswer39Class);
    }

    const [faqsBtnSwitch, setFaqsBtnSwitch] = useState(false);
    function FAQsEinblinden(){
        document.getElementById("allFAQs").style.display = "";
        setFaqsBtnSwitch(!faqsBtnSwitch);
    }
    function FAQsAusblinden(){
        document.getElementById("allFAQs").style.display = "none";
        setFaqsBtnSwitch(!faqsBtnSwitch);
    }

    return (
        <div className="content-accordion">
            <div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer1}>
                        <p className="title-question">
                            Was ist der Kiez-Locker?
                        </p>
                        <button className={toggleAnswer1Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer1}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer1Class ? "show-text" : "answer"}>
                        <p>
                            Der Kiez-Locker ist eine Idee und ein erster Test der BSR. Er funktioniert ähnlich einer Packstation, aber hier kannst Du eigene Artikel kurzzeitig verwahren oder gutes Gebrauchtes kontaktlos verschenken bzw. weitergeben.
                            <br/>
                            Somit kannst Du über den Kiez-Locker Artikel, die zu schade zum Wegwerfen sind, an andere Kiez-Bewohner:innen oder auch direkt an die NochMall weitergeben.<br/>
                            Ob das gut funktioniert, wollen wir hier testen. Es ist also erst der Anfang. Künftig wollen wir, dass Du über den Locker auch Pakete bekommen kannst, für Deinen Schuster hier Schuhe hinterlegen kannst oder Werkzeug ausleihen kannst.<br/>
                            Danke, dass Du uns bei der Entwicklung mit Deinen Erfahrungen hilfst.

                        </p>
                        <p>
                                Wir haben derzeit zwei Standorte:
                                <ul>
                                    <li><p>
                                      Tempelhof: 12103 Berlin, Ringbahnstraße 96 <br/>
                                      Der Locker steht auf BSR-Betriebsgelände, am Haupteingang in der Durchfahrt unter dem Dach
                                      und ist i.d.R. zu folgenden Zeiten erreichbar:
                                      Mo – Fr  04:00 Uhr – 20:00 Uhr,
                                      Sa, So und feiertags  05:00 Uhr – 14:00 Uhr.
                                      Außerhalb dieser Zugangszeiten ist das Tor verschlossen.
                                        </p>
                                    </li>
                                    <li><p>
                                      Adlershof: 12489 Berlin, Groß-Berliner Damm 150 <br/>
                                      Der Locker steht auf Gelände der Charlottenburger Baugenossenschaft eG und wird in gemeinsamer Kooperation zwischen „Charlotte“ und „BSR“ betrieben.
                                      Die als Kiez-Locker für o.g. Zwecke nutzbaren Fächer sind gekennzeichnet.
                                      Der Locker ist 24/7 erreichbar – auf Ruhezeiten für Anwohner:innen sollte trotzdem Rücksicht genommen werden.
                                      </p>
                                    </li>
                                    </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer39}>
                        <p className="title-question">
                            Wie nutze ich den Kiez-Locker – so funktioniert’s:
                        </p>
                        <button className={toggleAnswer39Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer39}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer39Class ? "show-text" : "answer"}>
                        <p>
                           Der Kiez-Locker ist eine Art Paketstation zur Weitergabe von gutem Gebrauchten.
                           „Vorgeschaltet“ ist ein Marktplatz, auf dem Du Gegenstände einstellen oder finden kannst.
                            <br/>
                            <ul>
                                <li>Auf <a href="www.bsr.de/kiez-locker">www.bsr.de/kiez-locker</a> anmelden und auf dem digitalen Kiez-Locker-Marktplatz gutes Gebrauchtes einstellen und/oder finden.</li>
                                <li>Jemand hat Interesse oder Du selbst hast etwas auf dem Marktplatz gefunden?
                                <br/>Gern könnt Ihr den Kiez-Locker als Übergabepunkt wählen.
                                </li>
                                <li>Dazu ein Fach buchen und Du erhältst eine Email mit Zugangscode.</li>
                                <li>Nun kannst Du zum Locker gehen und den Gegenstand einstellen.</li>
                                <li>Anschließend erhält der/die Empfänger:in per Email einen Code und kann damit den im Fach eingestellten Artikel abholen.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer38}>
                        <h3 className="title-question">
                            Wo finde ich einen Kiez-Locker?
                        </h3>
                        <button className={toggleAnswer38Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer38}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer38Class ? "show-text" : "answer"}>
                        <p>
                                Wir haben derzeit zwei Standorte:
                                <ul>
                                    <li><p>
                                      Tempelhof: 12103 Berlin, Ringbahnstraße 96 <br/>
                                      Der Locker steht auf BSR-Betriebsgelände, am Haupteingang in der Durchfahrt unter dem Dach
                                      und ist i.d.R. zu folgenden Zeiten erreichbar:
                                      Mo – Fr  04:00 Uhr – 20:00 Uhr,
                                      Sa, So und feiertags  05:00 Uhr – 14:00 Uhr.
                                      Außerhalb dieser Zugangszeiten ist das Tor verschlossen.
                                        </p>
                                    </li>
                                    <li><p>
                                      Adlershof: 12489 Berlin, Groß-Berliner Damm 150 <br/>
                                      Der Locker steht auf Gelände der Charlottenburger Baugenossenschaft eG und wird in gemeinsamer Kooperation zwischen „Charlotte“ und „BSR“ betrieben.
                                      Die als Kiez-Locker für o.g. Zwecke nutzbaren Fächer sind gekennzeichnet.
                                      Der Locker ist 24/7 erreichbar – auf Ruhezeiten für Anwohner:innen sollte trotzdem Rücksicht genommen werden.
                                      </p>
                                    </li>
                                    </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer3}>
                        <h3 className="title-question">
                            Wie buche ich ein Fach?
                        </h3>
                        <button className={toggleAnswer3Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer3}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer3Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Einloggen oder Registrieren</li>
                                <li><a className={"Links"} onClick={redirect_to_fachbuchung}>"Zur Fachbuchung"</a> anklicken</li>
                                <li>Fachgröße auswählen</li>
                                <li>Empfänger:in auswählen</li>
                                <li>Bestätigen</li>
                            </ul>
                            Wenn das Fach gebucht ist, bekommst Du eine E-Mail mit den Zugangsdaten für die Nutzung des Kiez-Lockers.
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer2}>
                        <h3 className="title-question">
                            Wie gebe ich einen Artikel ab?
                        </h3>
                        <button className={toggleAnswer2Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer2}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer2Class ? "show-text" : "answer"}>
                        <p>
                            <p>Tempelhof: </p>
                            Nach erfolgreicher Fachbuchung hast Du eine E-Mail mit einem Zahlencode und einem QR-Code für die Nutzung des Kiez-Lockers erhalten.
                            <ul>

                                <li>Code am Locker eingeben oder scannen</li>
                                <li>Artikel einlegen</li>
                            </ul>
                            Der/die Empfänger:in bekommt nach erfolgtem Einlegen eine E-Mail mit dem Abholcode.
                        </p>
                        <p>
                            <p>Adlershof:  </p>
                           Nach erfolgreicher Fachbuchung hast Du eine E-Mail mit einem Zahlencode für die Nutzung des Kiez-Lockers erhalten.
                            <ul>

                                <li>Code am Locker eingeben </li>
                                <li>Artikel einlegen    </li>
                            </ul>
                            Der/die Empfänger:in bekommt nach erfolgtem Einlegen eine E-Mail mit dem Abholcode.
                        </p>
                    </div>
                </div>

                <div style={!faqsBtnSwitch ? {marginTop:"30px"} : {marginTop:"30px", display:"none"}}>
                    <button className="load_faq_btn" onClick={!faqsBtnSwitch ? FAQsEinblinden : FAQsAusblinden}>{!faqsBtnSwitch ? "Alle FAQs einblenden" : "FAQs ausblenden"}</button>
                </div>
            </div>
            <div id="allFAQs" style={{display:"none"}}>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer4}>
                        <h3 className="title-question">
                            Wie hole ich einen Artikel ab?
                        </h3>
                        <button className={toggleAnswer4Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer4}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer4Class ? "show-text" : "answer"}>
                        <p>
                            <p>Tempelhof: </p>
                            Sobald der Artikel für Dich eingelegt worden ist, bekommst Du eine E-Mail mit dem Abholcode.
                            <ul>
                                <li>Am Locker den Zahlencode eingeben oder QR-Code scannen</li>
                                <li>Fach öffnen und Artikel entnehmen</li>
                                <li>Fach wieder schließen</li>
                            </ul>
                        </p>
                        <p>
                            <p>Adlershof: </p>
                            Sobald der Artikel für Dich eingelegt worden ist, bekommst Du eine E-Mail mit dem Abholcode.
                            <ul>
                                <li>Am Locker den Zahlencode eingeben </li>
                                <li>Fach öffnen und Artikel entnehmen</li>
                                <li>Fach wieder schließen</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer5}>
                        <h3 className="title-question">
                            Wo finde ich den QR-Code?
                        </h3>
                        <button className={toggleAnswer5Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer5}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer5Class ? "show-text" : "answer"}>
                        <p>
                            <p>Diese Funktion gilt nur für Tempelhof: </p>
                            Du erhältst bei Fachbuchung und für die Artikelabholung eine E-Mail. Dort findest Du den QR-Code.
                            Wenn Du einen Kiez-Locker Account hast, findest Du den QR-Code auch auf Deiner Profilseite unter <pre/> <a className={"Links"} onClick={redirect_to_profile}>"Meine Buchungen"</a>.
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer6}>
                        <h3 className="title-question">
                            Wie ändere ich meine Telefonnummer, E-Mail oder Nutzernamen?
                        </h3>
                        <button className={toggleAnswer6Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer6}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer6Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Gehe auf Dein Nutzerkonto</li>
                                <li><a className={"Links"} onClick={redirect_to_profile}>"Im Profil"</a> kannst Du die Angaben bearbeiten</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer7}>
                        <h3 className="title-question">
                            Was kostet mich die Nutzung?
                        </h3>
                        <button className={toggleAnswer7Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer7}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer7Class ? "show-text" : "answer"}>
                        <p>In der Testphase ist die Nutzung kostenlos.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer8}>
                        <h3 className="title-question">
                           Wie sind die „Öffnungszeiten“ des Kiez-Lockers?
                        </h3>
                        <button className={toggleAnswer8Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer8}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer8Class ? "show-text" : "answer"}>
                        <p>
                                Wir haben derzeit zwei Standorte:
                                <ul>
                                    <li><p>
                                      Tempelhof: 12103 Berlin, Ringbahnstraße 96 <br/>
                                      Der Locker steht auf BSR-Betriebsgelände, am Haupteingang in der Durchfahrt unter dem Dach
                                      und ist i.d.R. zu folgenden Zeiten erreichbar:
                                      Mo – Fr  04:00 Uhr – 20:00 Uhr,
                                      Sa, So und feiertags  05:00 Uhr – 14:00 Uhr.
                                      Außerhalb dieser Zugangszeiten ist das Tor verschlossen.
                                        </p>
                                    </li>
                                    <li><p>
                                      Adlershof: 12489 Berlin, Groß-Berliner Damm 150 <br/>
                                      Der Locker steht auf Gelände der Charlottenburger Baugenossenschaft eG und wird in gemeinsamer Kooperation zwischen „Charlotte“ und „BSR“ betrieben.
                                      Die als Kiez-Locker für o.g. Zwecke nutzbaren Fächer sind gekennzeichnet.
                                      Der Locker ist 24/7 erreichbar – auf Ruhezeiten für Anwohner:innen sollte trotzdem Rücksicht genommen werden.
                                      </p>
                                    </li>
                                    </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer9}>
                        <h3 className="title-question">
                           Wie registriere ich mich?
                        </h3>
                        <button className={toggleAnswer9Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer9}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer9Class ? "show-text" : "answer"}>
                        <p><a className={"Links"} onClick={redirect_to_register}>"Zur Registrierung"</a></p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer10}>
                        <h3 className="title-question">
                           Warum muss ich mich registrieren?
                        </h3>
                        <button className={toggleAnswer10Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer10}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer10Class ? "show-text" : "answer"}>
                       <p>
                           Wir wollen auch in Deinem Interesse, dass am Locker alles sicher ist und niemand die Fächer für illegale Zwecke missbraucht. Mit der Registrierung gehen wir sicher, dass nur tatsächliche Personen den Locker benutzen. Natürlich beachten wir dabei alle Vorschriften des Datenschutzes.<br/>
                           <a className={"Links"} onClick={redirect_to_datenschutz}>"Zur Datenschutzerklärung"</a>
                       </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer11}>
                        <h3 className="title-question">
                          Wie wähle ich das richtige Fach aus?
                        </h3>
                        <button className={toggleAnswer11Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer11}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer11Class ? "show-text" : "answer"}>
                        <p>Tempelhof:</p>
                        <p>Wähle das kleinstmögliche Fach entsprechend Deiner Artikelmaße aus. Folgende Maße hast du zur Auswahl (Höhe x Breite x Tiefe):
                            <ul>
                                <li>XS&nbsp;&emsp;&nbsp;&nbsp;9 x 48 x 61 cm (Aktenordner)</li>
                                <li>S&emsp;&nbsp;&nbsp;&nbsp;20 x 48 x 61 cm (Schuhkarton)</li>
                                <li>M&emsp;&nbsp;&nbsp;33 x 48 x 61 cm (Werkzeugkoffer)</li>
                                <li>L&emsp;&nbsp;&nbsp;&nbsp;43 x 48 x 61 cm (Kaffeemaschine)</li>
                                <li>XL&emsp;73 x 48 x 61 cm (Staubsauger)</li>
                            </ul>
                        </p>
                        <p>Adlershof:</p>
                        <p>Hier gibt es eine einheitliche Fachgröße (Höhe x Breite x Tiefe)</p>
                        <p>
                        <ul>
                            <li>XL&emsp;40 x 60 x 70 cm (Staubsauger)</li>
                        </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer12}>
                        <h3 className="title-question">
                           Ich habe ein zu kleines Fach gebucht. Kann ich das noch tauschen?
                        </h3>
                        <button className={toggleAnswer12Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer12}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer12Class ? "show-text" : "answer"}>
                        <p>
                            Gilt nur für Tempelhof: Du stornierst die Buchung und gibst eine neue auf.
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question">
                        <h3 className="title-question" onClick={toggleAnswer13}>
                           Wie öffnet sich das Fach zum Einlegen?
                        </h3>
                        <button className={toggleAnswer13Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer13}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer13Class ? "show-text" : "answer"}>
                        <p>Tempelhof:</p>
                        <p>Wähle am Locker-Terminal den Button "Einlegen" und scanne bei Aufforderung den bei der Buchung erhaltenen QR-Code oder gib alternativ den Zahlencode ein.</p>
                        <p>Adlershof:</p>
                        <p>Gib am Ziffernfeld den bei der Buchung erhaltenen Zahlencode ein.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer14}>
                        <h3 className="title-question">
                           Wie viel Zeit habe ich zum Einlegen?
                        </h3>
                        <button className={toggleAnswer14Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer14}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer14Class ? "show-text" : "answer"}>
                        <p>Tempelhof:</p>
                        <p>Den Artikel kannst Du ab dem Moment der Buchung bis zum übernächsten Tag bis Ende der Locker-Öffnungszeiten einlegen. So lange ist Deine Buchung aktiv.</p>
                        <p>Adlershof:</p>
                        <p>Den Artikel kannst Du ab dem Moment der Buchung bis zum übernächsten Tag Mitternacht einlegen. So lange ist Deine Buchung aktiv.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer15}>
                        <h3 className="title-question">
                           Wie viel Zeit habe ich zum Abholen?
                        </h3>
                        <button className={toggleAnswer15Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer15}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer15Class ? "show-text" : "answer"}>
                        <p>Nachdem der Artikel für Dich eingelegt worden ist, hast Du vier volle Tage Zeit ihn abzuholen.  In der E-Mail-Benachrichtigung zur Abholung, wird Dir die genaue Abholfrist mitgeteilt.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer16}>
                        <h3 className="title-question">
                           Kann ich Buchungen ändern oder löschen?
                        </h3>
                        <button className={toggleAnswer16Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer16}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer16Class ? "show-text" : "answer"}>
                        <p>Vor dem Einlegen des Artikels können Buchungen jederzeit unter <a className={"Links"} onClick={redirect_to_profile}>"Meine Buchungen"</a> geändert oder gelöscht werden.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer17}>
                        <h3 className="title-question">
                           Was passiert, wenn der Artikel nicht abgeholt wird?
                        </h3>
                        <button className={toggleAnswer17Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer17}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer17Class ? "show-text" : "answer"}>
                        <p>Wir bewahren den Artikel noch kurz bei uns auf und informieren den/die Empfänger:in und den/die Absender:in darüber.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer17}>
                        <h3 className="title-question">
                           Was passiert, wenn ich es nicht schaffe meinen Artikel rechtzeitig einzulegen?
                        </h3>
                        <button className={toggleAnswer17Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer17}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer17Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Storniere die bestehende Fachbuchung unter <a className={"Links"} onClick={redirect_to_profile}>"Meine Buchungen"</a>.</li>
                                <li>Buche einfach ein neues Fach im Kiez-Locker unter <a className={"Links"} onClick={redirect_to_fachbuchung}>"Fachbuchung"</a>.</li>
                            </ul>
                        </p>

                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer18}>
                        <h3 className="title-question">
                           Was kann ich machen, wenn ich es nicht schaffe meinen Artikel rechtzeitig abzuholen?
                        </h3>
                        <button className={toggleAnswer18Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer18}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer18Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Wenn der Artikel vom/von Absender:in noch nicht eingelegt worden ist, kontaktiere den/die Absender:in.</li>
                                <li>Wenn der Artikel bereits im Fach des Kiez-Lockers eingelegt ist, melde Dich bei uns: <a style={{textDecoration: "underline"}}>kiez-locker@BSR.de</a>.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer19}>
                        <h3 className="title-question">
                           Was ist,wenn ich mich umentschieden habe und einen Artikel nicht mehr möchte?
                        </h3>
                        <button className={toggleAnswer19Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer19}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer19Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Kontaktiere den/die Absender:in und teile ihr/ihm mit, dass Du Dich umentschieden hast und die Fachbuchung storniert werden soll.</li>
                                <li>Falls Du bereits einen Abholcode erhalten hast, übermittle diesen an den/die Absender/in.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer20}>
                        <h3 className="title-question">
                            Ist eine Stornierung auch möglich, nachdem ich meinen Artikel eingelegt habe?
                        </h3>
                        <button className={toggleAnswer20Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer20}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer20Class ? "show-text" : "answer"}>
                        <p>Du musst mit dem/der Empfänger:in Kontakt aufnehmen. Durch das Einlegen hast Du den Artikel an ihn/sie übergeben.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer21}>
                        <h3 className="title-question">
                            Was ist, wenn ich beim Kiez-Locker bin und mir der Artikel nicht gefällt oder er nicht der Beschreibung entspricht? Kann ich ihn dann einfach im Lockerfach lassen?
                        </h3>
                        <button className={toggleAnswer21Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer21}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer21Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Kontaktiere den/die Absender:in und kläre, ob er/sie den Artikel zurück möchte.</li>
                                <li>Oder lasse den Artikel im Fach liegen und melde dich bei uns: kiez-locker@BSR.de.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer22}>
                        <h3 className="title-question">
                            Wie ist es mit dem Datenschutz?
                        </h3>
                        <button className={toggleAnswer22Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer22}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer22Class ? "show-text" : "answer"}>
                        <p> Wir halten alle Datenschutzregeln ein. Weitere Infos findest Du <a className={"Links"} onClick={redirect_to_datenschutz}>hier</a>.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer23}>
                        <h3 className="title-question">
                            Wie sicher sind die eingelegten Artikel vor Diebstahl geschützt?
                        </h3>
                        <button className={toggleAnswer23Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer23}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer23Class ? "show-text" : "answer"}>
                        <p>Nur der/die Absender:in und der/die zugewiesene Empfänger:in haben die Zugangscodes.<br/>
                            Der Locker steht im Sichtbereich der Pförtner.
                            Letztlich entscheidest aber Du, was Du dem Locker anvertraust.<br/>
                            <a className={"Links"} onClick={redirect_to_verwahrungsbedingungen}>"Zu den Verwahrungsbedingungen"</a>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer24}>
                        <h3 className="title-question">
                            Bekomme ich einen Hinweis zum    Status Quo des Vorganges? (Bei Abholung, Nichtabholung, Nichtgefallen...)
                        </h3>
                        <button className={toggleAnswer24Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer24}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer24Class ? "show-text" : "answer"}>
                        <p> Den aktuellen Status Deiner Buchungen und Abholungen kannst Du unter <a className={"Links"} onClick={redirect_to_profile}>"Meine Buchungen"</a> einsehen.
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer25}>
                        <h3 className="title-question">
                            Muss auch der/die Empfänger:in einen Kiez-Locker Account haben?
                        </h3>
                        <button className={toggleAnswer25Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer25}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer25Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Nein. Nur der/die Absender:in, der/die den Artikel in den Locker einlegt, muss einen Kiez-Locker Account haben.</li>
                                <li>Der QR-Code zum Abholen des Artikels wird dann dem/der Absender:in zugesendet.</li>
                                <li>Der/die Absender:in muss den Abholcode dann einfach persönlich an den/die Empfänger:in weiterleiten.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer26}>
                        <h3 className="title-question">
                            Was ist, wenn ich mich beim Nutzernamen für die Fachbuchung verschrieben habe?
                        </h3>
                        <button className={toggleAnswer26Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer26}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer26Class ? "show-text" : "answer"}>
                        <p>Sollte etwas schief gegangen sein, kontaktiere uns unter: <a style={{textDecoration:"underline"}}>kiez-locker@BSR.de</a>.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer27}>
                        <h3 className="title-question">
                            Woher weiß ich, dass mein Artikel abholbereit ist?
                        </h3>
                        <button className={toggleAnswer27Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer27}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer27Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Wenn Du einen Kiez-Locker Account hast, ändert sich der Status unter <a className={"Links"} onClick={redirect_to_profile}>"Meine Buchungen"</a> und Du erhältst eine E-Mail, sobald der Artikel abholbereit ist.</li>
                                <li>Wenn Du keinen Kiez-Locker Account hast, muss Dir der/die Absender:in Bescheid geben.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer28}>
                        <h3 className="title-question">
                            Bekomme ich eine Erinnerung, wann meine Einlege- bzw. Abholfrist endet?
                        </h3>
                        <button className={toggleAnswer28Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer28}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer28Class ? "show-text" : "answer"}>
                        <p> Ja, Du erhältst eine Erinnerung per E-Mail.</p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer29}>
                        <h3 className="title-question">
                            Wie funktioniert der Kiez-Locker Marktplatz?
                        </h3>
                        <button className={toggleAnswer29Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer29}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer29Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Für den Test des Kiez-Lockers haben wir einen Marktplatz in Klein nachgebaut.</li>
                                <li>Hier kann man stöbern und finden oder selbst gutes Gebrauchtes weitergeben.</li>
                                <li>Anmelden, Fotografieren, kurzen Text schreiben, Hochladen und das Inserat ist fertig.</li>
                                <li>Als Übergabeort ganz einfach einen der Kiez-Locker nutzen.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer30}>
                        <h3 className="title-question">
                            Warum gibt es den Kiez-Locker Marktplatz, obwohl es den Tausch- und Verschenkmarkt der BSR gibt?
                        </h3>
                        <button className={toggleAnswer30Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer30}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer30Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Wir möchten mit dem Kiez-Locker Marktplatz u.a. das Weitergeben von gutem Gebrauchten in benachbarten Kiezen fördern. Der Kiez-Locker dient als Übergabestation für den Kiez-Locker Marktplatz.</li>
                                <li>Der Kiez-Locker kann aber auch als Übergabestation über den Tausch- und Verschenkmarkt genutzt werden.</li>
                                <li>Später wollen wir beides miteinander verknüpfen, für einen ersten Test haben wir aber erst mal den „großen“ Marktplatz in Klein nachgebaut. </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer31}>
                        <h3 className="title-question">
                            Wie gebe ich ein Inserat auf?
                        </h3>
                        <button className={toggleAnswer31Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer31}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer31Class ? "show-text" : "answer"}>
                        <p>
                            Anmelden, Fotografieren, kurzen Text schreiben, Hochladen, fertig.
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer32}>
                        <h3 className="title-question">
                            Mir gefällt ein Inserat. Was mache ich dann?
                        </h3>
                        <button className={toggleAnswer32Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer32}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer32Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Anmelden und auf Inserat antworten.</li>
                                <li>Dann mit Inserent:in vereinbaren, wann und wie die Übergabe erfolgt.</li>
                                <li>Ganz einfach geht es über die Kiez-Locker.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer33}>
                        <h3 className="title-question">
                            Wie reserviere ich einen Artikel?
                        </h3>
                        <button className={toggleAnswer33Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer33}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer33Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Kontaktiere den/die Inserent:in über das Nachrichtenfeld in den Artikeldetails und vereinbare eine Reservierung.</li>
                                <li>Der/die Inserent:in reserviert den Artikel dann für Dich.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer34}>
                        <h3 className="title-question">
                            Wie nutzen wir den Kiez-Locker als Übergabeort?
                        </h3>
                        <button className={toggleAnswer34Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer34}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer34Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Der/die Absender:in meldet sich an und bucht das passende Fach unter <a className={"Links"} onClick={redirect_to_fachbuchung}>"Fachbuchung"</a>.</li>
                                <li>Der/die Empfänger:in erhält eine E-Mail mit den Daten, um das Fach zu öffnen.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer35}>
                        <h3 className="title-question">
                            Was ist, wenn ich einen Artikel doch nicht mehr möchte - obwohl ich ihn reserviert habe?
                        </h3>
                        <button className={toggleAnswer35Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer35}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer35Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Reservierung aufheben.</li>
                                <li>Solltet ihr bereits eine Übergabe verabredet haben, kontaktiere den/die Inserent:in.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer36}>
                        <h3 className="title-question">
                            Muss ich für die Nutzung des Kiez-Locker Marktplatzes einen Kiez-Locker Account haben?
                        </h3>
                        <button className={toggleAnswer36Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer36}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer36Class ? "show-text" : "answer"}>
                        <p>
                            <ul>
                                <li>Du kannst im Marktplatz stöbern, ohne Dich anzumelden.</li>
                                <li>Wenn Du selbst ein Inserat aufgeben oder einen Artikel reservieren möchtest, musst Du Dich über den Kiez-Locker Account <a className={"Links"} onClick={redirect_to_register}>registrieren.</a></li>
                            </ul>

                        </p>
                    </div>
                </div>
                <div className="question-answer">
                    <div className="question" onClick={toggleAnswer37}>
                        <h3 className="title-question">
                            Der Kiez-Locker funktioniert nicht – technische Störung. Was mache ich?
                        </h3>
                        <button className={toggleAnswer37Class ? "question-btn show-text" : "question-btn"} onClick={toggleAnswer37}>
                                <span className="up-icon">
                                    <i className="fas fa-chevron-up"></i>
                                </span>
                            <span className="down-icon">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                        </button>
                    </div>
                    <div className={toggleAnswer37Class ? "show-text" : "answer"}>
                        <p>
                            Kontaktiere uns über die <a className={"Links"} onClick={redirect_to_help}>kiez-locker@BSR.de</a> oder telefonisch unter <a className={"Links"} onClick={redirect_to_help}>030 7592-4900</a><br/>
                            (Service-Center der BSR, montags bis freitags von 7.00 bis 19.00 Uhr, samstags von 8.00 bis 14.00 Uhr). Nach Feierabend erreichst Du eine:n Kolleg:in unseres Projekt-Test-Teams unter 030 7592-1210.
                        </p>
                    </div>
                </div>
                <div style={{marginTop:"30px"}}>
                    <button className="load_faq_btn" onClick={!faqsBtnSwitch ? FAQsEinblinden : FAQsAusblinden}>{!faqsBtnSwitch ? "Alle FAQs einblenden" : "FAQs ausblenden"}</button>
                </div>
            </div>
        </div>
    )
}

export default Ad_main_faq;