import React, {useEffect, useState} from 'react';
import './market.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import market_logo from "../../static/market/Marktplatz_orange.svg"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Post from "./post";
import {isTesting} from "../../App";
import RestAPI from "../../RestAPI";
import Loading_spinner from "../common/loading_spinner";
import { Helmet } from 'react-helmet';
import bsr_logo from '../../static/icons/BSR_RGB.svg'

function Start_page({switchToIndex, isAdminVar, isLoggedInVar}) {
    const my_inserate = [
        {"id": 12345678901234567890, "date": "03.10.2022","standort":"Adlershof", "views": "25", "title": "Tassen", "type": "Tauschen"},
        {"id": 2, "date": "03.10.2022","standort":"Tempelhof", "views": "12", "title": "Schreibtisch", "type": "Weitergeben"},
        {"id": 3, "date": "03.10.2022","standort":"Adlershof", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 4, "date": "03.10.2022","standort":"Standort (Alle) ", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 5, "date": "03.10.2022","standort":"Standort (Alle) ", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 6, "date": "03.10.2022","standort":"Tempelhof", "views": "6", "title": "Bücher", "type": "Gesucht"},
        {"id": 7, "date": "03.10.2022","standort":"Standort (Alle) ", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 8, "date": "03.10.2022","standort":"Tempelhof", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 9, "date": "03.10.2022","standort":"Tempelhof", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 10, "date": "03.10.2022","standort":"Standort (Alle) ", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 11, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 12, "date": "03.10.2022","standort":"Tempelhof", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 13, "date": "03.10.2022","standort":"Standort (Alle) ", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 14, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 15, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 16, "date": "03.10.2022","standort":"Tempelhof", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 17, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 18, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 19, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 20, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 21, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 22, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 23, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 24, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
        {"id": 25, "date": "03.10.2022", "views": "6", "title": "Bücher Bücher Bücher Bücher Bücher", "type": "Gesucht"},
    ];
    const [displayCount, setDisplayCount] = useState(10);
    const loadMore = () => {
        setDisplayCount(prevCount => prevCount + 10); // add 10 more posts
    };
    const testDisplayedPosts = my_inserate.slice(0, displayCount);
    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    const categoryOptions = [
        'Kategorie','Suchen', 'Weitergeben'
    ];

    useEffect(()=>{
        getPosts()
    },[])

    const RubrikOptions = [
        'Rubrik (Alle)', 'Möbel/Hausrat', 'Haushaltsgeräte', 'Computer/HiFi', 'Telefon/Fax', 'Bücher', 'Hobby',
        'Für Kinder', 'Kleidung/Schuhe', 'Garten/Bauen', 'Materialreste', 'Sonstiges'
    ];
    const StandortOptions = [
        'Standort (Alle)', 'Tempelhof', 'Adlershof'
    ];
    const defaultOption = categoryOptions[0];
    const rubrikDefaultOption = RubrikOptions[0];
    const StandortDefaultOption = StandortOptions[0];
    const [postCategory, setPostCategory] = useState(defaultOption);
    const [searchValue, setSearchValue] = useState("");
    const [category, setCategory] = useState(rubrikDefaultOption);
    const [standort, setStandort] = useState(StandortDefaultOption);
    const[isSearchFilterActivated, setIsSearchFilterActivated] = useState(false)
    const[isResultEmpty, setIsResultEmpty] = useState(false)
    const [posts, setPosts] = useState([]);
    const [allPosts, setAllPosts] = useState([]);
    const displayedPosts = posts.slice(0, displayCount);
    const redirect_to_login = () => {
        window.location.href = "/login";
    }
    const handleSearchInput = (event) => {
        setSearchValue(event.target.value)
    };
    function handleSearchClick(){
        filterBySearch(searchValue)
        setIsSearchFilterActivated(true)
    }
    useEffect(()=>{
        if(searchValue === ""){
            setIsSearchFilterActivated(false)
        }
    }, [searchValue])

    useEffect(()=>{
        filter(isSearchFilterActivated, category, postCategory, standort)
    }, [allPosts])

    useEffect(()=>{
        if (posts.length === 0){
            setIsResultEmpty(true)
        }
        else {
            setIsResultEmpty(false)
        }
    }, [posts])

    useEffect(()=> {
        getPosts()
    }, [isSearchFilterActivated, category, postCategory, standort])

    function filterBySearch(searchValue){
        let filterResult = allPosts.filter(posts => String(posts.title).toLowerCase().includes(searchValue.toLowerCase()) ||
            String(posts.description).toLowerCase().includes(searchValue.toLowerCase()))

        setPosts(filterResult)
    }

function filter(isSearchFilterActivated, category, postCategory, standort) {
    let type = postCategory === categoryOptions[1] ? "S" : "V";

    let filterResult = allPosts.filter(post => {
        let isCategoryMatch = (category === rubrikDefaultOption || post.category === category) &&
                              (postCategory === defaultOption || post.type === type) &&
                              (standort === StandortDefaultOption || post.standort === standort );

        let isSearchMatch = !isSearchFilterActivated ||
                            post.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                            post.description.toLowerCase().includes(searchValue.toLowerCase());

        return isCategoryMatch && isSearchMatch;
    });

    setPosts(filterResult);
}


    function switchToAddPost(){
        switchToIndex("Add_post");
    }
    function getPosts() {
        setRestQueryIsLoading(true);
       RestAPI("/api/get_posts", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                setRestQueryIsLoading(false);
                if (response["status"] === 200) {
                    setPosts(response["posts"])
                    setAllPosts(response["posts"])

                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Helmet>
            <title>Die BSR Kiez-Locker</title>
            <meta name="description" content="Die BSR Kiez-Locker Verschenken, Tauschen, Weitergeben." />
            <meta name="keywords" content="BSR, Kiez-Locker, Verschenken, Tauschen, Weitergeben" />
            <meta property="og:image" content={bsr_logo} />
            </Helmet>
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="market_page_content">
                <div className="market_page_content_description">
                    <div className="market_page_content_description_item">
                        <img src={market_logo}/>
                    </div>
                    <div className="market_page_content_description_item">
                        <h2 className="untertitel_h2">{"Kiez-Locker Marktplatz"}</h2>
                    </div>
                    <div className="market_page_content_description_item">
                        <h4 className="teaser_h4">Suchen und Weitergeben von gutem Gebrauchten im Kiez</h4>
                    </div>
                    <div className="market_page_content_description_item">
                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} className={"ueberschrift_btn"} onClick={isLoggedInVar ? switchToAddPost : redirect_to_login}>{isLoggedInVar ? "Inserat hinzufügen" : "Jetzt Einloggen"}</button>
                    </div>
                </div>
                <div className="market_page_content_posts_section">
                    <div className="market_page_content_posts_section_inputs">
                        <div className="market_page_content_posts_section_inputs_item">
                            <input placeholder="Suchbegriff eingeben" onChange={handleSearchInput} value={searchValue}/>
                            {
                                (searchValue !== "") &&
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} className={"ueberschrift_btn"} onClick={handleSearchClick} >{"Suchen"}</button>

                            }
                        </div>
                        <div className="market_page_content_posts_section_inputs_item">
                            <div className="market_dropdown_lists">
                                <Dropdown className="market_dropdown" arrowClassName="add_post_dropdown_arrow" options={categoryOptions} onChange={(e) => setPostCategory(e.value)} value={postCategory ? categoryOptions[categoryOptions.indexOf(postCategory)] : defaultOption}  placeholder="Kategorien" />
                                <Dropdown className="market_dropdown" arrowClassName="add_post_dropdown_arrow" options={RubrikOptions} onChange={(e) => setCategory(e.value)} value={category ? RubrikOptions[RubrikOptions.indexOf(category)] : rubrikDefaultOption} placeholder="Rubrik" />
                                <Dropdown className="market_dropdown" arrowClassName="add_post_dropdown_arrow" options={StandortOptions} onChange={(e) => setStandort(e.value)} value={standort ? StandortOptions[StandortOptions.indexOf(standort)] : StandortDefaultOption} placeholder="Standort" />


                            </div>
                        </div>
                    </div>
                    <div className="market_page_content_posts_section_components">
                        <div className="market_page_content_posts_section_components_title">
                            {
                                !isResultEmpty ? <h2 className="fliesstext_p">Neueste Beiträge</h2> :
                                    <h2 className="fliesstext_p">Es wurde nichts gefunden!</h2>}
                        </div>
                        <div className="market_page_content_posts_section_components_posts">
                            {
                                isTesting ?
                                testDisplayedPosts.map(function (d) {
                                return (<Post key={d.id} standort={d.standort} date={d.date} views={d.views} title={d.title}
                                                type={d.type} path={d.image} id={d.id}/>)
                                })
                                    :
                                displayedPosts.map(function (d) {
                                return (<Post key={d.id} standort={d.standort} date={d.date} views={d.views} title={d.title}
                                                type={d.type} path={d.image} id={d.id}/>)
                                })
                            }
                        </div>
                        {(displayedPosts.length > 0 || isTesting) && (
                                <div className={"market_page_content_posts_section_components_btn"}>
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} className={"ueberschrift_btn"} onClick={loadMore} >Mehr laden</button>
                                </div>
                                )}
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}
export default Start_page;
