import React, {useEffect, useState} from 'react';
import '../../GlobalStyle.css'
import 'react-dropdown/style.css';
import Start_page from "./start_page";
import Locker_load from "./locker_load";
import {isAdmin, isLogin} from "../login";
import Release_posts from "./release_posts";

function Admin_page() {
    const [index, setIndex] = useState("Start_page");
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
    await data()
    }, []);


    const switchToIndex = (index) => {
        setIndex(index);
    };

    switch (index) {
        case "Start_page":
            return <Start_page switchToIndex={switchToIndex} isAdminVar={isAdminVar} isLoggedInVar={isLoggedInVar}/>;
        case "Locker_load":
            return <Locker_load switchToIndex={switchToIndex} isAdminVar={isAdminVar} isLoggedInVar={isLoggedInVar}/>;
        case "Release_posts":
            return <Release_posts switchToIndex={switchToIndex} isAdminVar={isAdminVar} isLoggedInVar={isLoggedInVar}/>;
        default:
            return <Start_page/>;
    }
}
export default Admin_page;
