import React, {useEffect, useState} from 'react';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import Box_Kontur_orange from "../../static/insert_box/Buchungen_orange.svg";
import {isTesting, tinyScreenWidth} from "../../App";
import QRCode from "qrcode";
import RestAPI from "../../RestAPI";
import {useParams} from "react-router-dom";
import Modal from "../modal";
import {isAdmin, isLogin} from "../login";
import Loading_spinner from "../common/loading_spinner";

function Details_page() {
    let {q} = useParams();
    let {l} = useParams();
    const trackingNumber = q;
    const location = l;

    const [imageUrl, setImageUrl] =useState('');
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [terminalUserName, setTerminalUserName] = useState("532625");
    const [terminalPassword, setTerminalPassword] = useState("5262");
    const [senderUserName, setSenderUserName] = useState("senderUserName");
    const [recipientUserName, setRecipientUserName] = useState("recipientUserName");
    const [bookingTitle, setBookingTitle] = useState("bookingTitle");
    const [bookingNumber, setBookingNumber] = useState("bookingNumber");
    const [pin, setPin] = useState("bookingPin");
    const [parcelSize, setParcelSize] = useState("parcelSize");
    const [dateForReservation, setDateForReservation] = useState("");
    const [dateForEmpty, setDateForEmpty] = useState("");
    const [useCase, setUseCase] = useState("In Demmand");
    const [isCancelBookingModalOpened, setIsCancelBookingModalOpened] = useState(false);
    const [isSender, setIsSender] = useState(true);
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    useEffect(() => {
        getBookingInfos()
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        //setErrors(validation(values, "Enter_login_data"));
        setDataIsCorrect(true);
    }
    function getBookingInfos(){
        RestAPI("/api/get_booking_details/"+location+"/"+q, "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setSenderUserName(response["senderUserName" ]);
                    setRecipientUserName(response["recipientUserName"]);
                    setBookingTitle(response["bookingTitle"]);
                    setParcelSize(response["parcelSize"]);
                    setDateForReservation(response["dateForReservation"]);
                    setDateForEmpty(response["dateForEmpty"]);
                    setUseCase(response["booking_status"]);
                    setBookingNumber(response["tracking_number"])
                    setPin(response["pin"])
                    response["isSender"] === 1 ? setIsSender(true) : setIsSender(false)
                } else {
                    window.location.href = "/start_seite"
                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }

    function cancelBooking(){
        let data = {tracking_number : trackingNumber, location : location}
        setRestQueryIsLoading(true);
        RestAPI("/api/cancel_booking", "POST", data)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                   window.location.href = "/profile"
                } else {
                    //TODO ERROR
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }
    const qr = async () =>{

        try {
            const response = await QRCode.toDataURL(isTesting ? "trackingNumber" : useCase === "In Demmand" ? q : pin);
            setImageUrl(response);
        }catch (error) {
            console.log(error);
        }
    }

    const redirect_to_profile = () => {
        window.location.href = "/profile";
    }
    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <div className="info_box insert_box_confirmation">
                <div className="insert_box_content_horizontal">
                    <div style={{display:"none"}} className="upper_part"></div>
                    <div className="main_part">
                        <div className="main_part_item">
                            <h3 className="zwischenueberschrift_h3">Buchungsinformationen</h3>
                        </div>
                        <div style={{textAlign:"center", paddingLeft:"15px", paddingRight:"15px"}} className="main_part_item">
                            <h4 className="teaser_h4">
                                {/*Absender Fälle*/}
                                {
                                    isSender && useCase === "In Demmand" ?
                                        "Das Fach wurde noch nicht befüllt." : ""
                                }
                                {
                                    isSender && useCase === "Deposited" ?
                                        "Das Fach wurde noch nicht geleert." : ""
                                }
                                {
                                    isSender && useCase === "Picked up" ?
                                        "Das Fach wurde geleert." : ""
                                }
                                {/*Empänger Fälle*/}
                                {
                                    !isSender && useCase === "In Demmand" ?
                                        "Das Fach wurde noch nicht befüllt." : ""
                                }
                                {
                                    !isSender && useCase === "ready" ?
                                        "Der Artikel liegt für Dich bereit." : ""
                                }
                            </h4>
                            {
                                isSender && useCase === "Exceeded" ?
                                <h4 style={{color:"#bd451c"}} className="teaser_h4">Das Fach wurde noch nicht geleert.<br/>Die Frist wurde überschritten.</h4> : null
                            }
                            {
                                !isSender && useCase === "Exceeded" ?
                                <h4 style={{color:"#bd451c"}} className="teaser_h4">Das Fach wurde nicht geleert.<br/>Die Frist wurde überschritten. Kontaktiere uns!</h4> : null
                            }

                        </div>
                        <div style={{textAlign:"center"}} className="main_part_item">
                            <img style={{width:"75px"}} src={Box_Kontur_orange}/>
                            <h2 style={{ color:"#f61", fontWeight:"bold"}} className="fliesstext_p">Fach {isTesting ? "XL" : ""}</h2>
                            <h2 style={{ color:"#f61"}} className="fliesstext_p">{isTesting ? "20.20.2000" : localStorage.getItem("currentDate")}</h2>
                        </div>
                                <div className="insert_box_content_right_item insert_box_content_right_item_booking_data">
                                    <div className="booking_data booking_data_details">
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Absender:in:</p>
                                            <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "Nutzername" : senderUserName}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Empfänger:in:</p>
                                            <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "Nutzername" : recipientUserName}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">{location == "AH" ? "Einlege-Pin:" : location == "TH" ? "Buchungsnr.:" : null}</p>
                                            <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "12345678912345678912" : bookingNumber}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Abhol-Pin:</p>
                                            <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "123456" : isSender || useCase === "Deposited" ? pin : "Wird noch bekannt gegeben"}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Artikelname:</p>
                                            <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "12345678912345678912" : bookingTitle}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Fachgröße:</p>
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "XL" : parcelSize}</p>
                                        </div>
                                        {
                                            isAdminVar ?
                                                <div className="booking_data_item">
                                                    <p className="fliesstext_p">Standort:</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">
                                                        {
                                                            location == "AH" ? "Adlershof" :
                                                            location == "TH" ? "Tempelhof" :
                                                            null
                                                        }
                                                    </p>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="booking_data booking_data_details">
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Einlegen bis:</p>
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.split(" ")[0]}</p>
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.split(" ")[1] + " Uhr"}</p>
                                        </div>
                                        <div className="booking_data_item">
                                            <p className="fliesstext_p">Abholen bis:</p>
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.split(" ")[0]}</p>
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.split(" ")[1] + " Uhr"}</p>
                                        </div>
                                        {
                                            location == "TH" ?
                                                <div className="booking_data_item">
                                                    <p className="fliesstext_p">{tinyScreenWidth.matches ? "Öffnungs - zeiten" : "Öffnungszeiten"}:</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">Mo-Fr 04:00-20:00 Uhr</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">Sa, So und feiertags</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">05:00-14:00 Uhr</p>
                                                </div>
                                                :
                                            location == "AH" ?
                                                <div className="booking_data_item">
                                                    <p className="fliesstext_p">{tinyScreenWidth.matches ? "Öffnungs - zeiten" : "Öffnungszeiten"}:</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">24/7 erreichbar</p>
                                                </div>
                                                :
                                            null
                                        }
                                        {
                                            location == "TH" ?
                                                <div className="booking_data_item">
                                                    <p className="fliesstext_p">User:</p>
                                                    <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{terminalUserName}</p>
                                                </div>
                                                :
                                            null
                                        }
                                        {
                                            location == "TH" ?
                                                <div className="booking_data_item">
                                                    <p className="fliesstext_p">Passwort:</p>
                                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{terminalPassword}</p>
                                                </div>
                                                :
                                            null
                                        }
                                    </div>
                                </div>
                        <div className="main_part_item">

                                {
                                    !imageUrl ?
                                        <div className="main_part_item_qr_code_field">
                                            { isSender && useCase === "In Demmand" || useCase === "Deposited" ?
                                            <div className="details_btn_field_one_btn">
                                                <button style={tinyScreenWidth.matches ? {fontSize:"14px", padding:"0px 5px", "--btn_font_color":"#ffffff", "--btn_background_color":"#f61"} : {"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}}  className="ueberschrift_btn" onClick={() =>qr()}>QR-Code</button>
                                            </div> : null}
                                            {/*Storno Btn nur für absender*/}
                                            {
                                            isSender && useCase === "In Demmand" ?
                                            <div className="details_btn_field_one_btn">
                                                <button style={tinyScreenWidth.matches ? {fontSize:"14px", padding:"0px 5px", "--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c"} : {"--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c", padding: "0px 5px"}} className="ueberschrift_btn" onClick={() =>setIsCancelBookingModalOpened(true)}>Stornieren</button>
                                            </div>
                                            :
                                                null
                                            }
                                            <div className="details_btn_field_one_btn">
                                                <button style={tinyScreenWidth.matches ? {fontSize:"14px", padding:"0px 5px", "--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"} : {"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} className="ueberschrift_btn" onClick={redirect_to_profile}>Zurück</button>
                                            </div>
                                        </div>
                                    :
                                        <div className="main_part_item_qr_icon_field">
                                            <img className="main_part_item_qr_code_field_qr_code" src={imageUrl} alt="new"/>
                                            <a className="my_span" role="button" href={imageUrl} download="QR code">Herunterladen</a>

                                            <div style={{width:"100%", display:"flex", justifyContent:"center",paddingTop:"20px"}}>
                                                <div style={{marginLeft:""}} className="details_btn_field_one_btn">
                                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} className="ueberschrift_btn" onClick={redirect_to_profile}>Zurück</button>
                                                </div>
                                                <div style={{flex:"1"}}></div>
                                                {
                                                isSender && useCase === "In Demmand" ?
                                                <div  className="details_btn_field_one_btn">
                                                    <button style={{"--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c", marginLeft:"-115%"}} className="ueberschrift_btn" onClick={() =>setIsCancelBookingModalOpened(true)}>Stornieren</button>
                                                </div>
                                                : null}
                                            </div>
                                        </div>
                                }

                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
            <Modal isOpened={isCancelBookingModalOpened} onClose={() => setIsCancelBookingModalOpened(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Willst Du die Buchung endgültig stornieren?</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"space-between", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#bd451c"}} id="loginbtn" type="button" onClick={() =>setIsCancelBookingModalOpened(false)} className="ueberschrift_btn">Abbrechen</button>
                                </div>
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#bd451c", "--btn_background_color":"#ffffff", border: "2px solid #bd451c"}} id="loginbtn" type="button" onClick={() => cancelBooking()} className="ueberschrift_btn">Stornieren</button>
                                </div>

                            </div>
                        </div>
            </Modal>
        </main>
    )
}
export default Details_page;
