import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import qr_code_icon from "../../static/insert_box/generate_qr_icon.png";
import Box_Kontur_orange from "../../static/insert_box/Buchungen_orange.svg";
import {isTesting, screenWidth, tinyScreenWidth} from "../../App";
import QRCode from "qrcode";
import {currentDate, dateForEmpty, dateForReservation, pin, trackingNumber} from "./insert_box_selection";
import {objectSize} from "./insert_box_package_dimensions";
import {location} from "./insert_box_location";

function Insert_box_confirmation({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [imageUrl, setImageUrl] =useState('');
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        //setErrors(validation(values, "Enter_login_data"));
        setDataIsCorrect(true);
    }
    useEffect(() =>{
        if (/*Object.keys(errors).length === 0 &&*/ dataIsCorrect){
            isTesting ? switchToIndex("Insert_box_package_dimensions") : switchToIndex("Insert_box_package_dimensions");
        }
    }/*, [errors]*/);

    const qr = async () =>{

        try {
            const response = await QRCode.toDataURL(isTesting ? "1234567489" : trackingNumber);
            setImageUrl(response.toString());
        }catch (error) {
            console.log(error);
        }
    }
    const redirect_to_startSite = () => {
        window.location.href = "/start_seite";
    }
    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="info_box insert_box_confirmation">
                <div className="insert_box_content_horizontal">
                    <div style={{display:"none"}} className="upper_part"></div>
                    <div className="main_part">
                        <div className="main_part_item">
                            <h3 className="zwischenueberschrift_h3">Buchung erfolgreich!</h3>
                        </div>
                        <div style={{textAlign:"center"}/*{textAlign:"center", paddingLeft:"15px", paddingRight:"15px"}*/} className="main_part_item">
                            <h4 className="teaser_h4">Du erhältst in Kürze eine Buchungsbestätigung per E-Mail.</h4>
                        </div>
                        <div style={{textAlign:"center"}} className="main_part_item">
                            <img style={{width:"75px"}} src={Box_Kontur_orange}/>
                            <h2 style={{ color:"#f61", fontWeight:"bold"}} className="fliesstext_p">Fach {isTesting ? "XL" : objectSize}</h2>
                            <h2 style={{ color:"#f61"}} className="fliesstext_p">{isTesting ? "20.20.2000" : localStorage.getItem("currentDate")}</h2>
                        </div>
                        <div className="insert_box_content_right_item insert_box_content_right_item_booking_data">
                            <div className="booking_data">
                                {
                                    location == "TH" &&
                                    <div className="booking_data_item">
                                        <p className="fliesstext_p">Buchungsnr.:</p>
                                        <p style={{color:"#a0a0a0", wordWrap:"break-word"}} className="fliesstext_p">{isTesting ? "**"+("12345").slice(-5) : "**" + trackingNumber.slice(-5)}</p>
                                    </div>
                                }

                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Fachgröße:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "XL" : objectSize}</p>
                                    {
                                        (isTesting && isAdminVar) &&
                                        <p className="fliesstext_p"><br/></p>
                                    }
                                </div>
                                {
                                    (isTesting || isAdminVar) &&
                                    <div className="booking_data_item">
                                        <p className="fliesstext_p">Standort:</p>
                                        {
                                            (isTesting || isAdminVar) &&
                                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{location === "TH" ? "Kiez-Locker Tempelhof" : "Charlotte-Locker Adlershof"}</p>
                                        }

                                    </div>
                                }

                            </div>
                            <div className="booking_data">
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Einlegen bis:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.getDate().toString() + "." + (dateForReservation.getMonth()+1).toString() + "." + dateForReservation.getFullYear().toString()}</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.getHours().toString() + ":00 Uhr"}</p>
                                </div>
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Abholen bis:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.getDate().toString() + "." + (dateForEmpty.getMonth()+1).toString() + "." + dateForEmpty.getFullYear().toString()}</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.getHours().toString() + ":00 Uhr"}</p>
                                </div>
                                {
                                    (isTesting || isAdminVar) &&
                                    <div className="booking_data_item">
                                    <p className="fliesstext_p">Einlege-Pin:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{pin}</p>
                                </div>
                                }
                            </div>
                        </div>
                        {
                            isAdminVar ?
                            (location !== "AH") &&
                            <div className="main_part_item">
                                    {
                                        !imageUrl ?

                                            <div className="main_part_item_qr_code_field">
                                                <div className="btn_field_one_btn">
                                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} className="ueberschrift_btn" onClick={() =>qr()} src={qr_code_icon}>QR-Code</button>
                                                </div>

                                            </div>
                                        :
                                            <div className="main_part_item_qr_icon_field">
                                                <img className="main_part_item_qr_code_field_qr_code" src={imageUrl} alt="new"/>
                                                <a className="my_span" role="button" href={imageUrl} download="QR code">Herunterladen</a>
                                            </div>
                                    }
                            </div>
                                :
                                <div className="main_part_item">
                                        {
                                            !imageUrl ?

                                                <div className="main_part_item_qr_code_field">
                                                    <div className="btn_field_one_btn">
                                                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} className="ueberschrift_btn" onClick={() =>qr()} src={qr_code_icon}>QR-Code</button>
                                                    </div>

                                                </div>
                                            :
                                                <div className="main_part_item_qr_icon_field">
                                                    <img className="main_part_item_qr_code_field_qr_code" src={imageUrl} alt="new"/>
                                                    <a className="my_span" role="button" href={imageUrl} download="QR code">Herunterladen</a>
                                                </div>
                                        }
                                </div>
                        }

                    </div>
                    <div className={"insert_box_confirmation_backToStartPageBtnField"}>
                        <button onClick={redirect_to_startSite} className={"ueberschrift_btn"}>{tinyScreenWidth.matches || screenWidth.matches ? "Startseite" : "Zur Startseite"}</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}
export default Insert_box_confirmation;
