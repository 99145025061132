

const validation = (values, index, isHidden, checkBox1, checkBox2, checkBox3, checkBox4, checkBox5) => {
    let errors={};
    if (index === "Insert_box_location") {
        if (!checkBox1 && !checkBox2) {
            errors.checked = "Du musst eine Option auswählen!";
        }
    }

    if (index === "Insert_box_recipient") {
        if (!checkBox1 && !checkBox2 && !checkBox4) {
            errors.checked = "Du musst eine Option auswählen!";
        }
        if (!isHidden) {
            if (!values.userName){
                 errors.userData = "Username ist erforderlich!"
            }
            if (!/^([a-zäöüÄÖÜß]{4})+([0-9]{4})/.test(values.userName)){
                errors.userData = "Der Username muss aus 4 kleingeschriebenen Buchstaben und 4 Zahlen bestehen. Beispiel : abcd4567";
            }

            if (checkBox3) {
                    errors.responseMessage = "Username ist entweder falsch oder existiert nicht"
            }
        }
        // response message if user not exist

    }
    if (index === "Insert_box_package_dimensions") {
        if (!checkBox1 && !checkBox2 && !checkBox3 && !checkBox4 && !checkBox5) {
            errors.checked = "Du musst eine Option auswählen!";
        }
        if (isHidden) {
            errors.responseMessage = "Alle Fächer sind belegt! Versuchen Sie bitte später erneut!"
        }
    }
    if (index === "Insert_box_period") {
        if (!checkBox1 && !checkBox2 && !checkBox3){
            errors.checked = "Du musst eine Option auswählen!";
        }
        if (!values.hours){
            console.log(values.hours);
        }
        if (!values.minutes){
            console.log(values.minutes);
        }
    }
    if (index === "Insert_box_confirmation") {

    }
    return errors;
}

export default validation;