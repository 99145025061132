import './parcel.css'
import React from 'react';
import box_orange from "../../../static/parcel/Box_orange.svg"
import box_white from "../../../static/parcel/Box_white.svg"
import {MY_URL} from "../../../App";
import {log} from "qrcode/lib/core/galois-field";


function Parcel({date, size="XL", number="number", mine, fullNumber="", location="TH", title}) {
    function openBookingDetails(){
        //window.open(MY_URL + "/details/" + number.toString() ,'_blank');
        window.location.href = MY_URL + "/details/"+location +"/"+ fullNumber;
    }
    function test(){
        console.log(splitDate.toString())
    }
    const monthNames = ["1", "2", "3", "4", "5", "6",
    "7", "8", "9", "10", "11", "12"
    ];
    var splitDate = new Date()
    splitDate.setDate(date[0]+date[1])
    splitDate.setMonth((date[3]+date[4])-1)
    splitDate.setFullYear(date[6]+date[7]+date[8]+date[9])

    return (
        <div style={mine ? {"--bg_color": "#EEEEEE", "--img_body_bg_color": "#FFFFFF"} : {"--bg_color": "#FFFFFF", "--img_body_bg_color": "#EEEEEE"}} className="parcel">
            <div className="parcel_img_body">
                <img src={mine ? box_orange : box_white}/>
                <div>
                    <p className="fliesstext_p_bold">{size}</p>
                </div>
            </div>
            <div className="parcel_text_component parcel_text_component_timeLocation">
                <p className="tootip_content_p_gray">{splitDate.getDate() + "." + monthNames[splitDate.getMonth()] + "." + splitDate.getFullYear()/*date*/}</p>
                <p className="tootip_content_p_gray">{location == "TH" ? "Tempelhof" : location == "AH" ? "Adlershof" : null}</p>
            </div>
            <div className="parcel_text_component">
                <p className="fliesstext_p_bold">{!title ? <br/> : title}</p>
            </div>
            <div className="parcel_text_component">
                <p  className="fliesstext_p">{"**"+number.toString().slice(-5)}</p>
            </div>
            <div style={{flex:"1"}}></div>
            <button className="ueberschrift_btn" onClick={openBookingDetails}>Details</button>
        </div>
    )
}
export default Parcel;
