import './App.css';
import {BrowserRouter as Router, Route, Switch, Redirect, useLocation} from "react-router-dom";
import PrivateRoute from './components/login/PrivateRoute';
import Data_protection_policy from "./components/data_protection_policy";
import Editorical_notes from "./components/editorical_notes";
import Registration_conditions from "./components/registration_conditions";
import Liability_conditions from "./components/liability_conditions";
import Carousel from "./components/partner/Carousel";
import React, {useEffect, useState} from "react";
import Login, {isAdmin, isLogin} from "./components/login";
import Register from "./components/register";
import Profile from "./components/profile";
import Box_selection from "./components/box_selection";
import Insert_box from "./components/insert_box";
import Start_seite from "./components/start_seite";
import market from "./components/market";
import Admin_page from "./components/admin_page";
import Parcel from "./components/common/parcel";
import Reset_pass_v1 from "./components/reset_pass_v2";
import Locker_load from "./components/admin_page/locker_load";
import ProtectedRoute from "./components/login/ProtectedRoute";
import Details_page from "./components/profile/details_page";
import Post_details from "./components/market/post_details";
import Edit_post from "./components/market/edit_post";
import Chat from "./components/chat";
import Release_posts from "./components/admin_page/release_posts";
import CookieBanner from './components/cookie_banner/cookie_banner';
import Analytics from './components/admin_page/analytics';
import DB_export from './components/admin_page/db_export';

export var tinyScreenWidth = window.matchMedia("(max-width: 380px)")
export var screenWidth = window.matchMedia("(max-width: 600px)")
export var MscreenWidth = window.matchMedia("(max-width: 768px)")
export var LscreenWidth = window.matchMedia("(max-width: 992px)")
export var XLscreenWidth = window.matchMedia("(max-width: 1200px)")
export var XXLscreenWidth = window.matchMedia("(max-width: 3000px)")
export var MY_URL = "";
export var isTesting = false;
export var isLoggedIn = false; // used to test instead of local storage
const host = window.location.hostname;
const testServerUrl = "https://locker.bsr.de"
const produktionServerUrl = "https://kiezlocker.bsr.de"
const isProduction = true

function App() {

    if (host === "localhost" || host === "127.0.0.1" || host === "") {
        if (host !== "localhost") {
            window.location.hostname = "localhost";
        }
        MY_URL = "https://localhost";
    } else
        MY_URL = isProduction ? produktionServerUrl : testServerUrl;

    return (
        <Router>

            <Switch>
                {
                    isTesting ? <Route exact path="/locker_load" component={Locker_load}/> :
                        <ProtectedRoute exact path="/locker_load" component={Locker_load}/>
                }
                {
                    isTesting ? <Route exact path="/analytics" component={Analytics}/> :
                        <ProtectedRoute exact path="/analytics" component={Analytics}/>
                }
                {
                    isTesting ? <Route exact path="/db_export" component={DB_export}/> :
                        <ProtectedRoute exact path="/db_export" component={DB_export}/>
                }
                {
                    isTesting ? <Route exact path="/box_selection" component={Box_selection}/> :
                        <PrivateRoute exact path="/box_selection" component={Box_selection}/>
                }
                {
                    isTesting ? <Route exact path="/profile" component={Profile}/> :
                        <PrivateRoute exact path="/profile" component={Profile}/>
                }
                {
                    isTesting ? <Route exact path="/reset_pass" component={Reset_pass_v1}/> :
                        <Route exact path="/reset_pass/:i/:q" component={Reset_pass_v1}/>
                }
                {
                    isTesting ? <Route exact path="/insert_box" component={Insert_box}/> :
                        <PrivateRoute exact path="/insert_box" component={Insert_box}/>
                }
                {
                    isTesting ? <Route exact path="/parcel" component={Parcel}/> : null
                }
                {
                    isTesting ? <Route exact path="/start_seite" component={Start_seite}/> :
                        <Route exact path="/start_seite" component={Start_seite}/>
                }
                {
                    isTesting || !isLogin() ? <Route exact path="/register" component={Register}/> : null
                }
                {
                    isTesting ? <Route exact path="/market" component={market}/> :
                        <Route exact path="/market" component={market}/>
                }
                {
                    isTesting ? <Route exact path="/admin" component={Admin_page}/> :
                        <ProtectedRoute exact path="/admin" component={Admin_page}/>
                }
                {
                    isTesting ? <Route exact path="/post_details" component={Post_details}/> :
                        <Route exact path="/post_details/:q" component={Post_details}/>
                }
                {
                    isTesting ? <Route exact path="/edit_post" component={Edit_post}/> :
                        <PrivateRoute exact path="/edit_post/:q" component={Edit_post}/>
                }
                {
                    isTesting ? <Route exact path="/chat" component={Chat}/> :
                        <PrivateRoute exact path="/chat" component={Chat}/>
                }
                {
                    isTesting ? <Route exact path="/release_posts" component={Release_posts}/> :
                        <PrivateRoute exact path="/release_posts" component={Release_posts}/>
                }
                {
                    isTesting ? <Route exact path="/details" component={Details_page}/> :
                        <PrivateRoute exact path="/details/:l/:q" component={Details_page}/>
                }
                {
                    isTesting || !isLogin() ? <Route exact path="/login" component={Login}/> : null
                }

                <Route exact path="/data_protection_policy" component={Data_protection_policy}/>
                <Route exact path="/editorical_notes" component={Editorical_notes}/>
                <Route exact path="/registration_conditions" component={Registration_conditions}/>
                <Route exact path="/liability_conditions" component={Liability_conditions}/>
                <Route exact path="/partner" component={Carousel}/>
                <Redirect from="*" to="/start_seite"/>
            </Switch>
            <CookieBanner debug={isTesting}/>
        </Router>
    );
}

export default App;