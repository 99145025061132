import React, {useEffect, useState} from 'react';
import './market.css';
import '../../GlobalStyle.css'
import 'react-dropdown/style.css';
import Start_page from "./start_page";
import Add_post from "./add_post";
import {isAdmin, isLogin} from "../login";
import Post_details from "./post_details";
import Post_preview from "./post_preview";

function Market() {
    const [index, setIndex] = useState("Start_page");
    const switchToIndex = (index, values = null ) => {
        setIndex(index);
    };
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    switch (index) {
        case "Start_page":
            return <Start_page switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Add_post":
            return <Add_post switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Post_preview":
            return <Post_preview switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        default:
            return <Start_page/>;
    }
}
export default Market;
