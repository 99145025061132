import React, {useEffect} from 'react';
import './box_selection.css';
import '../../GlobalStyle.css'
import num_1 from '../../static/box_selection/1.svg'
import num_2 from '../../static/box_selection/2.svg'
import num_3 from '../../static/box_selection/3.svg'
import num_4 from '../../static/box_selection/4.svg'
import num_5 from '../../static/box_selection/5.svg'
import num_6 from '../../static/box_selection/6.svg'
import num_7 from '../../static/box_selection/7.svg'
import num_8 from '../../static/box_selection/8.svg'
import num_9 from '../../static/box_selection/9.svg'
import num_10 from '../../static/box_selection/10.svg'
import num_11 from '../../static/box_selection/11.svg'
import num_12 from '../../static/box_selection/12.svg'
import num_13 from '../../static/box_selection/13.svg'
import num_14 from '../../static/box_selection/14.svg'
import num_15 from '../../static/box_selection/15.svg'
import num_16 from '../../static/box_selection/16.svg'
import num_17 from '../../static/box_selection/17.svg'
import num_18 from '../../static/box_selection/18.svg'
import num_19 from '../../static/box_selection/19.svg'
import num_20 from '../../static/box_selection/20.svg'
import num_21 from '../../static/box_selection/21.svg'
import num_22 from '../../static/box_selection/22.svg'
import num_23 from '../../static/box_selection/23.svg'
import num_24 from '../../static/box_selection/24.svg'
import RestApi from "../../RestAPI";
import Insert_box_location from "../insert_box/insert_box_location";
import Insert_box_recipient from "../insert_box/insert_box_recipient";
import Insert_box_package_dimensions from "../insert_box/insert_box_package_dimensions";
import Insert_box_period from "../insert_box/insert_box_period";
import Insert_box_selection from "../insert_box/insert_box_selection";
import Insert_box_confirmation from "../insert_box/insert_box_confirmation";

function Box_selection({isAdmin, lockerWidth, location = "TH"}) {

    const status = ['free', 'free', 'free', 'free', 'free', 'free', 'free', 'free',
        'free', 'free', 'free', 'free', 'free', 'free', 'free', 'free',
        'free', 'free', 'free', 'free', 'free', 'free', 'free', 'free'];
    const status_ah = ['free', 'free', 'free', 'free'];

    function updateTerminalStatus(booked_locker_list: Array, exceeded_bookings_list: Array, out_of_order_locker_list: Array,paketin_booked_locker_list: Array, paketin_exceeded_bookings_list: Array, paketin_out_of_order_locker_list: Array) {
        if (location === "TH"){
        for (let i = 0; i < booked_locker_list.length; i++) {
            if (booked_locker_list[i] < 25) {
                if(isAdmin && exceeded_bookings_list.indexOf(booked_locker_list[i]) !== -1){
                    document.getElementById(booked_locker_list[i]).className = document.getElementById(booked_locker_list[i]).className +  " overdue_admin";
                }
                else if (isAdmin){
                    document.getElementById(booked_locker_list[i]).className = document.getElementById(booked_locker_list[i]).className + " not_free_admin";
                }
                else {
                    document.getElementById(booked_locker_list[i]).className = document.getElementById(booked_locker_list[i]).className + " not_free";
                }
            }
        }

         for (let i = 0; i < out_of_order_locker_list.length; i++) {
            if (out_of_order_locker_list[i] < 25) {
                if (isAdmin && out_of_order_locker_list.indexOf(out_of_order_locker_list[i]) !== -1){
                    document.getElementById(out_of_order_locker_list[i]).className = document.getElementById(out_of_order_locker_list[i]).className +  " not_free_out_of_order";
                }
                else {
                    document.getElementById(out_of_order_locker_list[i]).className = document.getElementById(out_of_order_locker_list[i]).className + " not_free";
                }
            }
        }
         }
        else {
         for (let i = 0; i < paketin_booked_locker_list.length; i++) {
            if (paketin_booked_locker_list[i] < 5) {
                if(isAdmin && paketin_exceeded_bookings_list.indexOf(paketin_booked_locker_list[i]) !== -1){
                    document.getElementById(paketin_booked_locker_list[i]+"_ah").className = document.getElementById(paketin_booked_locker_list[i]+"_ah").className +  " overdue_admin";
                }
                else if (isAdmin){
                    document.getElementById(paketin_booked_locker_list[i]+"_ah").className = document.getElementById(paketin_booked_locker_list[i]+"_ah").className + " not_free_admin";
                }
                else {
                    document.getElementById(paketin_booked_locker_list[i]+"_ah").className = document.getElementById(paketin_booked_locker_list[i]+"_ah").className + " not_free";
                }
            }
        }
         for (let i = 0; i < paketin_out_of_order_locker_list.length; i++) {
            if (paketin_out_of_order_locker_list[i] < 5) {
                if (isAdmin && paketin_out_of_order_locker_list.indexOf(paketin_out_of_order_locker_list[i]) !== -1){
                    document.getElementById(paketin_out_of_order_locker_list[i]+"_ah").className = document.getElementById(paketin_out_of_order_locker_list[i]+"_ah").className +  " not_free_out_of_order";
                }
                else {
                    document.getElementById(paketin_out_of_order_locker_list[i]+"_ah").className = document.getElementById(paketin_out_of_order_locker_list[i]+"_ah").className + " not_free";
                }
            }
        }
         }
    }


    useEffect(() => {
        getTerminalLoad();
  }, [location]);

   function getTerminalLoad() {
       RestApi("/api/get_terminal_load", "GET", {}).then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let booked_locker_list = JSON.parse(response["booked_locker_list"]);
                let exceeded_bookings_list = JSON.parse(response["exceeded_bookings_list"]);
                let out_of_order_locker_list = JSON.parse(response["out_of_order_locker_list"]);
                let paketIn_booked_locker_list = JSON.parse(response["PaketIn_booked_locker_list"]);
                let paketIn_exceeded_bookings_list = JSON.parse(response["PaketIn_exceeded_bookings_list"]);
                let paketIn_out_of_order_locker_list = JSON.parse(response["PaketIn_out_of_order_locker_list"]);
                updateTerminalStatus(booked_locker_list, exceeded_bookings_list, out_of_order_locker_list, paketIn_booked_locker_list, paketIn_exceeded_bookings_list,paketIn_out_of_order_locker_list);

            })
            .catch((err) => {
                console.log(err)
            });
    }


    if (location === "TH"){
        return (
            <div style={{"--locker_width": lockerWidth}} className="package_station">
                {isAdmin &&
                    <div className="terminal_explain_admin">
                        <div className={"terminal_explain_admin_item"}>
                            <div className="free_box"></div>
                            <h4 className="teaser_h4_bold">Fach nicht gebucht</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box"></div>
                            <h4 className="teaser_h4_bold">Fach gebucht – alles in Ordnung</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box_overdeu"></div>
                            <h4 className="teaser_h4_bold">Fach gebucht – Abholung überfällig</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box_out_of_order"></div>
                            <h4 className="teaser_h4_bold">Fach blockiert – Außer Betrieb</h4>
                        </div>
                    </div>
                }
                <div className="package_station_content">
                    <div className="terminal_img img_flex">
                        {
                            //<img src={terminal_img}/>
                        }
                    </div>
                    <div className="overlay_terminal_img img_flex">
                        {/*<img style={{width: "450px"}} src={terminal_img}/>*/}
                        <div className="columns">
                            <div className="column">
                                <div id={"1"} className={"row_a my_style border_right_bottom xl" + " " + status[0]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">1</p>*/}
                                    <img src={num_1}/>
                                </div>
                                <div id={"2"} className={"row_a my_style border_right_bottom xl" + " " + status[1]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">2</p>*/}
                                    <img src={num_2}/>
                                </div>
                                <div id={"3"} className={"row_e my_style border_right m" + " " + status[2]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">3</p>*/}
                                    <img src={num_3}/>
                                </div>
                            </div>
                            <div className="column">
                                <div id={"4"} className={"row_d my_style border_right_bottom l" + " " + status[3]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">4</p>*/}
                                    <img src={num_4}/>
                                </div>
                                <div id={"5"} className={"row_j my_style border_right_bottom s" + " " + status[4]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">5</p>*/}
                                    <img src={num_5}/>
                                </div>
                                <div id={"6"} className={"row_j my_style border_right_bottom s" + " " + status[5]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">6</p>*/}
                                    <img src={num_6}/>
                                </div>
                                <div id={"7"} className={"row_i my_style border_right_bottom xs" + " " + status[6]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">7</p>*/}
                                    <img src={num_7}/>
                                </div>
                                <div id={"8"} className={"row_i my_style border_right_bottom xs" + " " + status[7]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">8</p>*/}
                                    <img src={num_8}/>
                                </div>
                                <div id={"9"} className={"row_i my_style border_right_bottom xs" + " " + status[8]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">9</p>*/}
                                    <img src={num_9}/>
                                </div>
                                <div id={"10"} className={"row_i my_style border_right_bottom xs" + " " + status[9]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">10</p>*/}
                                    <img src={num_10}/>
                                </div>
                                <div id={"11"} className={"row_j my_style border_right_bottom s" + " " + status[10]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">11</p>*/}
                                    <img src={num_11}/>
                                </div>
                                <div id={"12"} className={"row_j my_style border_right s" + " " + status[11]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">12</p>*/}
                                    <img src={num_12}/>
                                </div>
                            </div>
                            <div className="column">
                                <div id={"13"} className={"row_f my_style border_right_bottom m" + " " + status[12]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">13</p>*/}
                                    <img src={num_13}/>
                                </div>
                                <div className="row_x border_right_bottom xl"></div>
                                <div id={"14"} className={"row_b my_style border_right xl" + " " + status[13]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">14</p>*/}
                                    <img src={num_14}/>
                                </div>
                            </div>
                            <div className="column">
                                <div id={"15"} className={"row_a my_style border_right_bottom xl" + " " + status[14]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">15</p>*/}
                                    <img src={num_15}/>
                                </div>
                                <div id={"16"} className={"row_a my_style border_right_bottom xl" + " " + status[15]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">16</p>*/}
                                    <img src={num_16}/>
                                </div>
                                <div id={"17"} className={"row_e my_style border_right m" + " " + status[16]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">17</p>*/}
                                    <img src={num_17}/>
                                </div>
                            </div>
                            <div className="column">
                                <div id={"18"} className={"row_c my_style border_bottom l" + " " + status[17]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">18</p>*/}
                                    <img src={num_18}/>
                                </div>
                                <div id={"19"} className={"row_h my_style border_bottom s" + " " + status[18]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">19</p>*/}
                                    <img src={num_19}/>
                                </div>
                                <div id={"20"} className={"row_h my_style border_bottom s" + " " + status[19]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">20</p>*/}
                                    <img src={num_20}/>
                                </div>
                                <div id={"21"} className={"row_h my_style border_bottom s" + " " + status[20]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">21</p>*/}
                                    <img src={num_21}/>
                                </div>
                                <div id={"22"} className={"row_h my_style border_bottom s" + " " + status[21]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">22</p>*/}
                                    <img src={num_22}/>
                                </div>
                                <div id={"23"} className={"row_h my_style border_bottom s" + " " + status[22]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">23</p>*/}
                                    <img src={num_23}/>
                                </div>
                                <div id={"24"} className={"row_h my_style s" + " " + status[23]}>
                                    {/*<p style={{color: "#666"}} className="fliesstext_p">24</p>*/}
                                    <img src={num_24}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
    else if(location === "AH"){
        return (
            <div style={{"--locker_width": lockerWidth}} className="package_station">
                {isAdmin &&
                    <div className="terminal_explain_admin">
                        <div className={"terminal_explain_admin_item"}>
                            <div className="free_box"></div>
                            <h4 className="teaser_h4_bold">Fach nicht gebucht</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box"></div>
                            <h4 className="teaser_h4_bold">Fach gebucht – alles in Ordnung</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box_overdeu"></div>
                            <h4 className="teaser_h4_bold">Fach gebucht – Abholung überfällig</h4>
                        </div>
                        <div className={"terminal_explain_admin_item"}>
                            <div className="not_free_box_out_of_order"></div>
                            <h4 className="teaser_h4_bold">Fach blockiert – Außer Betrieb</h4>
                        </div>
                    </div>
                }
                <div className="package_station_content">
                    <div className="terminal_img img_flex">
                        {
                            //<img src={terminal_img}/>
                        }
                    </div>
                    <div className="overlay_terminal_img img_flex">
                        {/*<img style={{width: "450px"}} src={terminal_img}/>*/}
                        <div className="columns">
                            <div className={"column_ah"}>
                                <div id={"1_ah"} className={"row_ah my_style border_bottom xl " + status_ah[0]}>
                                    <img src={num_1}/>
                                </div>
                                <div id={"2_ah"} className={"row_ah my_style border_bottom xl " + status_ah[1]}>
                                    <img src={num_2}/>
                                </div>
                                <div id={"3_ah"} className={"row_ah my_style border_bottom xl " + status_ah[2]}>
                                    <img src={num_3}/>
                                </div>
                                <div id={"4_ah"} className={"row_ah my_style xl " + status_ah[3]}>
                                    <img src={num_4}/>
                                </div>
                            </div>
                            {/*
                            <div className={"column_ah"}>
                                <div id={"3_ah"} className={"row_ah my_style border_bottom xl " + status_ah[2]}>
                                    <img src={num_3}/>
                                </div>
                                <div id={"4_ah"} className={"row_ah my_style xl " + status_ah[3]}>
                                    <img src={num_4}/>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Box_selection;
