import React, { useState } from 'react';
import {MeinBereichList, AdminBereichList} from './MenuItems';
import './Dropdown.css';
import { Link } from 'react-router-dom';

import postfach_icon from './icons/Postfach _grau.svg'
import postfach_icon_weiss from './icons/Postfach _weiss.svg'
import logout_icon from './icons/Abmelden_grau.svg'
/*
function Dropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
*/
const redirect_to_bookings = () => {
        window.location.href = "/profile#profile_my_bookings";
    }
const redirect_to_posts = () => {
        window.location.href = "/profile#profile_my_posts";
    }
function logout(){
        localStorage.removeItem("token");
        localStorage.removeItem("role");
    }

function MeinBereich() {
  const [click, setClick] = useState(false);
  const [isShown, setIsShown] = useState([false, false, false, false]);
  const [events, setEvents] = useState([null, redirect_to_posts, redirect_to_bookings, logout]);
    // Update an item in the array
  const updateItem = (index, val) => {
    setIsShown(isShown => {
      const newArray = [...isShown];
      newArray[index] = val;
      return newArray;
    });
  };

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
          id={"dropdown1"}
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MeinBereichList.map((item, index) => {
          return (
            <li key={index}
                onClick={events[index]}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
                onMouseEnter={() => updateItem(index, true)}
                onMouseLeave={() => updateItem(index, false)}
              >
                  <div>
                      <img src={isShown[index] ? item.icon_white : item.icon}/>
                      {item.title}
                  </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function AdminBereich() {
  const [click, setClick] = useState(false);
  const [isShown, setIsShown] = useState([false, false, false]);
    // Update an item in the array
  const updateItem = (index, val) => {
    setIsShown(isShown => {
      const newArray = [...isShown];
      newArray[index] = val;
      return newArray;
    });
  };

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
          id={"dropdown2"}
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {AdminBereichList.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
                onMouseEnter={() => updateItem(index, true)}
                onMouseLeave={() => updateItem(index, false)}
              >
                  <div>
                      <img src={isShown[index] ? item.icon_white : item.icon}/>
                      {item.title}
                  </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default MeinBereich;
export {MeinBereich, AdminBereich};

/*
function Dropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
*/
