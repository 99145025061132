import React, {useEffect, useState} from 'react';
import './new_login.css';
import Enter_login_data from "./enter_login_data";
import Forgot_pass from "./forgot_pass";
import Mail_sent from "./mail_sent";
import {isAdmin, isLogin} from "./index";


function Login() {
    const [index, setIndex] = useState("Enter_login_data");
    const switchToIndex = (index) => {
        setIndex(index);
    };
    const redirect = () => {
        window.location.href = "/start_seite";
    }
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);

    switch (index) {
        case "Enter_login_data":
            return <Enter_login_data switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Start_seite":
            return redirect();
        case "Forgot_pass":
            return <Forgot_pass switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Mail_sent":
            return <Mail_sent switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>
        default:
            return <Enter_login_data/>;
    }
}

export default Login;
