import React, { useEffect, useState } from "react";
import RestApi from "../../RestAPI";
import Navbar from "../common/Navbar/Navbar";
import analytics_icon from "../../static/admin/analytics.svg";
import Box_selection from "../box_selection";
import {isTesting, LscreenWidth, MscreenWidth} from "../../App";
import {Footer} from "../common";
import Modal from "../modal";
import {isAdmin, isLogin} from "../login";

function DB_export() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(true)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(true)
        }
    await data()
    }, []);
    function download_csv(table_name) {
        RestApi("/api/db_export/" + table_name, "GET", {})
            .then(response => {
                response.blob().then(blob => {
                    let file_name = table_name;
                    let file_ext = '.csv'
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = file_name + file_ext;
                    a.click();
                });
            });
    }
  return (
      <main className="main" >
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="admin_page_content">
                <div className="admin_page_content_item admin_page_content_locker_load">
                    <div>
                        <img src={analytics_icon}/>
                    </div>
                    <div>
                        <h1 className={"titel_h1"}>Datenbankauswertung</h1>
                    </div>
                </div>
                <div id={"test"} className="admin_page_content_item admin_page_content_item_main_field_background">
                    <div className={"admin_page_content_item_Datenbank"}>
                        <div className={"admin_analytics_page_content_item_Datenbank_item"}>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("User")}>Download Users Table</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("Pageviews")}>Download Pageviews</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("LockerEntry")}>Download LockerEntry</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("PaketInLockerEntry")}>Download PaketInLockerEntry</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("MarktEntry")}>Download MarktEntry</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("Conversation")}>Download Conversation</button>
                            <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} type="button" className="ueberschrift_btn" onClick={() => download_csv("Message")}>Download Message</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
  );
}

export default DB_export;