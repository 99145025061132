import React, {useEffect, useState} from 'react';
import './new_login.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import validation from "./validation";
import {isTesting, MY_URL} from "../../App";
import leftSideGraphic from "../../static/register_login/undraw_Forgot_password_re_hxwm.png";
import RestApi from "../../RestAPI";


function Forgot_pass({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [backBtnClicked, setBackBtnClicked] = useState(false);
    const [values, setValues] = useState({
        emailAddress: ""
    })
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleChange = (event) =>{
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Forgot_pass"));
        setDataIsCorrect(true);
    }
    useEffect(() =>{
        if (Object.keys(errors).length === 0 && dataIsCorrect){
            isTesting ? switchToIndex("Mail_sent") : checkEmailInDB();
        }
    }, [errors]);
    const handleBackBtn = (event) => {
        event.preventDefault();
        setBackBtnClicked(true);
    }
    useEffect(() =>{
        if (backBtnClicked){
            switchToIndex("Enter_login_data");
        }
    });
    function checkEmailInDB() {
        let data = {email: values.emailAddress};
            RestApi("/api/checkEmail", "POST", data).then((response) => {
                if (response["status"] !== -1) {
                    switchToIndex("Mail_sent");
                }
                else{
                    setRequestError("Die eingegebene Email ist entweder falsch oder existiert nicht!");
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}></Navbar>
            <div className="info_box">
                <div className="login_content">
                    <div style={{display:"none"}} className="login_content_left">
                        <img style={{width:"400px"}} src={leftSideGraphic}/>
                    </div>
                    <div className="login_content_right login_content_right_forgot_pass">
                        <div className="login_content_right_frame login_content_right_frame_forgot_pass">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Passwort zurücksetzen</h3>
                            </div>
                            <div className="login_content_right_item">
                                <input id="emailAddress" name="emailAddress" type="text" className="text_input" placeholder="E-Mail-Adresse" required value={values.emailAddress} onChange={handleChange}/>
                            </div>
                            {errors.emailAddress &&
                                <div className="login_content_right_item">
                                    <span className="error_span">{errors.emailAddress}</span>
                                </div>
                            }
                            <div style={{flex:"1"}}></div>
                            <div className="login_content_right_item login_content_right_item_btn_field_forgot_pass">
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleBackBtn}>Zurück</button>
                                </div>
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={handleFormSubmit} className="ueberschrift_btn">Weiter</button>
                                </div>


                            </div>

                            {requestError &&
                                <div className="login_content_right_item">
                                    <span_login>{requestError}</span_login>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default Forgot_pass;