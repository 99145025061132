import React, {useEffect, useState} from 'react';
import './admin_page.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import locker_icon from "../../static/admin/Locker_grau.svg"
import Box_selection from "../box_selection";
import Modal from "../modal";
import $ from "jquery";
import {isTesting, LscreenWidth, MscreenWidth} from "../../App";
import RestAPI from "../../RestAPI";
import {isAdmin, isLogin} from "../login";


function Locker_load({switchToIndex/*, isAdminVar, isLoggedInVar*/}) {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(true)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(true)
        }
    await data()
    }, []);

    const options = [
        'one', 'two', 'three'
    ];
    const defaultOption = options[0];
    const data =[{"name":"test1", "test":"test"},{"name":"test2"}];
    function loadPosts(posts){
        posts.forEach({ })
    }

    const [trackingNumber, setTrackingNumber] = useState("trackingNumber");
    const [senderUserName, setSenderUserName] = useState("senderUserName");
    const [recipientUserName, setRecipientUserName] = useState("recipientUserName");
    const [bookingTitle, setBookingTitle] = useState("bookingTitle");
    const [parcelSize, setParcelSize] = useState("parcelSize");
    const [bookingPin, setBookingPin] = useState("bookingPin");
    const [dateForReservation, setDateForReservation] = useState("dateForReservation");
    const [dateForEmpty, setDateForEmpty] = useState("dateForEmpty");
    const [useCase, setUseCase] = useState("useCase");

    const [thSelected, setThSelected] = useState(true);
    const [ahSelected, setAhSelected] = useState(false);
    const [location, setLocation] = useState("TH");

    var screenWidth = window.matchMedia("(max-width: 600px)");
    const [boxClicked, setBoxClicked] = useState(false);

    $(document).on("click", ".not_free_admin", function(event){
        if(!isAdminVar) return;
        event.preventDefault();
        setBoxClicked(true)
        if (event.target.id.includes("_ah")){
            getBookingInfosForAdmin((event.target.id).replace("_ah", ''), "AH")
        }
        else{
            getBookingInfosForAdmin((event.target.id), "TH")
        }
        $(document).off("click", ".not_free_admin")

    });
    $(document).on("click", ".overdue_admin", function(event){
        if(!isAdminVar) return;
        event.preventDefault();
        setBoxClicked(true)
        if (event.target.id.includes("_ah")){
            getBookingInfosForAdmin((event.target.id).replace("_ah", ''), "AH")
        }
        else{
            getBookingInfosForAdmin((event.target.id), "TH")
        }
        $(document).off("click", ".overdue_admin")

    });

    function selectTh(){
        setThSelected(true);
        setAhSelected(false);
        setLocation("TH")
    }
    function selectAh(){
        setThSelected(false);
        setAhSelected(true);
        setLocation("AH")
    }

    function getBookingInfosForAdmin(lockerID, location){
        RestAPI("/api/get_booking_details_admin/" + location + "/" + lockerID, "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    setTrackingNumber(response["tracking_Number"])
                    setSenderUserName(response["senderUserName"]);
                    setSenderUserName(response["senderUserName"]);
                    setRecipientUserName(response["recipientUserName"]);
                    setBookingTitle(response["bookingTitle"]);
                    setParcelSize(response["parcelSize"]);
                    setDateForReservation(response["dateForReservation"]);
                    setDateForEmpty(response["dateForEmpty"]);
                    setUseCase(response["booking_status"]);
                    setBookingPin(response["booking_pin"])
                } else {
                    setIsErrorMessage(true);
                    setBoxClicked(false)
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);

                }
            })
            .catch((err) => {
                //setRequestError(err.toString());
            });
    }
    return (
        <main className="main" >
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="admin_page_content">
                <div className="admin_page_content_item admin_page_content_locker_load">
                    <div>
                        <img src={locker_icon}/>
                    </div>
                    <div>
                        <h1 className={"titel_h1"}>Auslastung des Kiez-Lockers</h1>
                    </div>
                </div>
                <div id={"test"} className="admin_page_content_item admin_page_content_item_main_field_background">
                    <div className="admin_page_content_item__locker_load_main_field">
                        <div className={"admin_page_content_item__locker_load_main_field_locker_list"}>
                            <div className={thSelected ? "admin_page_content_item__locker_load_main_field_locker_list_item admin_page_content_item__locker_load_main_field_locker_list_item_selected" : "admin_page_content_item__locker_load_main_field_locker_list_item"}>
                                <div onClick={() => selectTh()} className={"admin_page_content_item__locker_load_main_field_locker_list_item_textField"}>
                                    <h4 className={"teaser_h4"}>Kiez-Locker Tempelhof</h4>
                                </div>
                                <div className={"admin_page_content_item__locker_load_main_field_locker_list_item_space"}></div>
                            </div>
                            <div className={ahSelected ? "admin_page_content_item__locker_load_main_field_locker_list_item admin_page_content_item__locker_load_main_field_locker_list_item_selected" : "admin_page_content_item__locker_load_main_field_locker_list_item"}>
                                <div onClick={() => selectAh()} className={"admin_page_content_item__locker_load_main_field_locker_list_item_textField"}>
                                    <h4 className={"teaser_h4"}>Charlotte-Locker Adlershof</h4>
                                </div>
                                <div className={"admin_page_content_item__locker_load_main_field_locker_list_item_space"}></div>


                            </div>
                        </div>
                        {
                            screenWidth.matches ?
                                <div className={"admin_page_content_item__locker_load_main_field_locker_field"}>
                                    <Box_selection isAdmin={true} lockerWidth={"90vw"} location={location}></Box_selection>
                                </div>
                                :
                            MscreenWidth.matches ?
                                <div className={"admin_page_content_item__locker_load_main_field_locker_field"}>
                                    <Box_selection isAdmin={true} lockerWidth={"80vw"} location={location}></Box_selection>
                                </div>
                                :
                            LscreenWidth.matches ?
                                <div className={"admin_page_content_item__locker_load_main_field_locker_field"}>
                                    <Box_selection isAdmin={true} lockerWidth={"80vw"} location={location}></Box_selection>
                                </div>
                                :
                                <div className={"admin_page_content_item__locker_load_main_field_locker_field"}>
                                    <Box_selection isAdmin={true} lockerWidth={"50vw"} location={location}></Box_selection>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
            <Modal isOpened={boxClicked} onClose={() => setBoxClicked(false)}>
                <div className="modal_group">
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Absender:&nbsp; </p>
                        </div>

                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Nutzername" : senderUserName}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Empfänger:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Nutzername" : recipientUserName}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Buchungsnummer:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Buchungsnummer" : trackingNumber}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Artikelname:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Artikelname" : bookingTitle}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Fachgröße:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Fachgröße" : parcelSize}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Einlegen bis:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Einlegen bis" : dateForReservation}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Abholen bis:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Abholen bis" : dateForEmpty}</p>
                        </div>
                    </div>
                    <div className="modal_group_item">
                        <div className="modal_group_item_data_left">
                            <p className="fliesstext_p">Pin:&nbsp; </p>
                        </div>
                        <div className="modal_group_item_data_right">
                            <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "Pin" : bookingPin}</p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpened={isErrorMessage} onClose={() => setIsErrorMessage(false)}>
                <div className="modal_group">
                                <div className="modal_group_item">
                                    <h4 className="teaser_h4_bold">Es gibt keine Buchung im ausgewählten Locker. Die Buchung wurde manuell storniert. Das Fach ist jetzt wieder verfügbar. Klicken Sie bitte auf "Seite neu laden".</h4>
                                </div>
                                <div style={{flex: "1"}}></div>
                                <div className="modal_group_item modal_group_item_btn_section">
                                    <div className="btn_section">
                                        <button
                                            style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#f61"}}
                                            id="loginbtn" type="button"
                                            onClick={() => window.location.reload()}
                                            className="ueberschrift_btn">Seite neu laden
                                        </button>
                                    </div>
                                </div>
                            </div>

            </Modal>

        </main>
    )
}
export default Locker_load;
