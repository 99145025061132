import React, {useEffect, useState} from 'react';
import './register.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";


function Registration_success({switchToIndex}) {
    const [loginClicked, setLoginClicked] = useState(false);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoginClicked(true);
    }
    useEffect(() =>{
        if (loginClicked){
            redirect();
        }
    });
    const redirect = () => {
        window.location.href = "/login";
    }
    return (
        <main className="main">
            <Navbar></Navbar>
            <div className="info_box">
                <div className="login_content register_window">
                    <div style={{display:"none"}} className="login_content_left">
                        <div className="login_content_left_item">
                            <div  className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">1</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Telefon</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">2</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Verifizierung</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">3</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Kundendaten</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div style={{backgroundColor:"#f61"}} className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#fff"}} className="global_style_h2_light_text">4</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#f61"}} className="global_style_h2_light_text">Bestätigen</h2>
                            </div>
                        </div>
                    </div>
                    <div className="login_content_right login_content_right_register">
                        <div className="login_content_right_frame login_content_right_frame_register">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Registrierung abgeschlossen!</h3>
                            </div>
                            <div className="login_content_right_item">
                                <p style={{color:"#666"}} className="fliesstext_p">Du erhältst in Kürze eine E-Mail.</p>
                            </div>
                            <div className="login_content_right_item">
                                <p style={{color:"#666"}} className="fliesstext_p">Bitte klicke dort auf den Link, um Deine E-Mail-Adresse zu verifizieren.</p>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{alignItems:"flex-end"}} className="login_content_right_item">
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={handleFormSubmit} className="ueberschrift_btn">Einloggen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </main>
    )
}

export default Registration_success;

