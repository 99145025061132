const validation = (values, checked, category, district, index) => {
    let errors={};
    //enter_login_fata form
    if (index === "Post_preview") {
        if (!values.title) {
            errors.title = "Titel ist erforderlich"
        }
        if (!values.description) {
            errors.description = "Beschreibung ist erforderlich"
        }
        if (!category) {
            errors.category = "Rubrik ist erforderlich"
        }
        /*
        if (!district) {
            errors.category = "Bezirk ist erforderlich"
        }
         */
        if (!checked){
            errors.checked = "Du musst eine Kategorie auswählen.";
        }
    }
    if (index === "Forgot_pass") {
        //forgot_pass form
        if (!values.emailAddress) {
            errors.emailAddress = "E-Mail oder Telefonnummer sind erforderlich"
        } else if (!/\S+@\S+\.\S+/.test(values.emailAddress)) {
            errors.emailAddress = "E-Mail ist inkorrekt";

        }
    }
    return errors;
}

export default validation;