import {MY_URL} from "./App";


function RestApi(url, method, data, isFormData=false) {
    let apiLink = MY_URL + url;
    let header = {
        "x-access-token": localStorage.getItem("token") ? localStorage.getItem("token") : "",
        "role" : localStorage.getItem("role") ? localStorage.getItem("role") : "",
        'Content-Type': 'application/json'
    };
    let options = {
        method: method,
        headers: header,
    };
    if ( method === "POST" && !isFormData){
        options.body = JSON.stringify(data);
    }
    else if (isFormData) {
        delete header["Content-Type"]
        options.body = data;
    }

    return fetch(apiLink, options)
}

export default RestApi;