import React, { useEffect, useState } from "react";
import "./banner_content.css";

function BannerContent({ checkboxStatesUpdate }) {
  // checkbox states
  const [isCheckedKomfort, setIsCheckedKomfort] = useState(false);
  const [isCheckedStatistik, setIsCheckedStatistik] = useState(false);

  // update checkbox states variables in parent component cookie banner everytime page gets rerendered
  useEffect(() => {
    checkboxStatesUpdate([isCheckedKomfort, isCheckedStatistik]);
  }, [checkboxStatesUpdate, isCheckedKomfort, isCheckedStatistik]);

  const handleOnChangeKomfrot = () => {
    setIsCheckedKomfort(!isCheckedKomfort);
  };
  const handleOnChangeStatistik = () => {
    setIsCheckedStatistik(!isCheckedStatistik);
  };

  return (
    <div className="banner_content">
      <h1 className="banner_title">Cookie-Einstellungen</h1>
      <p className="banner_text">
        Um Deinen Besuch auf unserer Website optimal zu gestalten, werden
        Cookies zu Funktions-, Statistik- und Komfortzwecken verwendet. Bei
        bestimmten Cookies kann ein Drittstaatentransfer stattfinden. Nähere
        Informationen zu Deinen datenschutzrechtlichen Betroffenenrechten
        findest Du in unserer Datenschutzerklärung. Cookies und vergleichbare
        Technologien, die nicht unbedingt erforderlich für die
        Zurverfügungstellung der Website sind, dürfen nur mit Deiner
        Einwilligung verwendet werden. Du kannst deine Einwilligung jederzeit
        unter folgendem Link, den Du auch immer am Fuße unserer Website findest,
        widerrufen, wobei ein späterer Widerruf keine Auswirkung auf die bis
        dahin erfolgten Zugriffe hat.
      </p>
      <a
        className="banner_text banner_link"
        href="/data_protection_policy"
        target="_blank"
      >
        Datenschutzerklärungen
      </a>
      <a
        className="banner_text banner_link"
        href="/editorical_notes"
        target="_blank"
      >
        Impressum
      </a>
      {/* three frames with swtich toggle buttons */}
      <div className="switch_frame">
        <h2 className="switch_frame_title">Notwendig</h2>
        <div className="switch_container">
          <p className="switch_text">
            Diese Cookies sind erforderlich, um die Kernfunktionalitäten der
            Webseite zu ermöglichen.
          </p>
          {/* first checkbox that is always checked */}
          <label className="switch checked">
            <input type="checkbox" checked readOnly></input>
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="switch_frame">
        <h2 className="switch_frame_title">Komfort</h2>
        <div className="switch_container">
          <p className="switch_text">
            Diese Cookies ermöglichen es uns, Komfortfunktionen in die Webseite
            zu integrieren, um Ihren Besuch auf unserer Webseite optimal zu
            gestalten.
          </p>
          {/* second checkbox */}
          <label className="switch">
            <input
              type="checkbox"
              checked={isCheckedKomfort}
              onChange={handleOnChangeKomfrot}
            ></input>
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="switch_frame">
        <h2 className="switch_frame_title">Statistik</h2>
        <div className="switch_container">
          <p className="switch_text">
            Diese Cookies ermöglichen es uns, die Nutzung der Webseite zu
            analysieren, um die Leistung zu messen und zu verbessern.
          </p>
          {/* third checkbox */}
          <label className="switch">
            <input
              type="checkbox"
              checked={isCheckedStatistik}
              onChange={handleOnChangeStatistik}
            ></input>
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default BannerContent;
