import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isLogin} from './index';
import {isLoggedIn} from "../../App";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            isLogin() || isLoggedIn ? <Component {...props} /> : <Redirect to="/login"/>
        )}/>
    );
};

export default PrivateRoute;