import RestApi from "../../RestAPI";

// get Cookie value by name
export function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Check if the cookie starts with the provided name
    if (cookie.startsWith(name + "=")) {
      // Extract the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }

  return null; // Cookie not found
}

// create a sessionID
export function createSession() {
  const key = "SessionID";
  const value = Date.now();
  window.sessionStorage.setItem(key, value);
}

// set pageview of route analytics to backend
export function setPageview(route) {
  let data = { route: route };
  RestApi("/api/set_pageview", "POST", data).then((resp) => {
    return resp.json();
  });
}
