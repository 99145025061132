const validation = (values, index) => {
    let errors={};
    //enter_login_fata form
    if (index === "Enter_login_data") {
        if (!values.emailPhone) {
            errors.emailPhone = "E-Mail oder Telefonnummer sind erforderlich"
        } else if (!/\S+@\S+\.\S+/.test(values.emailPhone)) {
            errors.emailPhone = "E-Mail ist inkorrekt";
        }
        if (!values.userpass) {
            errors.userpass = "Passwort ist erforderlich"
        }
    }
    if (index === "Forgot_pass") {
        //forgot_pass form
        if (!values.emailAddress) {
            errors.emailAddress = "E-Mail-Adresse ist erforderlich"
        } else if (!/\S+@\S+\.\S+/.test(values.emailAddress)) {
            errors.emailAddress = "E-Mail ist inkorrekt";

        }
    }
    return errors;
}

export default validation;