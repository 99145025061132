import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import './reset_pass.css';
import {Footer} from "../common";
import {isTesting, MY_URL} from "../../App";
import Navbar from "../common/Navbar/Navbar";
import validation from "./validation";


function Insert_password({switchToIndex}) {
    const [values, setValues] = useState({
        pass: "",
        rpass: ""
    })
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleChange = (event) =>{
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Insert_password"));
        setDataIsCorrect(true);
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Confirmation") : updatePassword();
        }
    }, [errors]);

    const [warning] = useState("");
    let authorized = false;
    const setAuthorized = () => {
        authorized = true;
        return authorized;
    };
    const redirect_to_login = () => {
        window.location.href = "/login";
    }

    let {i, q} = useParams();

    const checkToken = () => {
        if (q) {
            let url = MY_URL + "/api/reset_password/" + q;

            let header = {
                'Content-Type': 'application/json'
            };
            fetch(url)
                .then((resp) => {
                    // Error handling
                    return resp.json()
                })
                .then((response) => {
                    if (response["id"].toString() === i) {
                        setAuthorized();
                    } else {
                        redirect_to_login();
                    }
                })
        }

    }

    checkToken();

    const updatePassword = () => {
        let url = MY_URL + "/api/update_password/" + q;
        let header = {
            'Content-Type': 'application/json'
        };
        let options = {
            method: 'POST',
            body: JSON.stringify({
                password: values.pass
            }),
            headers: header
        };
        fetch(url, options)
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    console.log("Test")
                    switchToIndex("Confirmation");
                } else {
                    redirect_to_login();
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    return (
        <div className="main">
            <Navbar/>
            <div className="reset_pass">
                <div className="reset_pass_content">
                    <div style={{display:"none"}} className="reset_pass_content_item"></div>
                    <div className="reset_pass_content_item">
                        <h3 className="zwischenueberschrift_h3">Passwort zurücksetzen</h3>

                        <input id="pass" name="pass" type="password" className="text_input" placeholder="Passwort eingeben" required value={values.pass} onChange={handleChange}/>
                        {errors.pass &&
                                    <span className="error_span">{errors.pass}</span>
                                }
                        <input id="rpass" name="rpass" type="password" className="text_input" placeholder="Passwort wiederholen" required value={values.rpass} onChange={handleChange}/>
                        {errors.rpass &&
                                    <span className="error_span">{errors.rpass}</span>
                                }
                        <div style={{flex:"1"}}></div>
                        <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                            <div className="btn_field">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Weiter</button>
                            </div>

                        </div>

                    </div>
                </div>
                {
                    requestError &&
                    <span_login>{requestError}</span_login>
                }
            </div>
            <Footer/>
        </div>
    );
}
export default Insert_password;
