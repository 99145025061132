import React, {useEffect, useState} from 'react';
import './register.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import validation from "./validation";
import {isTesting} from "../../App";
import {phoneNumber, pinId} from "./phone_number_verification";
import RestApi from "../../RestAPI";


function Check_verification_code({switchToIndex}) {
    const validVerificationNumber = /^[0-9]\d*$/;
    const verificationNumberInputId = ["digit1", "digit2", "digit3", "digit4"];
    const [backBtnClicked, setBackBtnClicked] = useState(false);
    const [values, setValues] = useState({
        digit1: "",
        digit2: "",
        digit3: "",
        digit4: ""
    })
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    function activateInput(inputId) {
        console.log(inputId);
        document.getElementById(inputId).disabled = false;
    }

    function focusOnFirstInputOnLoad() {
        focusOnInput("digit1");
    }

    function focusOnInput(inputId) {
        document.getElementById(inputId).focus();
    }

    const handleChange = (event) => {
        if (event.target.value === '' || validVerificationNumber.test(event.target.value)) {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            })
            if (verificationNumberInputId.indexOf(event.target.id) + 1 <= 3) {
                if (event.target.value !== '') {
                    activateInput(verificationNumberInputId[verificationNumberInputId.indexOf(event.target.id) + 1]);
                    focusOnInput(verificationNumberInputId[verificationNumberInputId.indexOf(event.target.id) + 1]);
                }
            }
        }
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, false, "Check_verification_code"));
        setDataIsCorrect(true);
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Enter_personal_data") : approvePhoneNumber();
        }
    }, [errors]);
    const handleBackBtn = (event) => {
        event.preventDefault();
        setBackBtnClicked(true);
    }
    useEffect(() => {
        if (backBtnClicked) {
            switchToIndex("Phone_number_verification");
        }
    });

    function approvePhoneNumber() {
        let data = {
            phone_number: phoneNumber,
            sms_code: values.digit1+values.digit2+values.digit3+values.digit4,
            pinId: pinId
        }
        RestApi("/api/signup/sms/approve", "POST", data).then((resp) => {
            return resp.json()
        })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    switchToIndex("Enter_personal_data");
                } else {
                    setRequestError(message);
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    function resendVerificationCode() {
      let data = {phone_number: phoneNumber}
        RestApi("/api/signup/sms/send", "POST", data).then((resp) => {
            return resp.json()
        })
            .then(response => {
                let message = response["message"];
                if (response["status"] === 200) {
                    pinId = response["pinId"];
                } else {
                    if (response["errorId"] === 0) {
                        setRequestError(message);
                    } else if (response["errorId"] === 1) {
                        setRequestError(message);
                        switchToIndex("Enter_personal_data");
                    } else if (response["errorId"] === 2) {
                        setRequestError(message);
                    }
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    return (
        <main className="main" onLoad={focusOnFirstInputOnLoad}>
            <Navbar></Navbar>
            <div className="info_box">
                <div className="login_content register_window">
                    <div style={{display:"none"}} className="login_content_left">
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">1</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Telefon</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div style={{backgroundColor: "#f61"}}
                                 className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#fff"}} className="global_style_h2_light_text">2</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 className="global_style_h2_light_text">Verifizierung</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">3</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Kundendaten</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color: "#000"}} className="global_style_h2_light_text">4</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color: "#A0A0A0"}} className="global_style_h2_light_text">Bestätigen</h2>
                            </div>
                        </div>
                    </div>
                    <div className="login_content_right login_content_right_register">
                        <div className="login_content_right_frame login_content_right_frame_register">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Verifizierungscode</h3>
                            </div>
                            <div className="login_content_right_item">
                                <p style={{color:"#666"}} className="fliesstext_p">Du hast soeben per SMS einen vierstelligen Code
                                    erhalten. Bitte gib diesen unten ein, um Deine Telefonnummer zu bestätigen.</p>
                            </div>
                            <div style={{gap: "15px", justifyContent: "center", flexDirection: "row"}}
                                 className="login_content_right_item">
                                {/*
                                <input id="code" name="code" type="text" className="new_input-field" placeholder="Verifizierungscode" required value={values.code} onChange={handleChange}/>
                                */}
                                <div className="login_content_right_item_digit">
                                    <input id="digit1" name="digit1" className="login_content_right_item_digit_input"
                                           type="text" size={1} maxLength={1} value={values.digit1}
                                           onChange={handleChange} disabled={false}/>
                                </div>
                                <div className="login_content_right_item_digit">
                                    <input id="digit2" name="digit2" className="login_content_right_item_digit_input"
                                           type="text" size={1} maxLength={1} value={values.digit2}
                                           onChange={handleChange} disabled={true}/>
                                </div>
                                <div className="login_content_right_item_digit">
                                    <input id="digit3" name="digit3" className="login_content_right_item_digit_input"
                                           type="text" size={1} maxLength={1} value={values.digit3}
                                           onChange={handleChange} disabled={true}/>
                                </div>
                                <div className="login_content_right_item_digit">
                                    <input id="digit4" name="digit4" className="login_content_right_item_digit_input"
                                           type="text" size={1} maxLength={1} value={values.digit4}
                                           onChange={handleChange} disabled={true}/>
                                </div>

                            </div>
                            {errors.code &&
                            <div className="login_content_right_item">
                                <span className="error_span">{errors.code}</span>
                            </div>
                            }
                            <div style={{justifyContent: "center"}} className="login_content_right_item">
                                <div className="span_container_forgot_pass">
                                    <span className="my_span" onClick={resendVerificationCode}>Erneut senden!</span>
                                </div>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div className="login_content_right_item register_btn_field">
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} type="button"onClick={handleBackBtn} className="ueberschrift_btn">Zurück</button>
                                </div>
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button"onClick={handleFormSubmit} className="ueberschrift_btn">Weiter</button>
                                </div>


                            </div>
                            {requestError &&
                            <div className="login_content_right_item">
                                <span className="error_span">{requestError}</span>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default Check_verification_code;

