import React, {useEffect, useState} from 'react';
import './register.css';
import Navbar from "../common/Navbar/Navbar";
import {Footer} from "../common";
import validation from "../register/validation";
import {isTesting, screenWidth} from "../../App";
import RestApi from "../../RestAPI";

export var phoneNumber;
export var pinId;


function Phone_number_verification({switchToIndex}) {
    let validNumber = /^[1-9]\d*$/;
    const [checked, setChecked] = React.useState(false);
    const [values, setValues] = useState({
        telNum: ""
    })
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleChange = (event) =>{
        if (event.target.value === '' || validNumber.test(event.target.value)) {
            setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
        }
    }
    function focusOnPhoneNumberInputOnLoad() {
        document.getElementById("telNum").focus()
    }
    function handleCheckBoxChange(){
        setChecked(!checked);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, checked, "Phone_number_verification"));
        setDataIsCorrect(true);
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Check_verification_code") : verifyNumber();
        }
    }, [errors]);

    function verifyNumber() {
        let data = {phone_number: "+49" + values.telNum}
        RestApi("/api/signup/sms/send", "POST", data).then((resp) => {
            return resp.json()
        })
            .then(response => {
                let message = response["message"];
                if (response["status"] === 200) {
                    phoneNumber = data.phone_number;
                    pinId = response["pinId"]
                    switchToIndex("Check_verification_code");
                } else {
                    if (response["errorId"] === 0) {
                        setRequestError(message);
                    } else if (response["errorId"] === 1) {
                        phoneNumber = data.phone_number;
                        setRequestError(message);
                        switchToIndex("Enter_personal_data");
                    } else if (response["errorId"] === 2) {
                        setRequestError(message);
                    }
                }
            })
            .catch((err) => {
                setRequestError(err.toString());
            });
    }

    return (
        <main className="main" onLoad={focusOnPhoneNumberInputOnLoad}>
            <Navbar></Navbar>
            <div className="info_box">
                <div className="login_content register_window">
                    <div style={{display:"none"}} className="login_content_left">
                        <div className="login_content_left_item">
                            <div style={{backgroundColor:"#f61"}} className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#fff"}} className="global_style_h2_light_text">1</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#f61"}} className="global_style_h2_light_text">Telefon</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">2</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Verifizierung</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">3</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Kundendaten</h2>
                            </div>
                        </div>
                        <div className="login_content_left_item">
                            <div className="login_content_left_item_progress_bar_step">
                                <h2 style={{color:"#000"}} className="global_style_h2_light_text">4</h2>
                            </div>
                            <div className="login_content_left_item_progress_bar_text">
                                <h2 style={{color:"#A0A0A0"}} className="global_style_h2_light_text">Bestätigen</h2>
                            </div>
                        </div>
                    </div>
                    <div className="login_content_right login_content_right_register">
                        <div className="login_content_right_frame login_content_right_frame_register">
                            <div className="login_content_right_item">
                                <h3 className="zwischenueberschrift_h3">Willkommen</h3>
                            </div>
                            <div  className="login_content_right_item">
                                <p style={{color:"#666"}} className="fliesstext_p">Schön, dass Du die Welt ein Stück nachhaltiger machen willst.</p>
                            </div>
                            <div className="login_content_right_item login_content_right_item_num_with_prefix">
                                <div className="login_content_right_item_num_with_prefix_item">
                                    <p style={{fontSize:"17px"}} className="fliesstext_p">+49 (0)</p>
                                </div>
                                <div id="login_content_right_item_num_with_prefix_item_input" className="login_content_right_item_num_with_prefix_item">
                                    <input id="telNum" name="telNum" style={{marginTop: "0px", marginBottom: "0px"}} type="tel" className="text_input" placeholder={screenWidth.matches ? "Tel. beginnt mit 1" : "Telefonnummer beginnt mit 1"} defaultCountry="DE"
                                       value={values.telNum} onChange={handleChange} required/>
                                </div>
                            </div>
                            {errors.telNum &&
                                <div className="login_content_right_item">
                                    <span className="error_span">{errors.telNum}</span>
                                </div>
                            }

                            <div className="login_content_right_item login_content_right_item_checkbox">
                                <div className="login_content_right_item_left">
                                    <label>
                                        <input id="termsconds" name="termsconds" className="checkbox_input" type="checkbox" required checked={checked} onChange={handleCheckBoxChange}/>
                                        <span className="checkbox">
                                        </span>
                                    </label>
                                </div>
                                <div className="login_content_right_item_right">
                                    <p className="checkbox_text_p">Ich bin mit der Verifizierung meiner Telefonnummer einverstanden.</p>
                                </div>
                            </div>
                            {errors.checked &&
                                <div className="login_content_right_item">
                                    <span style={{textAlign:"center"}} className="error_span">{errors.checked}</span>
                                </div>
                            }
                            <div style={{flex:"1"}}></div>
                            <div style={{alignItems:"flex-end"}} className="login_content_right_item">
                                <div className="login_content_right_item_component">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={handleFormSubmit} className="ueberschrift_btn">Weiter</button>
                                </div>
                            </div>
                            {requestError &&
                                <div className="login_content_right_item">
                                    <span className="error_span">{requestError}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default Phone_number_verification;

