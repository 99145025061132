import React from "react";
import './reset_pass.css';
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";

function Confirmation({switchToIndex}) {
    const redirectToLogin = () => {
        window.location.href = "/login";
    }
    return (
        <div className="main">
            <Navbar/>
            <div className="reset_pass">
                <div className="reset_pass_content">
                    <div className="reset_pass_content_item">
                        <h3 className="zwischenueberschrift_h3">Bestätigung</h3>
                        <p style={{color:"#666"}} className="fliesstext_p">Dein Passwort wurde erfolgreich aktualisiert!</p>
                        <div style={{flex:"1"}}></div>
                        <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                            <div className="btn_field">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={redirectToLogin}>Zum Login</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Confirmation;
