import Login from './login';
import {MY_URL} from "../../App";
import RestApi from "../../RestAPI";


export default Login;

export const logout = () => {

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.href = MY_URL + "/start_seite";
    window.location.reload(false);
}

export const isLogin = () => {
    if (localStorage.getItem('token')) {
        return new Promise((resolve, reject) => {RestApi("/api/verify_token", "POST", {}).then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200){
                    resolve(true)
                }
                else {
                    resolve(false);
                    logout();
                }


            })
            .catch((err) => {
                // Error
                console.log(err)
            });
            })
    } else {
       return false;
    }

}

export const isAdmin = () => {
    if (localStorage.getItem('token')) {
        return new Promise((resolve, reject) => {RestApi("/api/verify_admin_token", "POST", {}).then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200){
                    resolve(true)
                }
                else {
                    resolve(false)
                }


            })
            .catch((err) => {
                // Error
                console.log(err)
            });
            })
    } else {
       return false;
    }

}
export {
    Login
}