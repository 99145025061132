import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import Info_icon from "../../static/insert_box/Info_grau.svg"
import {isTesting, MscreenWidth, screenWidth} from "../../App";
import validation from "./validation";
import RestAPI from "../../RestAPI";
import Tippy from '@tippy.js/react';
import PopUpWindow from "./PopUpWindow";

export var location;

const ToolTip =
        <div className="tootip_content">
            <ol>
                <li className="tootip_content_p">Wähle ein Fach in der richtigen Größe</li>
                    <ul>
                        <li className="tootip_content_p">Ausmessen des Gegenstandes (Höhe, Länge, Breite)</li>
                        <li className="tootip_content_p">Wähle die für Deinen Artikel kleinstmögliche Fachgröße</li>
                        <li className="tootip_content_p">Sollte eine Fachgröße belegt sein, wird automatisch das nächstgrößere Fach gebucht</li>
                    </ul>
                <li className="tootip_content_p">in der "Terminal-Auslastung" siehst Du, welches Fach frei ist</li>
            </ol>
        </div>


function Insert_box_location({switchToIndex, isLoggedInVar, isAdminVar}) {
    const [tempelhofChecked, setTempelhofChecked] = React.useState(false);
    const [adlershofChecked, setAdlershofChecked] = React.useState(false);

    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        location: ""
    })
    function handleTempelhofCheckBoxChange() {
        setTempelhofChecked(!tempelhofChecked);
        setAdlershofChecked(false);
    }
    function handleAdlershofCheckBoxChange() {
        setAdlershofChecked(!adlershofChecked);
        setTempelhofChecked(false)
    }



    const updateLocationValue = (event) => {
        setValues({
            ...values,
            location: event.target.value,
        })
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Insert_box_location", false, tempelhofChecked, adlershofChecked));
        location = values.location
        isTesting ? setDataIsCorrect(true) : /*checkBoxAvailability()*/setDataIsCorrect(true);
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Insert_box_package_dimensions") : switchToIndex("Insert_box_package_dimensions");
        }
    }, [errors, dataIsCorrect]);
/*
    function checkBoxAvailability() {

            let data =  {object_size: values.boxSize}
            RestAPI("/api/check_box_availability", "POST",data).then((resp) => {
                // Error handling
                return resp.json()
            })
            .then((response) => {
                if (response === -1) {
                    setErrors(validation(values, "Insert_box_package_dimensions", true, tempelhofChecked, adlershofChecked));
                } else {
                    boxNum = response;
                    objectSize = values.boxSize;
                    setDataIsCorrect(true);
                }

            })
            .catch((err) => {
                // Error
                setErrors(err);
            });
    }
 */

    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <div className="info_box">
                {/*<PopUpWindow></PopUpWindow>*/}
                <div className="insert_box_content">
                    {
                        MscreenWidth.matches ? <div><h2 className="untertitel_h2">Fachbuchung</h2></div> : null
                    }


                    <div className="insert_box_content_left">
                        <div style={MscreenWidth.matches ? {display:"none"} : null} className="insert_box_content_left_item">
                            <h2 className="untertitel_h2">Fachbuchung</h2>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div style={{backgroundColor: "#f61"}}
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#fff", fontWeight:"bold"}} className="teaser_h4">1</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {screenWidth.matches ?
                                <p style={{color:"#f61"}} className="fliesstext_p">Standort<br/>auswählen</p>
                                :
                                <h4 style={{color:"#f61", fontWeight:"bold"}} className="teaser_h4">Standort auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">2</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#fff"}} className="fliesstext_p">Fachgröße<br/>auswählen</p>
                                        :
                                    <h4 style={{color:"#fff", fontWeight:"bold"}} className="teaser_h4">Fachgröße auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">3</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Empfänger:in<br/>angeben</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Empfänger:in angeben</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">4</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Bestätigen</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Bestätigen</h4>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="insert_box_content_right">
                        <div className="insert_box_content_right_item">
                            <h2  className="zwischenueberschrift_h3">Standort</h2>
                            {/* <Tippy className="tool_tip" content={ToolTip}>
                                <img style={{paddingBottom:"15px"}} width="24px" src={Info_icon}/>
                                </Tippy> */}
                        </div>
                        <div className="insert_box_content_right_item">
                            <div className="package_dimensions_content_middle_item">
                                <h2 className="fliesstext_p">Kiez-Locker Tempelhof</h2>
                            </div>
                            <div className="package_dimensions_content_right_item">
                                <label>
                                    <input id="th" name="th" value={"TH"} className="checkbox_input" type="checkbox"
                                           checked={tempelhofChecked} onChange={handleTempelhofCheckBoxChange}
                                           onClick={updateLocationValue}/>
                                    <span className="checkbox">
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="insert_box_content_right_item">
                            <div className="package_dimensions_content_middle_item">
                                <h2 style={{fontWeight:"normal"}} className="fliesstext_p">Kiez-Locker Adlershof</h2>
                            </div>
                            <div className="package_dimensions_content_right_item">
                                <label>
                                    <input id="ah" name="ah" value={"AH"} className="checkbox_input" type="checkbox"
                                           checked={adlershofChecked}
                                           onChange={handleAdlershofCheckBoxChange} onClick={updateLocationValue}/>
                                    <span className="checkbox">
                                    </span>
                                </label>
                            </div>
                        </div>


                        {(errors.checked || errors.responseMessage) &&
                        <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                            <span className="error_span">{errors.checked ? errors.checked : errors.responseMessage}</span>
                        </div>
                        }
                        <div style={{flex:1}}></div>
                        <div style={{justifyContent: "flex-end", width:"100%"}} className="insert_box_content_right_item">
                            <div className="btn_field_one_btn">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Weiter</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Insert_box_location;
