import React, {useEffect, useRef, useState} from 'react';
import './add_post.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import download_img_icon from "../../static/market/Bild hinzufügen.svg"
import x_icon from "../../static/market/X im Kreis.svg"
import edit_icon from "../../static/market/Bild bearbeiten.svg"
import 'react-dropdown/style.css';
import Dropdown from "react-dropdown";
import RestApi from "../../RestAPI";
import validation from "./validation";
import {isTesting, MY_URL} from "../../App";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {images} from "../../Helpers/CarouselData";
import Loading_spinner from "../common/loading_spinner";
import Modal from "../modal";
import {log} from "qrcode/lib/core/galois-field";

let imgNum = 0;

function Add_post({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
    const [publishPostModalOpened, setPublishPostModalOpened] = useState(false);
    const [previewMode1, setPreviewMode1] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [errors, setErrors] = useState({})
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handlePreview = (event) => {
        event.preventDefault();
        setErrors(validation(values, checked, category, district, "Post_preview"));
        setDataIsCorrect(true);
    }
    const [images, setImages] = useState([])
    const [currImg, setCurrImg] = useState(0);
    const currentDate = new Date();

    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            setPreviewMode1(true);
            //handleUploadImage()
        }
    }, [errors]);


    const RubrikOptions = [
        'Rubrik (Alle)', 'Möbel/Hausrat', 'Haushaltsgeräte', 'Computer/HiFi', 'Telefon/Fax', 'Bücher', 'Hobby',
        'Für Kinder', 'Kleidung/Schuhe', 'Garten/Bauen', 'Materialreste', 'Sonstiges'
    ];
    const BezirkOptions = [
        'Standort (Alle)', 'Tempelhof', 'Adlershof'
    ];
    const rubrikDefaultOption = RubrikOptions[0];
    const bezirkDefaultOption = BezirkOptions[0];
    const inputFile1 = useRef(null)
    const inputFile2 = useRef(null)
    const inputFile3 = useRef(null)
    const inputFile4 = useRef(null)
    const [fileData1, setFileData1] = useState(null);
    const [fileData2, setFileData2] = useState(null);
    const [fileData3, setFileData3] = useState(null);
    const [fileData4, setFileData4] = useState(null);
    const [postCategory, setPostCategory] = useState("");
    const [category, setCategory] = useState("");
    const [district, setDistrict] = useState("");
    const [checked, setChecked] = React.useState("");
    const [values, setValues] = useState({
        title: "",
        description: ""
    })

    const onClickUpload1 = () => {
        inputFile1.current.click();
    };
    const onClickdelete1 = () => {
        setFileData1(null);
        images.splice(0);
    };
    const onClickUpload2 = () => {
        inputFile2.current.click();
    };
    const onClickdelete2 = () => {
        setFileData2(null);
        images.splice(1);
    };
    const onClickUpload3 = () => {
        inputFile3.current.click();
    };
    const onClickdelete3 = () => {
        setFileData3(null);
        images.splice(2);
    };
    const onClickUpload4 = () => {
        inputFile4.current.click();
    };
    const onClickdelete4 = () => {
        setFileData4(null);
        images.splice(3);
    };

    const handleCheckBox = (event) => {
        setPostCategory(event.target.value);
        setChecked(true);
    };
    const handleInput = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    };

    const [inputFields, setInputFields] = useState([
        {id: imgNum.toString(), firstName: '', lastName: ''},
    ]);

    function handleUploadImage() {
        setRestQueryIsLoading(true);
        const data = new FormData();
        let infos = {
            "postCategory": postCategory,
            "checked": checked,
            "category": category,
            "district": district,
            "title": values.title,
            "description": values.description,
            "imageNames": {
                "image1": fileData1 ? "0"+fileData1.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image2": fileData2 ? "1"+fileData2.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image3": fileData3 ? "2"+fileData3.name.toString().replace(/[^a-zA-Z0-9]/g, '') : "",
                "image4": fileData4 ? "3"+fileData4.name.toString().replace(/[^a-zA-Z0-9]/g, '') : ""
            }
        }
        data.append('files', fileData1);
        data.append('files', fileData2);
        data.append('files', fileData3);
        data.append('files', fileData4);
        data.append('data', JSON.stringify(infos));
        RestApi("/api/add_post", 'POST', data, true
        ).then((response) => {
            response.json().then(() => {
                if (response["status"] === 200){
                    setRestQueryIsLoading(false);
                    setPublishPostModalOpened(true);
                }
                else {
                    //TODO
                }
            });
        });
    }

    function switchToStart(){
        switchToIndex("Start_page");
    }

    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="add_post_screen">
                {
                    previewMode1 ?
                        <div className="details_page_content">
                            <div style={{padding: "0px"}} className="details_page_content_item">
                                {
                                    images.length !== 0 ?
                                        <div className="carousel">
                                            <div className="carouselInner" style={{backgroundImage: `url(${images[currImg]})`, backgroundSize: "contain"}}>
                                                <div className={currImg === 0 ? "left_last" : "left"} onClick={() => {
                                                    currImg > 0 && setCurrImg(currImg - 1);
                                                }}>
                                                    {currImg === 0 ? null : <ArrowBackIosIcon style={{fontSize: 30}}/>}
                                                </div>
                                                <div className="center">
                                                </div>
                                                <div className={currImg === images.length - 1 ? "right_last" : "right"}
                                                     onClick={() => {
                                                         currImg < images.length - 1 && setCurrImg(currImg + 1);
                                                     }}>
                                                    {currImg === images.length - 1 ? null :
                                                        <ArrowForwardIosIcon style={{fontSize: 30}}/>}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="carousel_no_images">
                                            <p className={"fliesstext_p"}>Keine Bilder vorhanden</p>
                                        </div>
                                }

                            </div>
                            <div className="details_page_content_item details_page_content_item_white_bg">
                                <div
                                    className="details_page_content_item_post_details details_page_content_item_post_details_date_district">
                                    <p className={"tootip_content_p_gray"}>{currentDate.getDate().toString() + "." + (currentDate.getMonth() + 1).toString() + "." + currentDate.getFullYear()}</p>
                                    <p className={"tootip_content_p_gray"}>{category}</p>
                                    {(isTesting || isAdminVar) &&
                                        <p className={"tootip_content_p_gray"}>{district}</p>
                                    }

                                </div>
                                <div className="details_page_content_item_post_details">
                                    <p className={"fliesstext_p"} style={{color: "#f61"}}>{
                                        postCategory === "Give" ? "Weitergeben"
                                            :
                                            "Suchen"}
                                    </p>
                                </div>
                                <div className="details_page_content_item_post_details">
                                    <h4 className={"teaser_h4"} style={{color: "#000"}}>{values.title}</h4>
                                </div>
                                <div className="details_page_content_item_post_details">
                                    <p className={"fliesstext_p"} style={{color: "#a0a0a0"}}>{"Beschreibung"}</p>
                                    <p className={"fliesstext_p"}>{values.description}</p>
                                </div>
                                <div style={{flex: "1"}}></div>
                                <div
                                    className="details_page_content_item_post_details details_page_content_item_post_details_btn_field">
                                    <button style={{"--btn_font_color": "#ffffff", "--btn_background_color": "#a0a0a0"}}
                                            onClick={() => setPreviewMode1(false)} className="ueberschrift_btn">Zurück
                                    </button>
                                    <button style={{
                                        "--btn_font_color": "#ffffff",
                                        "--btn_background_color": "#f61"
                                    }}  onClick={() => isTesting ? setPublishPostModalOpened(true) : handleUploadImage()}
                                            className="ueberschrift_btn">Hinzufügen
                                    </button>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="add_post_content">
                            <div className="add_post_content_item add_post_content_item_header">
                                <h2 className="untertitel_h2">{
                            isAdminVar ? "Kiez-Locker Marktplatz" : "Kiez-Locker Marktplatz Tempelhof"
                        }</h2>
                                <h4 className="teaser_h4">{"Inserat hinzufügen"}</h4>
                            </div>
                            <div className="add_post_content_item">
                                <p style={{paddingBottom: "15px"}}
                                   className="fliesstext_p">{"Bilder auswählen"}</p>
                                <div className="download_img_field">
                                    <div className="download_img_field_scope">
                                        <div className="download_img_field_scope_item">
                                            <img id="uploadedImage1" onClick={onClickUpload1}
                                                 src={fileData1 ? URL.createObjectURL(fileData1) : download_img_icon}/>
                                            <input type="file" id="file" onChange={(event) => {
                                                setFileData1(event.target.files[0]);
                                                images[0] = URL.createObjectURL(event.target.files[0]);
                                                document.getElementById("uploadedImage1").src = URL.createObjectURL(event.target.files[0])
                                            }}
                                                   accept="image/png, image/jpeg" ref={inputFile1}
                                                   style={{display: "none"}}/>
                                            {
                                                fileData1 ?
                                                    <div className={"download_img_field_scope_item_overlap"}>
                                                        <img id="uploadedImage1" className={"download_img_field_scope_item_overlap_img"} onClick={onClickdelete1} src={x_icon}/>
                                                        <img className={"download_img_field_scope_item_overlap_img"} onClick={onClickUpload1} src={edit_icon}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="download_img_field_scope_item">
                                            <img id="uploadedImage2" onClick={onClickUpload2}
                                                 src={fileData2 ? URL.createObjectURL(fileData2) : download_img_icon}/>
                                            <input type="file" id="file" onChange={(event) => {
                                                setFileData2(event.target.files[0]);
                                                images[1] = URL.createObjectURL(event.target.files[0]);
                                                document.getElementById("uploadedImage2").src = URL.createObjectURL(event.target.files[0])
                                            }}
                                                   accept="image/png, image/jpeg" ref={inputFile2}
                                                   style={{display: "none"}}/>
                                            {
                                                fileData2 ?
                                                    <div className={"download_img_field_scope_item_overlap"}>
                                                        <img className={"download_img_field_scope_item_overlap_img"} id="uploadedImage2"  onClick={onClickdelete2} src={x_icon}/>
                                                        <img className={"download_img_field_scope_item_overlap_img"} onClick={onClickUpload2} src={edit_icon}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="download_img_field_scope_item">
                                            <img id="uploadedImage3" onClick={onClickUpload3}
                                                 src={fileData3 ? URL.createObjectURL(fileData3) : download_img_icon}/>
                                            <input type="file" id="file" onChange={(event) => {
                                                setFileData3(event.target.files[0]);
                                                images[2] = URL.createObjectURL(event.target.files[0]);
                                                document.getElementById("uploadedImage3").src = URL.createObjectURL(event.target.files[0])
                                            }}
                                                   accept="image/png, image/jpeg" ref={inputFile3}
                                                   style={{display: "none"}}/>
                                            {
                                                fileData3 ?
                                                    <div className={"download_img_field_scope_item_overlap"}>
                                                        <img className={"download_img_field_scope_item_overlap_img"} id="uploadedImage3"  onClick={onClickdelete3} src={x_icon}/>
                                                        <img className={"download_img_field_scope_item_overlap_img"} onClick={onClickUpload3} src={edit_icon}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="download_img_field_scope_item">
                                            <img id="uploadedImage4" onClick={onClickUpload4}
                                                 src={fileData4 ? URL.createObjectURL(fileData4) : download_img_icon}/>
                                            <input type="file" id="file" onChange={(event) => {
                                                setFileData4(event.target.files[0]);
                                                images[3] = URL.createObjectURL(event.target.files[0]);
                                                document.getElementById("uploadedImage4").src = URL.createObjectURL(event.target.files[0])
                                            }}
                                                   accept="image/png, image/jpeg" ref={inputFile4}
                                                   style={{display: "none"}}/>
                                            {
                                                fileData4 ?
                                                    <div className={"download_img_field_scope_item_overlap"}>
                                                        <img className={"download_img_field_scope_item_overlap_img"} id="uploadedImage4" onClick={onClickdelete4} src={x_icon}/>
                                                        <img className={"download_img_field_scope_item_overlap_img"} onClick={onClickUpload4} src={edit_icon}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add_post_content_item">
                                <p style={{paddingBottom: "15px"}} className="fliesstext_p">{"Daten eingeben"}</p>
                                <div className="insert_data_field">
                                    <div className="insert_data_field_scope">
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Kategorie</p>
                                            <div className="checkbox_group">
                                                <div className="checkbox_component">
                                                    <div className="insert_data_field_item_left">
                                                        <label>
                                                            <input id="toMe" name="toMe" value={"Give"}
                                                                   className="checkbox_input" type="radio"
                                                                   required
                                                                   onClick={(event) => handleCheckBox(event)}
                                                                   checked={postCategory === "Give"}
                                                                   defaultChecked={checked}/>
                                                            <span className="checkbox"></span>
                                                        </label>
                                                    </div>
                                                    <div className="insert_data_field_item_right">
                                                        <p style={{color: "#000000"}}
                                                           className="checkbox_text_p">Weitergeben</p>
                                                    </div>
                                                </div>

                                                <div className="checkbox_component">
                                                    <div className="insert_data_field_item_left">
                                                        <label>
                                                            <input id="toMe2" name="toMe"
                                                                   className="checkbox_input"
                                                                   value={"Search"} onClick={handleCheckBox}
                                                                   type="radio"
                                                                   required
                                                                   checked={postCategory === "Search"}/>
                                                            <span className="checkbox">
                                                    </span>
                                                        </label>
                                                    </div>
                                                    <div className="insert_data_field_item_right">
                                                        <h2 style={{color: "#000000"}}
                                                            className="checkbox_text_p">Suchen</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {errors.checked &&
                                                <span className="error_span">{errors.checked}</span>
                                            }
                                        </div>
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Rubrik</p>
                                            <Dropdown name="category" onChange={(e) => setCategory(e.value)}
                                                      className="market_dropdown"
                                                      controlClassName="add_post_dropdown"
                                                      arrowClassName="add_post_dropdown_arrow"
                                                      options={RubrikOptions}
                                                      placeholder="Bitte wählen"
                                                      value={category ? RubrikOptions[RubrikOptions.indexOf(category)] : rubrikDefaultOption}
                                            />
                                            {
                                                errors.category &&
                                                <span className="error_span">{errors.category}</span>
                                            }
                                        </div>
                                        <div className="insert_data_field_item">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Standort</p>
                                            <Dropdown name="category" onChange={(e) => setDistrict(e.value)}
                                                      className="market_dropdown"
                                                      controlClassName="add_post_dropdown"
                                                      arrowClassName="add_post_dropdown_arrow"
                                                      options={BezirkOptions}
                                                      placeholder="Bitte wählen"
                                                      value={district ? BezirkOptions[BezirkOptions.indexOf(district)] : bezirkDefaultOption}
                                            />
                                            {
                                                errors.category &&
                                                <span className="error_span">{errors.category}</span>
                                            }
                                        </div>
                                        <div className="insert_data_field_item insert_data_field_item_titel_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Titel</p>
                                            <input className="text_input" defaultValue={values.title} name="title"
                                                   maxLength={200} onChange={handleInput}/>
                                            {
                                                errors.title &&
                                                <span className="error_span">{errors.title}</span>
                                            }
                                        </div>
                                        <div
                                            className="insert_data_field_item insert_data_field_item_description_input">
                                            <p style={{paddingBottom: "15px", color: "#a0a0a0"}}
                                               className="fliesstext_p">Beschreibung</p>
                                            <textarea name={"description"}  defaultValue={values.description}
                                                     onChange={handleInput} maxLength={5000}></textarea>
                                            <div>
                                                <p>{values.description.length}/5000 Character</p>
                                            </div>
                                            {
                                                errors.description &&
                                                <span className="error_span">{errors.description}</span>
                                            }
                                        </div>
                                        <div className="insert_data_field_item add_post_btn_placement">
                                            <button style={{
                                                "--btn_font_color": "#ffffff",
                                                "--btn_background_color": "#f61"
                                            }} onClick={handlePreview} className="ueberschrift_btn">Vorschau
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            <Modal isOpened={publishPostModalOpened} onClose={() => setPublishPostModalOpened(false)}>
                        <div className="modal_group">
                            <div className="modal_group_item">
                                <h4 className="teaser_h4_bold">Dein Inserat wird nach Überprüfung veröffentlicht</h4>
                            </div>
                            <div style={{flex:"1"}}></div>
                            <div style={{justifyContent:"flex-end", flexDirection:"row"}} className="modal_group_item">
                                <div className="btn_section">
                                    <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" onClick={() => switchToStart()} className="ueberschrift_btn">Fertig</button>
                                </div>
                            </div>
                        </div>
            </Modal>
            <Footer/>
        </main>
    )
}

export default Add_post;

