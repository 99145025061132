import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import Info_icon from "../../static/insert_box/Info_grau.svg"
import {isTesting, MscreenWidth, screenWidth} from "../../App";
import validation from "./validation";
import RestAPI from "../../RestAPI";
import Tippy from "@tippy.js/react";

export var recipient_id;
export var recipient;
export var booking_title;
export var tome;
export var donateForNochMall;

const ToolTip =
        <div className="tootip_content">
            <p style={{fontWeight:"bold"}} className="tootip_content_p">Nutzer:in ohne Kiez-Locker-Account:</p>
            <p style={{paddingLeft:"15px"}} className="tootip_content_p">Hier musst Du den Abhol-Code selbst an den/die Empfänger:in übermitteln.</p>
            <p style={{paddingLeft:"15px"}} className="tootip_content_p">Für die sichere Datenübermittlung und Haftung bist Du als Nutzer:in vollständig verantwortlich.</p>
            <p style={{fontWeight:"bold"}} className="tootip_content_p">Nutzer:in mit Kiez-Locker-Account:</p>
            <p style={{paddingLeft:"15px"}} className="tootip_content_p">Der Abholcode wird automatisch weitergeleitet, wenn Du den Nutzernamen des/der Empängers:in angibst.</p>
            <p style={{fontWeight:"bold"}} className="tootip_content_p">Weitergebe an NochMall:</p>
            <p style={{paddingLeft:"15px"}} className="tootip_content_p">Hier kannst Du Deinen Artikel direkt an die NochMall Weitergeben.</p>
        </div>

function Insert_box_recipient({switchToIndex, isLoggedInVar, isAdminVar}) {
    const [values, setValues] = useState({
        userName: "",
        title: ""
    })


    const [isHidden, setIsHidden] = React.useState(true);
    const [toMeChecked, setToMeChecked] = React.useState(false);
    const [toOtherChecked, setToOtherChecked] = React.useState(false);
    const [toNochMallChecked, setToNochMallChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [backBtnClicked, setBackBtnClicked] = useState(false);

    const handleBackBtn = (event) => {
        event.preventDefault();
        setBackBtnClicked(true);
    }

    useEffect(()=>{
        if (sessionStorage.getItem("Booking")){
            handleToOtherCheckBoxChange()
            setValues({
                ...values,
                userName: JSON.parse(sessionStorage.getItem("Booking")).username,
                title : JSON.parse(sessionStorage.getItem("Booking")).bookingTitle

            })
           }
    },[])
    useEffect(() =>{
        if (backBtnClicked){
            switchToIndex("Insert_box_package_dimensions");
        }
    });


    function handleToMeCheckBoxChange() {
        setToMeChecked(!toMeChecked);
        setToOtherChecked(false)
        setToNochMallChecked(false)
        setIsHidden(true);
    }
    function handleToNochMallCheckBoxChange() {
        setToNochMallChecked(!toNochMallChecked)
        setToMeChecked(false);
        setToOtherChecked(false)
        setIsHidden(true);
    }

    function handleToOtherCheckBoxChange() {
        setToOtherChecked(!toOtherChecked);
        setToMeChecked(false)
        setIsHidden(false);
        setToNochMallChecked(false)
    }

    const handleChange = (event) => {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            })
        }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Insert_box_recipient", isHidden, toMeChecked, toOtherChecked, false, toNochMallChecked));
        isTesting || toMeChecked || toNochMallChecked ? setDataIsCorrect(true) : checkIfUsernameExists();
        toMeChecked ? tome = true : tome = false;
        toNochMallChecked ? donateForNochMall = true : donateForNochMall = false

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            booking_title = values.title
            isTesting ? switchToIndex("Insert_box_selection", toMeChecked) : switchToIndex("Insert_box_selection");
        }
    }, [errors, dataIsCorrect]);

    function checkIfUsernameExists() {
        let data = {username : values.userName, title: values.title}
        RestAPI("/api/check_if_username_exists", "POST", data).then((resp) => {
            // Error handling
            return resp.json()
        })
            .then((response) => {
                let message = response["message"];
                if (response["status"] === 200) {
                    recipient_id = response["recipient_id"];
                    recipient = response["username"];
                    booking_title = values.title
                    setDataIsCorrect(true);
                } else {
                    setErrors(validation(values, "Insert_box_recipient", isHidden, toMeChecked, toOtherChecked, true));
                    //updateModal(true, "Registrierungsfehler", message, "Nochmal versuchen", "Nochmal versuchen", "", true, false, false, false);
                }

            })
            .catch((err) => {
            });
    }

    return (
        <main className="main">
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="info_box">
                <div className="insert_box_content">
                    {
                        MscreenWidth.matches ? <div><h2 className="untertitel_h2">Fachbuchung</h2></div> : null
                    }

                    <div className="insert_box_content_left">
                        <div style={MscreenWidth.matches ? {display:"none"} : null} className="insert_box_content_left_item">
                            <h2 className="untertitel_h2">Fachbuchung</h2>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">1</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {screenWidth.matches ?
                                <p style={{color:"#ffffff"}} className="fliesstext_p">Standort<br/>auswählen</p>
                                :
                                <h4 style={{color:"#ffffff", fontWeight:"bold"}} className="teaser_h4">Standort auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">2</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#fff"}} className="fliesstext_p">Fachgröße<br/>auswählen</p>
                                        :
                                    <h4 style={{color:"#fff", fontWeight:"bold"}} className="teaser_h4">Fachgröße auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div style={{backgroundColor: "#f61"}}
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#fff", fontWeight:"bold"}} className="teaser_h4">3</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#f61"}} className="fliesstext_p">Empfänger:in<br/>angeben</p>
                                        :
                                    <h4 style={{color: "#f61", fontWeight:"bold"}} className="teaser_h4">Empfänger:in angeben</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">4</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Bestätigen</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Bestätigen</h4>
                                }
                            </div>
                        </div>
                    </div>


                    <div className="insert_box_content_right">
                        <div className="insert_box_content_right_item">
                            <h3 className="zwischenueberschrift_h3"> Empfänger:in</h3>
                            <Tippy className="tool_tip" content={ToolTip}>
                                <img style={{paddingBottom:"15px"}} width="24px" src={Info_icon}/>
                            </Tippy>
                        </div>
                        <div className="insert_box_content_right_item">
                            <div className="insert_box_content_right_item_left">
                                <label>
                                    <input id="toMe" name="toMe" className="checkbox_input" type="checkbox" required
                                           checked={toMeChecked} onChange={handleToMeCheckBoxChange}/>
                                    <span className="checkbox">
                                    </span>
                                </label>
                            </div>
                            <div className="insert_box_content_right_item_right">
                                <p className="fliesstext_p">an mich</p>
                            </div>
                        </div>
                        <div className="insert_box_content_right_item">
                            <div className="insert_box_content_right_item_left">
                                <label>
                                    <input id="toOther" name="toOther" className="checkbox_input" type="checkbox"
                                           required checked={toOtherChecked} onChange={handleToOtherCheckBoxChange}/>
                                    <span className="checkbox">
                                    </span>
                                </label>
                            </div>
                            <div className="insert_box_content_right_item_right">
                                <p className="fliesstext_p">an andere/n Nutzer:in</p>
                            </div>
                        </div>
                        <div className="insert_box_content_right_item">
                            <div className="insert_box_content_right_item_left">
                                <label>
                                    <input id="toNochMall" name="toNochMall" className="checkbox_input" type="checkbox"
                                           required checked={toNochMallChecked} onChange={handleToNochMallCheckBoxChange}/>
                                    <span className="checkbox">
                                    </span>
                                </label>
                            </div>
                            <div className="insert_box_content_right_item_right">
                                <p className="fliesstext_p">Weitergeben an NochMall</p>
                            </div>
                        </div>
                        {errors.checked &&
                            <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                                <span className="error_span">{errors.checked}</span>
                            </div>
                        }
                        <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                            {toOtherChecked ?
                                <input id="username" name="userName" type="text"
                                   className={isHidden ? "insert_box_input_field_hidden" : "text_input"}
                                   placeholder="Nutzername" required disabled={toMeChecked}
                                   size={8} maxLength={8}
                                   value={values.userName} onChange={handleChange}/> : null
                            }
                        </div>
                        {(errors.userData || errors.responseMessage) &&
                            !isHidden &&
                            <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                                <span className="error_span">{errors.userData ? errors.userData : errors.responseMessage}</span>
                            </div>
                        }
                        <div className="insert_box_content_right_item">
                            <h2 style={{color:"#666"}} className="fliesstext_p">Artikelname (optional):</h2>
                        </div>
                        <div className="insert_box_content_right_item">
                            <input id="titel" name="title" type="text"
                                   className="text_input" onChange={handleChange}
                                   value={values.title}
                                   placeholder="Artikelname" required
                            />
                        </div>
                        <div className="insert_box_btn_field">
                            <div className="btn_field_one_btn">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleBackBtn}>Zurück</button>
                            </div>
                            <div className="btn_field_one_btn">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Weiter</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Insert_box_recipient;
