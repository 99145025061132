import React, {useEffect, useState} from "react";
import "./Carousel.css";
import {images} from "../../Helpers/CarouselData";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isAdmin, isLogin} from "../login";

function Carousel() {
  const [currImg, setCurrImg] = useState(0);
  const [isAdminVar, setIsAdminVar] = useState(false);
  const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
        data().catch(console.error);
    }, []);
  return (
      <main className="main">
          <Navbar admin={isAdminVar} logged={isLoggedInVar} />
          <div className="info_fox_green_background">
                <div style={{marginLeft:"10px", alignItems:"left", justifyContent:"left", marginTop:"0px", flexDirection:"row"}} className="flex_container">
                    <div style={{margin:"0px"}} className="flex_item">
                        <h1 style={{marginBottom:"0px", marginTop:"0px"}} className="global_style_h1_huge">Konsortium</h1>
                        <h2 style={{marginBottom:"0px", fontSize:"24px"}} className="global_style_h2">Unsere Projektpartner auf einen Blick</h2>
                    </div>
                </div>
            </div>
          <div className="partner">
        <div className="title_partner">
        </div>
            <div className="carousel">
              <div className="carouselInner" style={{ backgroundImage: `url(${images[currImg].img})` }}>
                <div className={currImg == 0 ? "left_last" : "left"} onClick={() => {currImg > 0 && setCurrImg(currImg - 1);}}>
                    {currImg == 0 ? null : <ArrowBackIosIcon style={{ fontSize: 30 }} />}
                </div>
                <div className="center">
                </div>
                <div className={currImg == images.length - 1 ? "right_last" : "right"} onClick={() => {currImg < images.length - 1 && setCurrImg(currImg + 1);}}>
                    {currImg == images.length - 1 ? null : <ArrowForwardIosIcon style={{ fontSize: 30 }} />}
                </div>
              </div>
            </div>
          <div className="inner_footer_partner">
              <div style={{flex: 1, textAlign: currImg == 0 ? "left" : "center", display:"block", marginBlockStart: "0.83em", marginBlockEnd: "0.83em", marginInlineStart: "0px", marginInlineEnd: "0px"}}>
                  <img style={{width: "255px", height: "auto"}} src={images[currImg].logo}/>
              </div>
              {
                  currImg == 0 ?
                      <div style={{flex: 1}}>
                          <h2 style={{marginBottom: "0px", textAlign: "left"}} className="global_style_h2_light_text">{images[currImg].role}</h2>
                          <p style={{fontSize: "1em", textAlign: "left", fontWeight: "bold"}} className="global_style_p_light_text">{images[currImg].subtitle}</p>
                      </div>
                      :
                      null
              }

          </div>
          </div>
          <Footer></Footer>
      </main>
  );
}

export default Carousel;
