import React, {useEffect, useState} from 'react';
import './admin_page.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import locker_icon from "../../static/admin/Locker_grau.svg"
import Box_selection from "../box_selection";
import Modal from "../modal";
import $ from "jquery";
import {isTesting, LscreenWidth, MscreenWidth} from "../../App";
import RestAPI from "../../RestAPI";
import {isAdmin, isLogin} from "../login";
import Post from "../market/post";


function Release_posts({/*switchToIndex, isAdminVar, isLoggedInVar*/}) {

    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
        }
    await data()
    }, []);

    const [posts, setPosts] = useState([]);
    useEffect(()=>{
        getPosts();
    },[])
    function getPosts() {
        RestAPI("/api/get_posts_admin", "GET", {})
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                if (response["status"] === 200) {
                    setPosts(response["posts"])
                } else {
                    //TODO
                    // @Oday
                    // Error Modal
                }

            })
    }

    const my_posts = [
        {"id": 12345678901234567890, "date": "03.10.2022", "views": "25", "title": "Tassen", "type": "Tauschen", "image" : null,"standort": "Adlershof"},
        {"id": 2, "date": "03.10.2022", "views": "12", "title": "Schreibtisch", "type": "Weitergeben", "image" : null,"standort": "Tempelhof"},
        {"id": 3, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht", "image" : null,"standort": "Adlershof"},
        {"id": 4, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht", "image" : null,"standort": "Adlershof"},
        {"id": 5, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht", "image" : null,"standort": "Tempelhof"},
        {"id": 6, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht", "image" : null,"standort": "Adlershof"},
        {"id": 7, "date": "03.10.2022", "views": "6", "title": "Bücher", "type": "Gesucht", "image" : null,"standort": "Tempelhof"},
    ];
    return (
        <main className="main" >
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="admin_page_content admin_page_content_gray_bg">
                <div className="admin_page_content_release_posts_section">
                    <h2 className={"untertitel_h2"}>Freizugebende Inserate</h2>
                    <div className={"posts"}>
                        {
                            isTesting ?
                                my_posts.map(function (d) {
                                    return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                  type={d.type} path={d.image} id={d.id} standort={d.standort}/>)
                                })
                                :
                                posts.map(function (d) {
                                    return (<Post key={d.id} mine={true} date={d.date} views={d.views} title={d.title}
                                                  type={d.type} path={d.image} id={d.id} standort={d.standort}/>)
                                })
                        }
                    </div>


                </div>
            </div>
            <Footer/>



        </main>
    )
}
export default Release_posts;
