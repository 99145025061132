import React from 'react';
import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";

function Loading_spinner({isRunning}) {
    const useStyles = makeStyles((theme) => ({
        backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    }));
    const classes=useStyles();
    return (
        isRunning ?
            <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
            </Backdrop>
            :
            null
    )
}

export default Loading_spinner;