import React, { useEffect, useState } from "react";
import RestApi from "../../RestAPI";
import Navbar from "../common/Navbar/Navbar";
import analytics_icon from "../../static/admin/analytics.svg";
import Box_selection from "../box_selection";
import {isTesting, LscreenWidth, MscreenWidth} from "../../App";
import {Footer} from "../common";
import Modal from "../modal";
import {isAdmin, isLogin} from "../login";

function Analytics() {
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(async () => {
        const data = async () => {
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(true)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(true)
        }
    await data()
    }, []);
  const [page, setPage] = useState("");
  const [views, setViews] = useState(0);
  const [date, setDate] = useState("");
  const [total, setTotal] = useState(0);
  // get todays page views from the database
  useEffect(() => {
    RestApi("/api/get_pageview", "GET", {})
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        setPage(response["page"]);
        setViews(response["views"]);
        setDate(response["date"]);
        setTotal(response["total"])
      });
  }, []);

  return (
      <main className="main" >
            <Navbar logged={isLoggedInVar} admin={isAdminVar}/>
            <div className="admin_page_content">
                <div className="admin_page_content_item admin_page_content_locker_load">
                    <div>
                        <img src={analytics_icon}/>
                    </div>
                    <div>
                        <h1 className={"titel_h1"}>Statistiken</h1>
                    </div>
                </div>
                <div id={"test"} className="admin_page_content_item admin_page_content_item_main_field_background">
                    <div className={"admin_page_content_item_analytics"}>
                        <div className={"admin_page_content_item_analytics_item"}>
                            <h4 className={"teaser_h4"}>Seitenaufrufe - Heute</h4>
                            <p className={"fliesstext_p_bold"}>Seite: {page}</p>
                            <p className={"fliesstext_p_bold"}>Aufrufe: {views}</p>
                            <p className={"fliesstext_p_bold"}>Datum: {date}</p>
                            <h4 className={"teaser_h4"}>Gesamtaufrufe</h4>
                            <p className={"fliesstext_p_bold"}>Total: <span style={{color:"#f61"}}>{total}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
  );
}

export default Analytics;
