import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import Insert_box_recipient from "./insert_box_recipient";
import Insert_box_package_dimensions from "./insert_box_package_dimensions";
import Insert_box_period from "./insert_box_period";
import Insert_box_selection from "./insert_box_selection";
import Insert_box_confirmation from "./insert_box_confirmation";
import {isAdmin, isLogin} from "../login";
import Insert_box_location from "./insert_box_location";

function Insert_box() {
    const [index, setIndex] = useState("Insert_box_location");
    const switchToIndex = (index) => {
        setIndex(index);
    };
    const [isAdminVar, setIsAdminVar] = useState(false);
    const [isLoggedInVar, setIsLoggedInVar] = useState(false);
    useEffect(() => {
        const data = async () => {
            //await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
            await isLogin() ? setIsLoggedInVar(true) : setIsLoggedInVar(false)
            await isAdmin() ? setIsAdminVar(true) : setIsAdminVar(false)
        }
        data().catch(console.error);
    }, []);




    switch (index) {
        case "Insert_box_location":
            return <Insert_box_location switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Insert_box_recipient":
            return <Insert_box_recipient switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>;
        case "Insert_box_package_dimensions":
            return <Insert_box_package_dimensions switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                                  isLoggedInVar={isLoggedInVar}/>;
        case "Insert_box_period":
            return <Insert_box_period switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                      isLoggedInVar={isLoggedInVar}/>;
        case "Insert_box_selection":
            return <Insert_box_selection switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                         isLoggedInVar={isLoggedInVar}/>
        case "Insert_box_confirmation":
            return <Insert_box_confirmation switchToIndex={switchToIndex} isAdminVar={isAdminVar}
                                            isLoggedInVar={isLoggedInVar}/>
        default:
            return null;
    }
}

export default Insert_box;
