const validation = (values, index) => {
    let errors={};

    if (index === "Insert_password") {
        if (!values.pass) {
            errors.pass = "Passwort ist erforderlich";
        }else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9])(.{8,50})$/.test(values.pass)) {
            errors.pass = "Passwort ist zu schwach, das Passwort sollte mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.";
        }
        if (!values.rpass) {
            errors.rpass = "Passwort ist erforderlich";
        }
        if (values.rpass !== values.pass) {
            errors.rpass = "Passwörter stimmen nicht überein";
        }
    }
    return errors;
}

export default validation;