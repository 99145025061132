import React, {useState} from 'react';
import Insert_password from "./insert_password";
import Confirmation from "./confirmation";


function Reset_pass_v1() {
    const [index, setIndex] = useState("Insert_password");
    const switchToIndex = (index) => {
        setIndex(index);
    };

    switch (index) {
        case "Insert_password":
            return <Insert_password switchToIndex={switchToIndex}/>;
        case "Confirmation":
            return <Confirmation switchToIndex={switchToIndex}/>;
        default:
            return <Insert_password/>;
    }
}

export default Reset_pass_v1;
