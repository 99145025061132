import React from 'react';
import './footer.css';

function Footer () {
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    return (
        <div className="footer">
            <div className="footerBottom">
                <p className="footer_text">
                    <span className="footer_text">{"© " + currentDate.getFullYear() + " Berliner Stadtreinigungsbetriebe"}</span>
                    <br/>
                    <a className="footer_text" href="/data_protection_policy" target="_blank">Datenschutzerklärung</a> | <a className="footer_text" href="/editorical_notes" target="_blank">Impressum</a> | <a className="footer_text" href="/registration_conditions" target="_blank">Registrierungsbedingungen</a> | <a className="footer_text" href="/liability_conditions" target="_blank">Verwahrungsbedingungen</a>
                </p>
            </div>
        </div>
    )
}

export default Footer;